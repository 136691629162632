<div>
  <app-page>
    <div class="top-cta">
      <dx-button
        [text]="'COMMON.BACK' | translate"
        icon="back"
        type="default"
        class="integration-btn"
        (click)="navigateBack()"
      >
      </dx-button>
      <dx-button
        class="cta-btn"
        type="normal"
        text="{{ 'COMMON.SAVE' | translate }}"
        (click)="submit()"
      ></dx-button>
    </div>
    <app-page-title
      [title]="
        editingMode
          ? ('ADMINISTRATION.CATEGORY-TITLE-EDIT' | translate)
          : ('ADMINISTRATION.CATEGORY-TITLE-ADD' | translate)
      "
    ></app-page-title>
    <div class="row">
      <div class="col-md-12 col-lg-5">
        <app-panel class="image-panel">
          <div id="avatar-upload" class="uploader-container">
            <img
              class="category-image"
              [src]="formData.image"
              *ngIf="formData.image"
            />
            <img
              class="category-image"
              src=""
              appTrainingImageFallback
              *ngIf="!formData.image && editingMode"
            />
            <div
              class="uploader-preview-container"
              *ngIf="!formData.image && !editingMode"
            >
              <img src="assets/img/upload.jpg" />
              <span class="preview-title">{{
                "COMMON.UPLOAD-IMAGE" | translate
              }}</span>
              <span class="preview-description">{{
                "COMMON.UPLOAD-IMAGE-SIZE" | translate
              }}</span>
            </div>
          </div>
          <dx-file-uploader
            #fileUploader
            dialogTrigger="#avatar-upload"
            dropZone="#avatar-upload"
            [multiple]="false"
            [allowedFileExtensions]="['.jpg', '.jpeg', '.gif', '.png']"
            uploadMode="useForm"
            [visible]="false"
            (valueChange)="onCategoryImageSelected($event)"
          ></dx-file-uploader>
        </app-panel>
      </div>
      <div class="col-md-12 col-lg-7">
        <app-panel
          class="training-category-panel"
          [title]="'ADMINISTRATION.TITLE-DESCRIPTION' | translate"
        >
          <dx-form #form labelMode="floating" [formData]="formData">
            <dxi-item dataField="name">
              <dxo-label [text]="'COMMON.FIELDS.NAME' | translate"></dxo-label>
              <dxi-validation-rule
                type="required"
                [message]="'VALIDATIONS.NAME-REQUIRED' | translate"
              ></dxi-validation-rule>
            </dxi-item>
            <dxi-item
              dataField="description"
              editorType="dxTextArea"
              [editorOptions]="{
                height: 145
              }"
            >
              <dxo-label
                [text]="'COMMON.FIELDS.DESCRIPTION' | translate"
              ></dxo-label>
            </dxi-item>
          </dx-form>
        </app-panel>
      </div>
      <div class="col-md-12">
        <app-panel [title]="'ADMINISTRATION.EXTENDED-DESCRIPTION' | translate">
          <dx-form
            labelMode="floating"
            [showColonAfterLabel]="false"
            [formData]="formData"
            *ngIf="formData"
          >
            <dxi-item>
              <dxo-label [visible]="false"></dxo-label>
              <div *dxTemplate>
                <dx-html-editor
                  height="400px"
                  [(value)]="formData.descriptionExtended"
                >
                  <dxo-toolbar [multiline]="true">
                    <dxi-item name="undo"></dxi-item>
                    <dxi-item name="redo"></dxi-item>
                    <dxi-item name="separator"></dxi-item>
                    <dxi-item
                      name="size"
                      [acceptedValues]="[
                        '8pt',
                        '10pt',
                        '12pt',
                        '14pt',
                        '18pt',
                        '24pt',
                        '36pt'
                      ]"
                    ></dxi-item>
                    <dxi-item name="separator"></dxi-item>
                    <dxi-item name="bold"></dxi-item>
                    <dxi-item name="italic"></dxi-item>
                    <dxi-item name="strike"></dxi-item>
                    <dxi-item name="underline"></dxi-item>
                    <dxi-item name="separator"></dxi-item>
                    <dxi-item name="alignLeft"></dxi-item>
                    <dxi-item name="alignCenter"></dxi-item>
                    <dxi-item name="alignRight"></dxi-item>
                    <dxi-item name="alignJustify"></dxi-item>
                    <dxi-item name="separator"></dxi-item>
                    <dxi-item name="orderedList"></dxi-item>
                    <dxi-item name="bulletList"></dxi-item>
                    <dxi-item name="separator"></dxi-item>
                    <dxi-item
                      name="header"
                      [acceptedValues]="[false, 1, 2, 3, 4, 5]"
                    ></dxi-item>
                    <dxi-item name="separator"></dxi-item>
                    <dxi-item name="color"></dxi-item>
                    <dxi-item name="background"></dxi-item>
                    <dxi-item name="separator"></dxi-item>
                    <dxi-item name="link"></dxi-item>
                  </dxo-toolbar>
                </dx-html-editor>
              </div>
            </dxi-item>
          </dx-form>
        </app-panel>
      </div>
      <div class="col-md-12" *ngIf="editingMode">
        <app-panel [title]="'ADMINISTRATION.UPLOAD-DOCUMENTS' | translate">
          <div class="row">
            <div class="col-md-12 col-lg-5">
              <app-panel class="image-panel">
                <div id="document-upload" class="uploader-container">
                  <div class="uploader-preview-container">
                    <img src="assets/img/upload.jpg" />
                    <span class="preview-title">{{
                      "COMMON.UPLOAD-DOCUMENT" | translate
                    }}</span>
                  </div>
                </div>
                <dx-file-uploader
                  #fileUploader
                  dialogTrigger="#document-upload"
                  dropZone="#document-upload"
                  [multiple]="false"
                  uploadMode="useForm"
                  [visible]="false"
                  (valueChange)="onDocumentSelected($event)"
                ></dx-file-uploader>
              </app-panel>
            </div>
            <div class="col-md-12 col-lg-7">
              <app-panel class="training-category-panel">
                <div class="files-title">
                  {{ "COMMON.FILES" | translate }} ({{
                    currentTrainingCategory && currentTrainingCategory!.files
                      ? currentTrainingCategory!.files!.length
                      : 0
                  }})
                </div>
                <div
                  class="full-height-list"
                  *ngIf="
                    currentTrainingCategory && currentTrainingCategory!.files
                  "
                >
                  <div class="list-wrapper">
                    <dx-list
                      [dataSource]="currentTrainingCategory!.files!"
                      class="striped-list"
                    >
                      <div *dxTemplate="let item of 'item'">
                        <div class="file">
                          <div class="file-name">
                            <div>{{ item.fileName }}</div>
                            <div class="file-url">
                              {{ item.fileUrl }}
                            </div>
                          </div>
                          <div
                            class="close-action"
                            (click)="onRemoveFileClicked(item)"
                          >
                            <i class="dx-icon-close"></i>
                          </div>
                        </div>
                      </div>
                    </dx-list>
                  </div>
                </div>
              </app-panel>
            </div>
          </div>
        </app-panel>
      </div>
    </div>
  </app-page>
</div>

<app-confirmation-dialog
  [(showModal)]="showDeleteFileModal"
  (submit)="onRemoveFileConfirm()"
  [options]="modalConfirmationOptions"
></app-confirmation-dialog>
