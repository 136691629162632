import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DxFileUploaderComponent } from 'devextreme-angular';
import { ToastrService } from 'ngx-toastr';
import { RawFileModel } from 'src/app/models/raw-file-model';
import { FileUtilService } from 'src/app/services/file-util.service';
import { LoadingService } from 'src/app/services/loading.service';
import { AdministrationService } from 'src/app/services/administration.service';

@Component({
  selector: 'app-import-data',
  templateUrl: './import-data.component.html',
  styleUrl: './import-data.component.scss',
})
export class ImportDataComponent implements OnInit {
  private filesUploader!: DxFileUploaderComponent;
  dataFile!: File | null;

  constructor(
    private toastrService: ToastrService,
    private fileUtilService: FileUtilService,
    private translateService: TranslateService,
    private loadingService: LoadingService,
    private administrationService: AdministrationService
  ) {}

  ngOnInit(): void {}

  onDataFileSelected(e: any) {
    if (
      e.value &&
      e.value[0] &&
      (e.value[0].name.endsWith('.csv') || e.value[0].name.endsWith('.xls') || e.value[0].name.endsWith('.xlsx'))
    ) {
      this.dataFile = e.value[0];
    } else if (e.value && e.value[0]) {
      this.toastrService.error(
        this.translateService.instant('VALIDATIONS.FILE-NOT-SUPPORTED')
      );
      this.dataFile = null;
      this.filesUploader.instance.reset();
    }
  }

  importDataFile() {
    this.fileUtilService
      .convertFileToRawFileModel(this.dataFile as File)
      .then((data: RawFileModel) => {
        const rawFile: RawFileModel = {
          name: data.name,
          data: data.dataBase64,
        };

        this.loadingService
          .load(this.administrationService.importData(rawFile))
          .subscribe({
            next: () => {
              this.toastrService.success(
                this.translateService.instant(
                  'ADMINISTRATION.IMPORT-DATA.IMPORT-SUCCESS'
                )
              );
              this.dataFile = null;
              this.filesUploader.instance.reset();
            },
            error: () => {
              this.toastrService.error(
                this.translateService.instant(
                  'ADMINISTRATION.IMPORT-DATA.IMPORT-ERROR'
                )
              );
            },
          });
      });
  }
}
