import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {Configuration} from "../../assets/Configuration";
import {HttpClient} from "@angular/common/http";
import { RawFileModel } from '../models/raw-file-model';

@Injectable({
    providedIn: 'root'
})
export class AdministrationService {

    constructor(private httpClient: HttpClient) {
    }

    public importData(
        file: RawFileModel
      ): Observable<any> {
        return this.httpClient.post<any>(
          Configuration.getApiBaseUrl() + '/administration/import-data',
          file
        );
      }
}
