import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Configuration } from '../../assets/Configuration';
import {
  PersonEventModel,
  PersonEventFlagType,
} from '../models/person/person-event.model';

@Injectable({
  providedIn: 'root',
})
export class PersonEventFlagService {
  constructor(private httpClient: HttpClient) {}

  public getPersonEventFlags(eventId: string): Observable<PersonEventModel[]> {
    return this.httpClient.get<PersonEventModel[]>(
      Configuration.getApiBaseUrl() + '/PersonEventFlag/by-event/' + eventId
    );
  }

  public getPersonAllEventsFlags(actionType: PersonEventFlagType): Observable<PersonEventModel[]> {
    return this.httpClient.get<PersonEventModel[]>(
      Configuration.getApiBaseUrl() + '/PersonEventFlag/by-action?action=' + actionType
    );
  }

  public addPersonEventFlag(
    eventId: string,
    actionType: PersonEventFlagType
  ): Observable<PersonEventModel> {
    return this.httpClient.post<PersonEventModel>(
      Configuration.getApiBaseUrl() +
        '/PersonEventFlag/by-event/' +
        eventId +
        '?action=' +
        actionType,
      {}
    );
  }

  public deletePersonEventFlag(
    eventId: string,
    actionType: PersonEventFlagType
  ): Observable<PersonEventModel> {
    return this.httpClient.delete<PersonEventModel>(
      Configuration.getApiBaseUrl() +
        '/PersonEventFlag/by-event/' +
        eventId +
        '?action=' +
        actionType,
      {}
    );
  }

  public deletePersonEventFlagCourseAssignment(
    eventId: string,
    employeeId: string,
  ): Observable<PersonEventModel> {
    return this.httpClient.post<PersonEventModel>(
      Configuration.getApiBaseUrl() +
        '/PersonEventFlag/by-event/' +
        eventId +
        '/by-person/' +
        employeeId +
        '/remove-course-assignment',
        {}
    );
  }
}
