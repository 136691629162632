import { Injectable, Injector } from '@angular/core';
import { defer, Observable, Subject } from 'rxjs';
import { finalize, map, scan } from 'rxjs/operators';
import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { SpinnerOverlayComponent } from '../shared-components/spinner-overlay/spinner-overlay.component';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private spinnerTopRef = this.cdkSpinnerCreate();
  private spin$: Subject<boolean> = new Subject();
  private isFileUploading: boolean = false;

  constructor(private overlay: Overlay, private injector: Injector) {
    this.spin$
      .asObservable()
      .pipe(
        map((val) => (val ? 1 : -1)),
        scan((acc, one) => (acc + one >= 0 ? acc + one : 0), 0)
      )
      .subscribe((res) => {
        if (res === 1) {
          this.showSpinner();
        } else if (res == 0) {
          this.spinnerTopRef.hasAttached() ? this.stopSpinner() : null;
        }
      });
  }

  getIsFileUploading() {
    return this.isFileUploading;
  }

  setIsFileUploading(value: boolean) {
    this.isFileUploading = value;
  }

  load<T>(obs: Observable<T>): Observable<T> {
    return obs.pipe(
      this.prepare(() => this.spin$.next(true)),
      finalize(() => this.spin$.next(false))
    );
  }

  prepare<T>(callback: () => void): (source: Observable<T>) => Observable<T> {
    return (source: Observable<T>): Observable<T> =>
      defer(() => {
        callback();
        return source;
      });
  }

  private cdkSpinnerCreate() {
    return this.overlay.create({
      hasBackdrop: true,
      positionStrategy: this.overlay
        .position()
        .global()
        .centerHorizontally()
        .centerVertically(),
    });
  }

  showSpinner() {
    if (!this.spinnerTopRef.hasAttached()) {
      const spinnerOverlayPortal = new ComponentPortal(SpinnerOverlayComponent);
      this.spinnerTopRef.attach(spinnerOverlayPortal);
    }
  }

  private stopSpinner() {
    this.spinnerTopRef.detach();
  }
}
