import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {PersonSearchResultModel} from "../models/person/person-search-result.model";
import {TrainingInvitationSearchModel} from "../models/training/training-invitation-search.model";
import {TrainingMessageSendModel} from "../models/training/training-message-send.model";
import {Configuration} from "../../assets/Configuration";

@Injectable({
    providedIn: 'root'
})
export class TrainingMessageService {

    constructor(private httpClient: HttpClient) {
    }

    public searchPersonsForInvitation(searchModel: TrainingInvitationSearchModel): Observable<PersonSearchResultModel> {
        return this.httpClient.post<PersonSearchResultModel>(Configuration.getApiBaseUrl() + "/trainingmessage/search-persons", searchModel);
    }

    sendInvitation(trainingId: string, model: TrainingMessageSendModel): Observable<any> {
        return this.httpClient.post<void>(Configuration.getApiBaseUrl() + "/trainingmessage/invitation?trainingId=" + trainingId, model);
    }

    sendInfo(trainingId: string, model: TrainingMessageSendModel): Observable<any> {
        return this.httpClient.post<PersonSearchResultModel>(Configuration.getApiBaseUrl() + "/trainingmessage/info?trainingId=" + trainingId, model);
    }
}

