<ng-content></ng-content>

<div class="side-nav">
  <dx-tree-view
    [items]="items"
    keyExpr="path"
    selectionMode="single"
    [focusStateEnabled]="false"
    expandEvent="click"
    (onItemClick)="onItemClick($event)"
    width="100%"
  >
    <div *dxTemplate="let item of 'item'">
      <a
        *ngIf="item.path"
        [routerLink]="[item.path]"
        class="side-nav-link"
        [ngClass]="{ 'side-nav-padding': !item.icon }"
      >
        <i
          *ngIf="item.icon"
          class="dx-icon dx-svg-icon"
          [innerHTML]="getTrustedSvg(item.icon)"
        ></i>
        <span>{{ item.text | translate }}</span>
      </a>
      <div *ngIf="!item.path" class="side-nav-item">
        <i
          *ngIf="item.icon"
          class="dx-icon dx-svg-icon"
          [innerHTML]="getTrustedSvg(item.icon)"
        ></i>
        <span>{{ item.text | translate }}</span>
      </div>
    </div>
  </dx-tree-view>
</div>
