<app-panel>
  <app-page-title [title]="'ADMINISTRATION.IMPORT-DATA.TITLE' | translate">
  </app-page-title>
  <div class="import-data">
    <div class="import-description">
      {{ "ADMINISTRATION.IMPORT-DATA.IMPORT-COPY" | translate }}
    </div>
    <div class="import-cta">
      <div class="file-picker">
        <dx-file-uploader
          #filesUploader
          [selectButtonText]="'COMMON.SELECT-FILE' | translate"
          labelText=""
          [multiple]="false"
          [allowedFileExtensions]="['.csv', '.xls', '.xlsx']"
          (onValueChanged)="onDataFileSelected($event)"
          uploadMode="useForm"
          width="165px"
          [showFileList]="false"
        >
        </dx-file-uploader>
        <span class="file-name">{{ dataFile && dataFile.name }}</span>
      </div>
      <dx-button
        [disabled]="!dataFile"
        type="normal"
        (click)="importDataFile()"
      >
        {{ "COMMON.IMPORT" | translate }}
      </dx-button>
    </div>
  </div>
</app-panel>
