<app-page>
  <app-page-title title="{{ 'MY-TRAININGS.OVERVIEW.TITLE' | translate }}">
  </app-page-title>
  <dx-tabs
    width="100%"
    [selectedIndex]="selectedTab"
    [showNavButtons]="false"
    [dataSource]="tabs"
    (onItemClick)="onTabChange($event)"
  ></dx-tabs>
  <div class="my-trainings-container">
    <div class="my-trainings-filters">
      <div class="my-trainings-filters-dropdown">
        <dx-drop-down-box
          [(value)]="filters.trainingTypes"
          [inputAttr]="{ 'aria-label': 'Owner' }"
          valueExpr="id"
          displayExpr="name"
          [label]="'COMMON.FIELDS.TRAINING-TYPE' | translate"
          [dataSource]="trainingTypes"
          labelMode="floating"
          stylingMode="outlined"
        >
          <div *dxTemplate="let data of 'content'">
            <dx-tree-view
              [searchEnabled]="false"
              [dataSource]="trainingTypes"
              dataStructure="plain"
              keyExpr="id"
              selectionMode="multiple"
              showCheckBoxesMode="normal"
              [selectNodesRecursive]="false"
              displayExpr="name"
              [selectByClick]="true"
              (onItemSelectionChanged)="onTrainingTypeSelected($event)"
            >
            </dx-tree-view>
          </div>
        </dx-drop-down-box>
        <dx-drop-down-box
          [(value)]="filters.trainingCategories"
          [inputAttr]="{ 'aria-label': 'Owner' }"
          valueExpr="id"
          displayExpr="name"
          [label]="'COMMON.FIELDS.TRAINING-CATEGORIES' | translate"
          [dataSource]="trainingCategories"
          labelMode="floating"
          stylingMode="outlined"
        >
          <div *dxTemplate="let data of 'content'">
            <dx-tree-view
              [searchEnabled]="true"
              [dataSource]="trainingCategories"
              dataStructure="plain"
              keyExpr="id"
              selectionMode="multiple"
              showCheckBoxesMode="normal"
              [selectNodesRecursive]="false"
              displayExpr="name"
              [selectByClick]="true"
              (onItemSelectionChanged)="onTrainingCategorySelected($event)"
            >
            </dx-tree-view>
          </div>
        </dx-drop-down-box>
        <dx-drop-down-box
          [(value)]="filters.eventTypes"
          [inputAttr]="{ 'aria-label': 'Owner' }"
          valueExpr="id"
          displayExpr="name"
          [label]="'COMMON.FIELDS.EVENT-TYPE' | translate"
          [dataSource]="eventTypes"
          labelMode="floating"
          stylingMode="outlined"
        >
          <div *dxTemplate="let data of 'content'">
            <dx-tree-view
              [searchEnabled]="false"
              [dataSource]="eventTypes"
              dataStructure="plain"
              keyExpr="id"
              selectionMode="multiple"
              showCheckBoxesMode="normal"
              [selectNodesRecursive]="false"
              displayExpr="name"
              [selectByClick]="true"
              (onItemSelectionChanged)="onEventTypeSelected($event)"
            >
            </dx-tree-view>
          </div>
        </dx-drop-down-box>
      </div>
      <dx-text-box
        class="search-input"
        icon="search"
        width="100%"
        labelMode="floating"
        stylingMode="outlined"
        [label]="'COMMON.SEARCH' | translate"
        [value]="filters.searchString"
        valueChangeEvent="input"
        (onValueChanged)="onSearchInput($event)"
      >
        <dxi-button
          name="search"
          type="danger"
          location="before"
          [options]="{ icon: 'search', type: 'default', disabled: true }"
        ></dxi-button>
      </dx-text-box>
    </div>
    <div class="cards-container">
      <div *ngFor="let training of myTrainings" class="card-wrapper">
        <app-training-card [training]="training" />
      </div>
    </div>
  </div>
</app-page>
