import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {Configuration} from "../../assets/Configuration";
import { CourseTemplateSummaryModel } from '../models/course/course-template-summary.model';
import { CourseTemplateFullModel } from '../models/course/course-template-full.model';
import { CourseContentTypeModel } from '../models/course/course-content-type.model';
import { PersonSummaryModel } from '../models/person/person-summary.model';
import { CourseTemplateCreationAndUpdateBaseModel } from '../models/course/course-template-creation-and-update-base.model';

@Injectable({
  providedIn: 'root',
})
export class CourseTemplateService {
  constructor(private httpClient: HttpClient) {}

  public getAllCourseTemplates(): Observable<CourseTemplateSummaryModel[]> {
    return this.httpClient.get<CourseTemplateSummaryModel[]>(
      Configuration.getApiBaseUrl() + '/coursetemplate/'
    );
  }

  public getCourseTemplate(id: string): Observable<CourseTemplateFullModel> {
    return this.httpClient.get<CourseTemplateFullModel>(
      Configuration.getApiBaseUrl() + '/coursetemplate/' + id
    );
  }

  public addCourseTemplate(
    model: CourseTemplateCreationAndUpdateBaseModel
  ): Observable<CourseTemplateFullModel> {
    return this.httpClient.post<CourseTemplateFullModel>(
      Configuration.getApiBaseUrl() + '/coursetemplate/',
      model
    );
  }

  public getCourseContentTypeByFileName(
    fileName: string
  ): Observable<CourseContentTypeModel> {
    return this.httpClient.get<CourseContentTypeModel>(
      Configuration.getApiBaseUrl() +
        '/coursetemplate/course-content-type?fileName=' +
        fileName
    );
  }

  public getScormThumbnailByImsManifestXml(
    imsManifestXml: string
  ): Observable<string | null> {
    return this.httpClient.post(
      Configuration.getApiBaseUrl() + '/coursetemplate/scorm-thumbnail-by-ims-manifest',
      { fileContent: imsManifestXml },
      { responseType: 'text' }
    );
  }

  public getScormEntrypoint(
      courseTemplateId: string
  ): Observable<string> {
    return this.httpClient.get(
        Configuration.getApiBaseUrl() + '/scorm/' + courseTemplateId + '/entrypoint',{ responseType: 'text' });
  }
  updateCourseTemplate(
    id: string,
    model: CourseTemplateCreationAndUpdateBaseModel
  ) {
    return this.httpClient.post<CourseTemplateFullModel>(
      Configuration.getApiBaseUrl() + '/coursetemplate/' + id,
      model
    );
  }

  public getCourseTemplatePersons(id: string): Observable<{
    completedPersons: PersonSummaryModel[];
    acceptedPersons: PersonSummaryModel[];
    declinedPersons: PersonSummaryModel[];
    openPersons: PersonSummaryModel[];
  }> {
    return this.httpClient.get<{
      completedPersons: PersonSummaryModel[];
      acceptedPersons: PersonSummaryModel[];
      declinedPersons: PersonSummaryModel[];
      openPersons: PersonSummaryModel[];
    }>(Configuration.getApiBaseUrl() + '/CourseTemplate/' + id + '/persons');
  }

  public getCourseTemplateClone(
    id: string
  ): Observable<CourseTemplateFullModel> {
    return this.httpClient.get<CourseTemplateFullModel>(
      Configuration.getApiBaseUrl() + `/coursetemplate/${id}/clone`
    );
  }
}
