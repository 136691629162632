<div class="external-contact-form">
  <app-page>
    <div class="top-cta">
      <dx-button
        [text]="'COMMON.BACK' | translate"
        icon="back"
        type="default"
        class="integration-btn"
        (click)="navigateBack()"
      >
      </dx-button>
      <dx-button
        text="{{ 'COMMON.SAVE' | translate }}"
        type="normal"
        (click)="onFormSubmit()"
      >
      </dx-button>
    </div>
    <app-page-title
      [title]="
        editingExternalContactId
          ? ('EXTERNAL-CONTACTS.TITLE-EDIT' | translate)
          : ('EXTERNAL-CONTACTS.TITLE-ADD' | translate)
      "
    ></app-page-title>
    <div class="row">
      <div class="col-auto">
        <app-panel class="avatar-panel">
          <div id="avatar-upload" class="uploader-container">
            <img
              class="user-avatar"
              [src]="
                externalContactForm.imageBase64 || externalContactForm.avatar
              "
              *ngIf="
                externalContactForm.imageBase64 || externalContactForm.avatar
              "
            />
            <div
              class="uploader-preview-container"
              *ngIf="
                !externalContactForm.imageBase64 && !externalContactForm.avatar
              "
            >
              <img src="assets/img/upload.jpg" />
              <span class="preview-title">{{
                "COMMON.UPLOAD-IMAGE" | translate
              }}</span>
              <span class="preview-description">{{
                "COMMON.UPLOAD-IMAGE-SIZE" | translate
              }}</span>
            </div>
          </div>
          <dx-file-uploader
            #fileUploader
            dialogTrigger="#avatar-upload"
            dropZone="#avatar-upload"
            [multiple]="false"
            [allowedFileExtensions]="['.jpg', '.jpeg', '.gif', '.png']"
            uploadMode="useForm"
            [visible]="false"
            (valueChange)="onAvatarUploaded($event)"
          ></dx-file-uploader>
        </app-panel>
      </div>
      <div class="col">
        <app-panel class="external-contact-panel">
          <dx-form
            #topForm
            labelMode="floating"
            [showColonAfterLabel]="false"
            [formData]="externalContactForm"
          >
            <dxi-item dataField="lastName">
              <dxo-label
                [text]="'COMMON.FIELDS.LAST-NAME' | translate"
              ></dxo-label>
              <dxi-validation-rule
                type="required"
                [message]="'VALIDATIONS.LAST-NAME-REQUIRED' | translate"
              ></dxi-validation-rule>
            </dxi-item>
            <dxi-item dataField="firstName">
              <dxo-label
                [text]="'COMMON.FIELDS.FIRST-NAME' | translate"
              ></dxo-label>
              <dxi-validation-rule
                type="required"
                [message]="'VALIDATIONS.FIRST-NAME-REQUIRED' | translate"
              ></dxi-validation-rule>
            </dxi-item>
            <dxi-item dataField="email">
              <dxo-label [text]="'COMMON.FIELDS.EMAIL' | translate"></dxo-label>
              <dxi-validation-rule
                type="required"
                [message]="'VALIDATIONS.EMAIL-REQUIRED' | translate"
              ></dxi-validation-rule>
              <dxi-validation-rule
                type="email"
                [message]="'VALIDATIONS.EMAIL-INVALID' | translate"
              >
              </dxi-validation-rule>
            </dxi-item>
            <dxi-item>
              <dxo-label
                [text]="'COMMON.FIELDS.TRAINING-GROUPS' | translate"
              ></dxo-label>
              <div *dxTemplate>
                <dx-drop-down-box
                  [(value)]="externalContactForm.trainingGroupIds"
                  [inputAttr]="{ 'aria-label': 'Owner' }"
                  valueExpr="id"
                  displayExpr="name"
                  [label]="'COMMON.FIELDS.TRAINING-GROUPS' | translate"
                  [dataSource]="trainingGroups"
                  labelMode="floating"
                  stylingMode="outlined"
                >
                  <div *dxTemplate="let data of 'content'">
                    <dx-tree-view
                      [searchEnabled]="true"
                      [dataSource]="trainingGroups"
                      dataStructure="plain"
                      keyExpr="id"
                      [selectNodesRecursive]="false"
                      displayExpr="name"
                      [selectByClick]="true"
                      (onItemSelectionChanged)="onTrainingGroupSelected($event)"
                    >
                    </dx-tree-view>
                  </div>
                </dx-drop-down-box>
              </div>
            </dxi-item>
          </dx-form>
        </app-panel>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <app-panel [title]="'EXTERNAL-CONTACTS.TITLE-PROFILE' | translate">
          <dx-form
            #bottomForm
            labelMode="floating"
            [showColonAfterLabel]="false"
            [formData]="externalContactForm"
          >
            <dxi-item itemType="group">
              <dxo-col-count-by-screen
                [xs]="1"
                [sm]="1"
                [md]="1"
                [lg]="2"
              ></dxo-col-count-by-screen>
              <dxi-item dataField="companyName">
                <dxo-label
                  [text]="'COMMON.FIELDS.COMPANY' | translate"
                ></dxo-label>
              </dxi-item>
              <dxi-item dataField="position">
                <dxo-label
                  [text]="'COMMON.FIELDS.POSITION' | translate"
                ></dxo-label>
              </dxi-item>
              <dxi-item dataField="city">
                <dxo-label
                  [text]="'COMMON.FIELDS.CITY' | translate"
                ></dxo-label>
              </dxi-item>
              <dxi-item dataField="street">
                <dxo-label
                  [text]="'COMMON.FIELDS.STREET' | translate"
                ></dxo-label>
              </dxi-item>
              <dxi-item dataField="zipCode">
                <dxo-label
                  [text]="'COMMON.FIELDS.ZIP-CODE' | translate"
                ></dxo-label>
              </dxi-item>
              <dxi-item dataField="phoneMobile">
                <dxo-label
                  [text]="'COMMON.FIELDS.PHONE-MOBILE' | translate"
                ></dxo-label>
              </dxi-item>
              <dxi-item dataField="phonePrivate">
                <dxo-label
                  [text]="'COMMON.FIELDS.PHONE-PRIVATE' | translate"
                ></dxo-label>
              </dxi-item>
              <dxi-item dataField="phoneBusiness">
                <dxo-label
                  [text]="'COMMON.FIELDS.PHONE-BUSINESS' | translate"
                ></dxo-label>
              </dxi-item>
            </dxi-item>
          </dx-form>
        </app-panel>
      </div>
    </div>
  </app-page>
</div>
