import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class TimeService {

    constructor() {
    }

    public static getHoursFromAbsoluteMinutes(iddTimeMinutes: number): number {
        return Math.floor(iddTimeMinutes / 60);
    }

    public static getMinutesFromAbsoluteMinutes(iddTimeMinutes: number): number {
        return iddTimeMinutes % 60;
    }

    public static getHoursAndMinutesDisplayStringFromIddTimeMinutes(value: number) {
        if (value === null) {
            return "0:00";
        }
        const formattedMinutes = this.getMinutesFromAbsoluteMinutes(value).toLocaleString(
            [], {
                minimumIntegerDigits: 2,
                useGrouping: false
            });
        return this.getHoursFromAbsoluteMinutes(value) + ":" + formattedMinutes + " h";
    }
}
