import { Component, Input } from '@angular/core';
import { playIcon, categoryIcon } from '../../app-icons';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TrainingCategoryModel } from 'src/app/models/training-category.model';

@Component({
  selector: 'app-training-catalogue-card',
  templateUrl: './training-catalogue-card.component.html',
  styleUrls: ['./training-catalogue-card.component.scss'],
})
export class TrainingCatalogueCardComponent {
  @Input()
  category!: TrainingCategoryModel;

  getCategoryIcon() {
    return this.sanitized.bypassSecurityTrustHtml(categoryIcon);
  }

  getPlayIcon() {
    return this.sanitized.bypassSecurityTrustHtml(playIcon);
  }

  getCardLink() {
    return `/training-catalogue/${this.category.trainingCategory.id}`
  }

  constructor(
    private sanitized: DomSanitizer,
    private router: Router,
    public translateService: TranslateService
  ) {}
}
