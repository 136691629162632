import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DxFormComponent } from 'devextreme-angular/ui/form';
import { ToastrService } from 'ngx-toastr';
import { LoadingService } from '../../../services/loading.service';
import { TrainingGroupService } from '../../../services/training-group.service';
import { FileUtilService } from '../../../services/file-util.service';
import { ExternalContactService } from '../../../services/external-contacts.service';
import { AuthorizationService } from '../../../services/authorization.service';
import { ContactFullModel } from '../../../models/contact/contact-full.model';
import { ContactCreationAndUpdateModel } from 'src/app/models/contact/contact-creation-and-update.model';
import { TrainingGroupModel } from '../../../models/training-group.model';

@Component({
  selector: 'app-external-contacts-form',
  templateUrl: './external-contacts-form.component.html',
  styleUrls: ['./external-contacts-form.component.scss'],
})
export class ExternalContactsFormComponent {
  @ViewChild('topForm', { static: true }) topForm!: DxFormComponent;
  @ViewChild('bottomForm', { static: true }) bottomForm!: DxFormComponent;

  editingExternalContactId!: string;
  trainingGroups!: TrainingGroupModel[];
  currentUserId!: string;
  externalContactForm: ContactCreationAndUpdateModel = {
    firstName: null,
    lastName: null,
    ownerId: null,
    trainingGroupIds: [],
    city: null,
    email: null,
    phoneBusiness: null,
    phoneMobile: null,
    phonePrivate: null,
    street: null,
    zipCode: null,
    imageBase64: null,
    position: null,
    companyName: null,
  };

  constructor(
    private trainingGroupService: TrainingGroupService,
    private router: Router,
    private route: ActivatedRoute,
    private loadingService: LoadingService,
    private fileUtilService: FileUtilService,
    private externalContactService: ExternalContactService,
    private authorizationService: AuthorizationService,
    private toastrService: ToastrService
  ) {
    this.loadingService
      .load(this.trainingGroupService.getAllTrainingGroups())
      .subscribe((trainingGroups) => {
        this.trainingGroups = trainingGroups;
      });

    this.authorizationService.getCurrentPerson().subscribe((result) => {
      this.currentUserId = result.person.id;
    });

    this.editingExternalContactId = this.route.snapshot.params['id'];

    if (this.editingExternalContactId) {
      this.loadingService
        .load(
          this.externalContactService.getContact(this.editingExternalContactId)
        )
        .subscribe((externalContact) => {
          this.patchFormExternalContact(externalContact);
        });
    }
  }

  navigateBack(): void {
    this.router.navigate(['/external-contacts']);
  }

  onFormSubmit(): void {
    const isTopFormValid = this.topForm.instance.validate().isValid;
    const isBottomFormValid = this.bottomForm.instance.validate().isValid;

    if (isTopFormValid && isBottomFormValid) {
      if (!this.editingExternalContactId) {
        const externalContact: ContactCreationAndUpdateModel = {
          ...this.externalContactForm,
          imageBase64: this.externalContactForm.imageBase64
            ? this.externalContactForm.imageBase64!.split(',')[1]
            : null,
          ownerId: this.currentUserId,
        };
        const result = this.externalContactService.addContact(externalContact);
        this.loadingService.load(result).subscribe(() => {
          this.toastrService.success('Employee successfully created!');
          this.router.navigate(['external-contacts']);
        });
      } else {
        const externalContact: ContactCreationAndUpdateModel = {
          ...this.externalContactForm,
          imageBase64: this.externalContactForm.imageBase64
            ? this.externalContactForm.imageBase64!.split(',')[1]
            : null,
        };

        if (!externalContact.imageBase64) {
          delete externalContact.imageBase64;
        }

        const result = this.externalContactService.updateContact(
          this.editingExternalContactId,
          externalContact
        );
        this.loadingService.load(result).subscribe(() => {
          this.toastrService.success('External contact updated!');
        });
      }
    }
  }

  onTrainingGroupSelected(e: any) {
    if (e.node.selected) {
      this.externalContactForm.trainingGroupIds = [
        ...this.externalContactForm.trainingGroupIds,
        e.node.key,
      ];
    } else {
      this.externalContactForm.trainingGroupIds =
        this.externalContactForm.trainingGroupIds.filter(
          (id) => id !== e.node.key
        );
    }
  }

  onAvatarUploaded(files: any) {
    const file = files[0];
    const reader = new FileReader();
    reader.onload = () => {
      this.externalContactForm!.imageBase64 = reader!.result!.toString();
    };
    reader.readAsDataURL(file);
  }

  private patchFormExternalContact(externalContact: ContactFullModel) {
    this.externalContactForm.firstName = externalContact.person.firstName;
    this.externalContactForm.lastName = externalContact.person.lastName;
    this.externalContactForm.email = externalContact.person.email;
    this.externalContactForm.ownerId = externalContact.person.ownerId;
    this.externalContactForm.phonePrivate = externalContact.person.phonePrivate;
    this.externalContactForm.phoneMobile = externalContact.person.phoneMobile;
    this.externalContactForm.phoneBusiness =
      externalContact.person.phoneBusiness;
    this.externalContactForm.street = externalContact.person.street;
    this.externalContactForm.zipCode = externalContact.person.zipCode;
    this.externalContactForm.city = externalContact.person.city;
    this.externalContactForm.companyName = externalContact.person.companyName;
    this.externalContactForm.position = externalContact.person.position;

    this.externalContactForm.trainingGroupIds =
      externalContact.trainingGroups.map((tg) => tg.id);
    this.externalContactForm.createdAt = externalContact.person.createdAt
      ? new Date(externalContact.person.createdAt).toLocaleDateString('en')
      : null;

    if (externalContact.person.image) {
      this.loadingService
        .load(
          this.fileUtilService.avatarUrlToBase64(externalContact.person.image!)
        )
        .subscribe((base64) => {
          const mimeType = this.getImageMimeType(externalContact.person.image!);
          this.externalContactForm.imageBase64 = `data:${mimeType};base64,${base64}`;
        });
    }

    this.setTreeViewValues();
  }

  setTreeViewValues() {
    this.trainingGroups.forEach((trainingGroup) => {
      if (
        this.externalContactForm.trainingGroupIds.indexOf(trainingGroup.id) >
        -1
      ) {
        trainingGroup.selected = true;
      }
    });
  }

  getImageMimeType(url: string) {
    const extension = url.split('.').pop()?.split(/\#|\?/)[0].toLowerCase();

    switch (extension) {
      case 'png':
        return 'image/png';
      case 'jpg':
      case 'jpeg':
        return 'image/jpeg';
      case 'gif':
        return 'image/gif';
      default:
        return 'image/jpeg';
    }
  }

  onBackClick() {
    this.router.navigate(['/employee']);
  }

  refreshComponent() {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([
        '/external-contacts/edit/' + this.editingExternalContactId,
      ]);
    });
  }
}
