import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingService } from '../../../services/loading.service';
import { ToastrService } from 'ngx-toastr';
import { TrainingLocationTypeModel } from '../../../models/training/training-location-type.model';
import { IntegrationsService } from '../../../services/integration/integrations.service';

@Component({
  selector: 'app-webex',
  templateUrl: './webex.component.html',
  styleUrls: ['./webex.component.scss'],
})
export class WebexComponent implements OnInit {
  constructor(
    private activatedRoute: ActivatedRoute,
    private loadingService: LoadingService,
    private router: Router,
    private toastrService: ToastrService,
    private integrationsService: IntegrationsService
  ) {}

  ngOnInit(): void {
    const code = this.activatedRoute.snapshot.queryParams['code'];
    const obs = this.integrationsService.integrate(
      TrainingLocationTypeModel.Webex,
      code,
      IntegrationsService.webexRedirectUrl
    );
    this.loadingService.load(obs).subscribe(() => {
      this.toastrService.success('Webex Integration erfolgreich');
      this.router.navigate(['integrations']);
    });
  }
}
