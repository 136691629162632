import { Component, OnInit, ViewChild } from '@angular/core';
import { forkJoin, from, Subscription, switchMap } from 'rxjs';
import { TrainingFullModel } from '../../../models/training/training-full.model';
import { ActivatedRoute, Router } from '@angular/router';
import { TrainingService } from '../../../services/training.service';
import { LoadingService } from '../../../services/loading.service';
import { PersonModel } from '../../../models/person/person.model';
import { RawFileModel } from '../../../models/raw-file-model';
import { FileUtilService } from '../../../services/file-util.service';
import { TrainingMessageSendModel } from '../../../models/training/training-message-send.model';
import { TrainingMessageService } from '../../../services/training-message.service';
import { ToastrService } from 'ngx-toastr';
import { PersonSummaryModel } from '../../../models/person/person-summary.model';
import { DxFormComponent } from 'devextreme-angular';

@Component({
  selector: 'app-training-message',
  templateUrl: './training-message.component.html',
  styleUrls: ['./training-message.component.scss']
})
export class TrainingMessageComponent implements OnInit {
  trainingId!: string;
  isInvite: boolean = false;

  training: TrainingFullModel | null = null;
  attachments: RawFileModel[] = [];

  formData: {
    to: (PersonModel | PersonSummaryModel)[];
    subject: string;
    body: string;
    files: File[];
  } = {
    to: [],
    subject: '',
    body: '',
    files: []
  };

  private subs = new Subscription();

  @ViewChild('topForm') topForm!: DxFormComponent;
  @ViewChild('bottomForm') bottomForm!: DxFormComponent;

  constructor(
    private route: ActivatedRoute,
    private trainingService: TrainingService,
    private loadingService: LoadingService,
    private router: Router,
    private fileUtilService: FileUtilService,
    private trainingInvitationService: TrainingMessageService,
    private toastrService: ToastrService
  ) {}

  ngOnInit(): void {
    this.trainingId = this.route.snapshot.params['id'];
    this.isInvite =
      this.route.snapshot.url.filter(url => url.toString() == 'invite').length >
      0;
    const obs = forkJoin({
      trainingFull: this.trainingService.getTraining(this.trainingId),
      members: this.trainingService.getEventMembers(this.trainingId)
    });
    const sub = this.loadingService.load(obs).subscribe(t => {
      this.training = t.trainingFull;

      this.formData.subject = t.trainingFull.training.emailSubject
        ? t.trainingFull.training.emailSubject
        : t.trainingFull.training.name;
      this.formData.body = t.trainingFull.training.emailBody
        ? t.trainingFull.training.emailBody
        : t.trainingFull.training.description || '';
      if (!this.isInvite) {
        this.formData.to = t.members.map(em => em.person);
      }
    });
    this.subs.add(sub);
  }

  onInvitersChange(e: any) {
    this.formData.to = e.component.getSelectedNodeKeys();
  }

  onSelectedFileChange(files: File[]) {
    this.formData.files = files;
  }

  onBackClick(): void {
    this.router.navigate(['training', this.trainingId]);
  }

  onSendClick() {
    const isTopFormValid = this.topForm.instance.validate().isValid;
    const isBottomFormValid = this.bottomForm.instance.validate().isValid;

    if (!this.formData.to.length) {
      this.toastrService.error('Attendees are required');
      return;
    }
    if (!isTopFormValid || !isBottomFormValid) {
      this.toastrService.error('Values are not valid');
      return;
    }

    const attachmentsPromises = Promise.all(
      this.formData.files.map(file => {
        return this.fileUtilService.convertFileToRawFileModel(file);
      })
    );

    from(attachmentsPromises)
      .pipe(
        switchMap(attachments => {
          const model: TrainingMessageSendModel = {
            toPersonIds: this.formData.to
              ? this.formData.to.map(p => p.id)
              : [],
            subject: this.formData.subject,
            htmlBody: this.formData.body,
            attachments
          };
          const obs = this.isInvite
            ? this.trainingInvitationService.sendInvitation(
                this.trainingId,
                model
              )
            : this.trainingInvitationService.sendInfo(this.trainingId, model);

          return this.loadingService.load(obs);
        })
      )
      .subscribe({
        next: () => {
          this.toastrService.success('Erfolgreich versendet');
          this.router.navigate(['training', this.trainingId]);
        },
        error: () => {
          this.toastrService.error('Es ist ein Fehler aufgetreten');
        }
      });
  }

  addAttendeesHandler(persons: PersonModel[]) {
    this.formData.to = persons;
  }
}
