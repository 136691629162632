<dx-popup
  [width]="600"
  [height]="330"
  [showTitle]="true"
  [title]="''"
  [dragEnabled]="false"
  [hideOnOutsideClick]="false"
  [showCloseButton]="true"
  class="modal"
  [classList]="'modal'"
  [copyRootClassesToWrapper]="false"
  [visible]="showModal"
  (onHidden)="dismiss()"
>
  <div *dxTemplate="let data of 'content'" class="modal-content">
    <img class="checkmark-icon" src="assets/img/checkmark.jpg" />
    <div class="modal-text">
      <p>{{ "MY-TRAININGS.OVERVIEW.ATTENDANCE-CONFIRMED" | translate }}</p>
    </div>
    <div class="modal-buttons">
      <dx-button
        class="cta-btn"
        type="normal"
        [text]="'MY-TRAININGS.OVERVIEW.SAVE-CALENDAR' | translate"
        (click)="onSubmit()"
      ></dx-button>
    </div>
  </div>
</dx-popup>
