import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Configuration} from "../../assets/Configuration";
import {TrainingTemplateSummaryModel} from "../models/training-template/training-template-summary.model";
import {TrainingTemplateFullModel} from "../models/training-template/training-template-full.model";
import {TrainingTemplateCreationAndUpdateModel} from "../models/training-template/training-template-creation-and-update.model";

@Injectable({
  providedIn: 'root'
})
export class TrainingTemplateService {

  constructor(private httpClient: HttpClient) {
  }

  public getAllTrainingTemplates(): Observable<TrainingTemplateSummaryModel[]> {
    return this.httpClient.get<TrainingTemplateSummaryModel[]>(Configuration.getApiBaseUrl() + "/trainingtemplate/");
  }

  public getTrainingTemplate(id: string): Observable<TrainingTemplateFullModel> {
    return this.httpClient.get<TrainingTemplateFullModel>(Configuration.getApiBaseUrl() + "/trainingtemplate/" + id);
  }

  public updateTrainingTemplate(id: string, model: TrainingTemplateCreationAndUpdateModel): Observable<TrainingTemplateFullModel> {
    return this.httpClient.post<TrainingTemplateFullModel>(Configuration.getApiBaseUrl() + "/trainingtemplate/" + id, model);
  }

  public addTrainingTemplate(model: TrainingTemplateCreationAndUpdateModel) {
    return this.httpClient.post<TrainingTemplateFullModel>(Configuration.getApiBaseUrl() + "/trainingtemplate/", model);
  }

  public getTrainingTemplateClone(id: string): Observable<TrainingTemplateFullModel> {
    return this.httpClient.get<TrainingTemplateFullModel>(Configuration.getApiBaseUrl() + `/trainingtemplate/${id}/clone`);
  }
}
