import { Component } from '@angular/core';

@Component({
  selector: 'app-page',
  template: `
    <div class="page"><div class="page-content"><ng-content></ng-content></div></div>
  `,
  styleUrls: ['./page.component.scss'],
})
export class PageComponent {}
