import { Component } from '@angular/core';
import { FeedbackService } from '../../services/feedback.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FeedbackModel } from 'src/app/models/feedback/feedback.model';
import { SurveyAnswerCreationModel } from 'src/app/models/feedback/survey-answer-creation.model';
import { LoadingService } from 'src/app/services/loading.service';
import { ToastrService } from 'ngx-toastr';
import {SurveyQuestionType} from "../../models/feedback/survey-question.model";

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrl: './feedback.component.scss',
})
export class FeedbackComponent {
  isFeebackStarted: boolean = false;
  isFeebackFinihsed: boolean = false;
  feedback: FeedbackModel | null = null;
  currentPage: number = 0;
  eventKey: string = '';
  answers: SurveyAnswerCreationModel[] = [];

  get progressPersentage() {
    return (this.currentPage * 100) / this.feedback!.survey.pages.length;
  }

  constructor(
    route: ActivatedRoute,
    private router: Router,
    private feedbackService: FeedbackService,
    private loadingService: LoadingService,
    private toastrService: ToastrService
  ) {
    this.eventKey = route.snapshot.params['key'];
    this.feedbackService.getFeedback(this.eventKey).subscribe((feedback) => {
      this.feedback = feedback;

      this.feedback.survey.pages = this.feedback.survey.pages.sort(
        (a, b) => a.number - b.number
      );
      this.feedback.survey.pages.forEach((page) => {
        page.questions = page.questions.sort((a, b) => a.number - b.number);
      });
    });
  }

  onStartFeedback() {
    this.isFeebackStarted = true;
  }

  // onCloseFeedback() {
  //   this.router.navigate(['/']);
  // }

  previousPage() {
    this.currentPage--;
  }

  nextPage() {
    this.currentPage++;
  }

  submitFeedback() {
    this.loadingService
      .load(this.feedbackService.sendFeedback(this.eventKey, this.answers))
      .subscribe(() => {
        this.toastrService.success('Feedback submitted!');
        this.isFeebackFinihsed = true;
      });
  }

  onRadioQuestionAnswered(e: any, id: string) {
    const existingAnswer = this.answers.find(
      (answer) => answer.surveyQuestionId == id
    );

    if (existingAnswer) {
      existingAnswer.value = e.value;
    } else {
      this.answers.push({
        surveyQuestionId: id,
        value: e.value,
      });
    }
  }

  onTextQuestionAnswered(e: any, id: string) {
    const existingAnswer = this.answers.find(
      (answer) => answer.surveyQuestionId == id
    );

    if (existingAnswer) {
      existingAnswer.text = e.value;
    } else {
      this.answers.push({
        surveyQuestionId: id,
        text: e.value,
      });
    }
  }

  protected readonly SurveyQuestionType = SurveyQuestionType;
}
