<div>
  <app-page>
    <app-page-title [title]="'TRAINING.OVERVIEW.TITLE' | translate">
      <dx-button
        *ngIf="isTemplateMode"
        class="cta-btn"
        type="normal"
        icon="add"
        [text]="'TRAINING-TEMPLATE.OVERVIEW.ADD-TEMPLATE' | translate"
        (click)="onAddTrainingTemplateClick()"
      ></dx-button>
      <dx-button
        *ngIf="!isTemplateMode"
        class="cta-btn"
        type="normal"
        icon="add"
        [text]="'TRAINING.OVERVIEW.ADD-TRAINING' | translate"
        (click)="onAddTrainingClick()"
      ></dx-button>
    </app-page-title>
    <dx-tabs
      *ngIf="dataLoaded"
      width="100%"
      [selectedIndex]="selectedTab"
      [showNavButtons]="false"
      [dataSource]="tabs"
      (onItemClick)="onSelectedTabChange($event)"
    ></dx-tabs>
    <div class="training-overview-table">
      <div [hidden]="selectedTab != 2" class="year-filter">
        <dx-select-box
          labelMode="floating"
          stylingMode="outlined"
          [label]="'COMMON.FIELDS.YEAR' | translate"
          placeholder=""
          displayExpr="name"
          valueExpr="id"
          [dataSource]="yearsDropdownDataSource"
          (onValueChanged)="onYearFilterChanged($event)"
          [value]="yearFilter"
        >
        </dx-select-box>
      </div>
      <div class="table-wrapper" *ngIf="!isTemplateMode">
        <app-table
          [data]="trainings"
          [columns]="trainingColumns"
          [showSearch]="true"
          [showGrouping]="false"
          [searchLabel]="'COMMON.SEARCH' | translate"
          [searchPlacehoder]="'TRAINING.OVERVIEW.FIND-TRAININGS' | translate"
          (onRowClick)="trainingNavigateHandler($event)"
        ></app-table>
      </div>
      <div class="table-wrapper" *ngIf="isTemplateMode">
        <app-table
          [columns]="templatesColumns"
          [data]="trainingTemplates"
          [showSearch]="true"
          [showGrouping]="false"
          [searchLabel]="'COMMON.SEARCH' | translate"
          [searchPlacehoder]="
            'TRAINING-TEMPLATE.OVERVIEW.FIND-TEMPLATES' | translate
          "
          (onRowClick)="trainingTemplateNavigateHandler($event)"
        ></app-table>
      </div>
    </div>
  </app-page>
</div>
