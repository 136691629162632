<div
  class="training-category-info"
  *ngIf="trainingCategory && trainingCategory.trainingCategory"
>
  <app-page>
    <app-panel>
      <div class="top-cta">
        <dx-button
          [text]="'COMMON.BACK' | translate"
          icon="back"
          type="default"
          class="integration-btn"
          (click)="navigateBack()"
        >
        </dx-button>
      </div>
      <div class="training-category-heading">
        <div class="training-category-details">
          <div class="training-category-name">
            {{ trainingCategory.trainingCategory.name }}
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 col-lg-5">
          <div class="training-category-image-container">
            <img
              class="training-category-image"
              [src]="trainingCategory.trainingCategory.imageUrl"
              appTrainingImageFallback
            />
          </div>
        </div>
        <div class="col-md-12 col-lg-7">
          <div class="training-category-widget">
            <div class="training-category-description">
              {{ trainingCategory.trainingCategory.description! }}
            </div>
            <div
              *ngIf="trainingCategory.files && trainingCategory.files.length"
              class="training-category-widget-item"
            >
              <div class="training-category-widget-badge">
                <i class="dx-icon-link dx-svg-icon-default"></i>
                {{ "TRAINING-CATALOGUE.AVAILABLE-FILES" | translate }}:
              </div>
              <div class="training-category-widget-value">
                <div
                  *ngFor="let file of trainingCategory.files"
                  class="training-category-item-file"
                >
                  <a [href]="file.fileUrl" target="_blank">
                    {{ file.fileName }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="training-category-description-widget">
        <div class="training-category-widget-title">
          {{ "COMMON.FIELDS.DESCRIPTION" | translate }}
        </div>
        <dx-form
          class="course-description-form"
          labelLocation="top"
          [showColonAfterLabel]="false"
          [formData]="trainingCategory.trainingCategory"
        >
          <dxi-item
            dataField="descriptionExtended"
            label=""
            editorType="dxHtmlEditor"
            [editorOptions]="{
              label: '',
              maxHeight: 280,
              readOnly: true
            }"
          >
            <dxo-label text=" "></dxo-label>
          </dxi-item>
        </dx-form>
      </div>
    </app-panel>
    <app-panel>
      <div class="events-list-container">
        <div class="events-list-filters">
          <div class="events-list-filters-dropdown">
            <dx-drop-down-box
              [(value)]="filters.trainingTypes"
              [inputAttr]="{ 'aria-label': 'Owner' }"
              valueExpr="id"
              displayExpr="name"
              [label]="'COMMON.FIELDS.TRAINING-TYPE' | translate"
              [dataSource]="trainingTypes"
              labelMode="floating"
              stylingMode="outlined"
            >
              <div *dxTemplate="let data of 'content'">
                <dx-tree-view
                  [searchEnabled]="false"
                  [dataSource]="trainingTypes"
                  dataStructure="plain"
                  keyExpr="id"
                  selectionMode="multiple"
                  showCheckBoxesMode="normal"
                  [selectNodesRecursive]="false"
                  displayExpr="name"
                  [selectByClick]="true"
                  (onItemSelectionChanged)="onTrainingTypeSelected($event)"
                >
                </dx-tree-view>
              </div>
            </dx-drop-down-box>
            <dx-drop-down-box
              [(value)]="filters.eventTypes"
              [inputAttr]="{ 'aria-label': 'Owner' }"
              valueExpr="id"
              displayExpr="name"
              [label]="'COMMON.FIELDS.EVENT-TYPE' | translate"
              [dataSource]="eventTypes"
              labelMode="floating"
              stylingMode="outlined"
            >
              <div *dxTemplate="let data of 'content'">
                <dx-tree-view
                  [searchEnabled]="false"
                  [dataSource]="eventTypes"
                  dataStructure="plain"
                  keyExpr="id"
                  selectionMode="multiple"
                  showCheckBoxesMode="normal"
                  [selectNodesRecursive]="false"
                  displayExpr="name"
                  [selectByClick]="true"
                  (onItemSelectionChanged)="onEventTypeSelected($event)"
                >
                </dx-tree-view>
              </div>
            </dx-drop-down-box>
          </div>
          <dx-text-box
            class="search-input"
            icon="search"
            width="100%"
            labelMode="floating"
            stylingMode="outlined"
            [label]="'COMMON.SEARCH' | translate"
            [value]="filters.searchString"
            valueChangeEvent="input"
            (onValueChanged)="onSearchInput($event)"
          >
            <dxi-button
              name="search"
              type="danger"
              location="before"
              [options]="{ icon: 'search', type: 'default', disabled: true }"
            ></dxi-button>
          </dx-text-box>
        </div>
        <div class="cards-container">
          <div *ngFor="let event of eventsList" class="card-wrapper">
            <app-training-card
              [training]="event"
              [isCatalogue]="true"
              [categoryId]="trainingCategoryId"
            />
          </div>
        </div>
      </div>
    </app-panel>
  </app-page>
</div>
