import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  OnInit
} from '@angular/core';
import { Router } from '@angular/router';
import { DxFormComponent } from 'devextreme-angular';
import { ToastrService } from 'ngx-toastr';
import { LoadingService } from '../../services/loading.service';
import { TrainingAnonymousService } from '../../services/training-anonymous.service';
import { TrainingAnonymousModel } from '../../models/training/training-anonymous.model';

@Component({
  selector: 'app-accept-invitation-modal',
  templateUrl: './accept-invitation-modal.component.html',
  styleUrl: './accept-invitation-modal.component.scss',
})
export class AcceptInvitationModalComponent implements OnInit {
  @Input() showModal = false;
  @Input() training: TrainingAnonymousModel | null = null;
  @Input() trainingKey = '';
  @Output() showModalChange = new EventEmitter<boolean>();
  @Output() onInviteAcepted = new EventEmitter();

  isTermsAccepted: boolean | null | undefined = false;
  isShowForm: boolean = false;
  isFormSubmitted: boolean = false;
  isRequestFormSubmitted: boolean = false;
  isFormError: boolean = false;
  accessKey: string | null = null;
  formData = {
    email: '',
    firstName: '',
    lastName: '',
    agentNumber: null,
  };

  @ViewChild('form') form!: DxFormComponent;

  constructor(
    private trainingAnonymousService: TrainingAnonymousService,
    private loadingService: LoadingService,
    private toastrService: ToastrService,
    private router: Router
  ) {}

  ngOnInit() {
    if(this.training?.guestsOnly) {
      this.isShowForm = true;
    }
  }

  acceptInvitation() {
    const isValid = this.form.instance.validate().isValid;
    if (!isValid) {
      return;
    }

    this.loadingService
      .load(
        this.trainingAnonymousService.acceptTrainingAnonymous(
          this.trainingKey,
          this.formData
        )
      )
      .subscribe({
        next: (key) => {
          if (key) {
            this.accessKey = key;
          }
          this.toastrService.success('Invitation accepted successfully');
          this.onInviteAcepted.emit();
          this.isFormSubmitted = true;
        },
        error: () => {
          this.isFormError = true;
          this.isFormSubmitted = true;
        },
      });
  }

  navigateTraining() {
    window.location.href = window.location.origin + `/my-trainings/training/${this.training!.id}?autoaccept=true`
  }

  showForm() {
    this.isShowForm = true;
  }

  closeModal() {
    this.showModal = false;
    this.showModalChange.emit(this.showModal);

    if (this.accessKey) {
      this.router.navigate([`/my-invitation/${this.training!.key}/${this.accessKey}`]);
    }
  }

  cancelError() {
    this.isFormError = false;
    this.isFormSubmitted = false;
  }

  sendRequest() {
    this.loadingService
      .load(
        this.trainingAnonymousService.requestTrainingAnonymous(
          this.trainingKey,
          this.formData
        )
      )
      .subscribe({
        next: () => {
          this.toastrService.success('Request sent');
          this.isFormSubmitted = false;
          this.isRequestFormSubmitted = true;
        }
      });
  }

  onPopupHiding() {
    console.log('On popup hide event!');
  }

  onBackClick() {
    this.isShowForm = false;
  }
}
