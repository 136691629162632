<a class="training-card" [routerLink]="[getCardLink()]">
  <img
    class="training-image"
    [src]="training.imageUrl"
    appTrainingImageFallback
  />
  <div class="training-details">
    <div>
      <h2 class="training-name">{{ training.name }}</h2>
      <div class="training-description">{{ training.description }}</div>
    </div>
    <div>
      <div class="training-badge" *ngIf="training.type == 'Training'">
        <div
          class="training-badge-container"
          *ngIf="training.dates && training.dates[0]"
        >
          <div class="training-badge-content">
            <i class="dx-icon dx-svg-icon" [innerHTML]="getCalendarIcon()"></i>
            {{
              training.dates[0].start
                | date : "dd LLL yyyy" : "" : translateService.currentLang
            }}
          </div>
        </div>
      </div>
      <div class="training-badge">
        <div class="training-badge-container">
          <div class="training-badge-content" *ngIf="training.type == 'Course'">
            <i
              class="dx-icon dx-svg-icon play-icon"
              [innerHTML]="getPlayIcon()"
            ></i>
            Elearning
          </div>
          <div
            class="training-badge-content"
            *ngIf="training.type == 'Training'"
          >
            <i
              class="dx-icon dx-svg-icon play-icon"
              [innerHTML]="getPlayIcon()"
            ></i>
            <span *ngIf="training.location.type === 0">{{
              "COMMON.FIELDS.EVENT-TYPES.IN-PERSON" | translate
            }}</span>
            <span *ngIf="training.location.type === 1">{{
              "COMMON.FIELDS.EVENT-TYPES.WEB" | translate
            }}</span>
            <span *ngIf="training.location.type === 2">{{
              "COMMON.FIELDS.EVENT-TYPES.WEBEX-WEBINAR" | translate
            }}</span>
            <span *ngIf="training.location.type === 3">{{
              "COMMON.FIELDS.EVENT-TYPES.ONLINE" | translate
            }}</span>
            <span *ngIf="training.location.type === 6">{{
              "COMMON.FIELDS.EVENT-TYPES.GO-TO-WEBINAR" | translate
            }}</span>
          </div>
        </div>
        <div class="training-badge-container">
          <div class="training-badge-content">
            <i
              class="dx-icon dx-svg-icon play-icon"
              [innerHTML]="getCategoryIcon()"
            ></i>
            <span *ngIf="training.trainingType === 0">{{
              "COMMON.FIELDS.TRAINING-TYPES.IDD" | translate
            }}</span>
            <span *ngIf="training.trainingType === 1">{{
              "COMMON.FIELDS.TRAINING-TYPES.COMPLIANCE" | translate
            }}</span>
            <span *ngIf="training.trainingType === 2">{{
              "COMMON.FIELDS.TRAINING-TYPES.GENERAL" | translate
            }}</span>
          </div>
        </div>
        <i
          *ngIf="training.isBookmarked"
          class="dx-icon dx-bookmark-icon svg-red"
          [innerHTML]="getBookmarkIcon()"
        ></i>
      </div>
    </div>
  </div>
</a>
