import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {PersonSummaryModel} from "../models/person/person-summary.model";
import {Configuration} from "../../assets/Configuration";
import {TrainingGroupModel} from "../models/training-group.model";
import {TrainingGroupCreationAndUpdateModel} from "../models/training-group-creation-and-update.model";

@Injectable({
  providedIn: 'root'
})
export class TrainingGroupService {

  constructor(private httpClient: HttpClient) {
  }

  public getAllTrainingGroups(): Observable<TrainingGroupModel[]> {
    return this.httpClient.get<TrainingGroupModel[]>(Configuration.getApiBaseUrl() + "/traininggroup/");
  }

  public getTrainingGroup(id: string): Observable<TrainingGroupModel> {
    return this.httpClient.get<TrainingGroupModel>(Configuration.getApiBaseUrl() + "/traininggroup/" + id);
  }

  public addTrainingGroup(model: TrainingGroupCreationAndUpdateModel): Observable<TrainingGroupModel> {
    return this.httpClient.post<TrainingGroupModel>(Configuration.getApiBaseUrl() + "/traininggroup/", model);
  }

  public updateTrainingGroup(id: string, model: TrainingGroupCreationAndUpdateModel): Observable<TrainingGroupModel> {
    return this.httpClient.post<TrainingGroupModel>(Configuration.getApiBaseUrl() + "/traininggroup/" + id, model);
  }

  public deleteTrainingGroup(id: string): Observable<void> {
    return this.httpClient.delete<void>(Configuration.getApiBaseUrl() + "/traininggroup/" + id);
  }

  public getTrainingGroupPersons(id: string): Observable<PersonSummaryModel[]> {
    return this.httpClient.get<PersonSummaryModel[]>(Configuration.getApiBaseUrl() + "/traininggroup/" + id + "/persons");
  }

  public addTrainingGroupPersons(id: string, personIds: String[]): Observable<void> {
    return this.httpClient.post<void>(Configuration.getApiBaseUrl() + "/traininggroup/" + id + "/add-persons", personIds);
  }
}
