<dx-popup
  [width]="620"
  [height]="450"
  [showTitle]="true"
  [title]="'MY-INVITATION.LOGIN' | translate"
  [dragEnabled]="false"
  [hideOnOutsideClick]="false"
  [showCloseButton]="true"
  class="modal"
  [classList]="'modal'"
  [copyRootClassesToWrapper]="false"
  [visible]="showModal"
  (onHidden)="closeModal()"
  (onHiding)="onPopupHiding()"
>
  <div *dxTemplate="let data of 'content'">
    <div
      [hidden]="isShowForm || isFormSubmitted || isRequestFormSubmitted"
      class="login-options"
    >
      <dx-button
        [text]="'MY-INVITATION.LOGIN-EMPLOYEE' | translate"
        type="normal"
        (click)="navigateTraining()"
      ></dx-button>
      <div class="separator">{{ "MY-INVITATION.OR" | translate }}</div>
      <dx-button
        [text]="'MY-INVITATION.CONTINUE-GUEST' | translate"
        type="normal"
        (click)="showForm()"
      ></dx-button>
    </div>
    <div
      [hidden]="
        !isShowForm || (isShowForm && isFormSubmitted) || isRequestFormSubmitted
      "
    >
      <dx-form #form [formData]="formData" labelMode="floating">
        <dxi-item dataField="email">
          <dxo-label [text]="'COMMON.FIELDS.EMAIL' | translate"></dxo-label>
          <dxi-validation-rule
            type="required"
            [message]="'VALIDATIONS.EMAIL-REQUIRED' | translate"
          ></dxi-validation-rule>
          <dxi-validation-rule
            type="email"
            [message]="'VALIDATIONS.EMAIL-INVALID' | translate"
          >
          </dxi-validation-rule>
        </dxi-item>
        <dxi-item dataField="firstName">
          <dxo-label
            [text]="'COMMON.FIELDS.FIRST-NAME' | translate"
          ></dxo-label>
          <dxi-validation-rule
            type="required"
            [message]="'VALIDATIONS.FIRST-NAME-REQUIRED' | translate"
          ></dxi-validation-rule>
        </dxi-item>
        <dxi-item dataField="lastName">
          <dxo-label [text]="'COMMON.FIELDS.LAST-NAME' | translate"></dxo-label>
          <dxi-validation-rule
            type="required"
            [message]="'VALIDATIONS.LAST-NAME-REQUIRED' | translate"
          ></dxi-validation-rule>
        </dxi-item>
        <dxi-item dataField="agentNumber">
          <dxo-label
            [text]="'COMMON.FIELDS.AGENT-NUMBER' | translate"
          ></dxo-label>
        </dxi-item>
      </dx-form>
      <dx-check-box
        class="terms-checkbox"
        [(value)]="isTermsAccepted"
        [text]="'MY-INVITATION.TERMS' | translate"
      ></dx-check-box>
      <div class="form-cta">
        <dx-button
          [ngStyle]="{'visibility': training!.guestsOnly ? 'hidden' : 'visible'}"
          [text]="'COMMON.BACK' | translate"
          type="default"
          (click)="onBackClick()"
        ></dx-button>
        <dx-button
          [disabled]="!isTermsAccepted"
          [text]="'MY-INVITATION.SEND' | translate"
          type="normal"
          (click)="acceptInvitation()"
        ></dx-button>
      </div>
    </div>
    <div [hidden]="!isFormSubmitted" class="response-message">
      <div *ngIf="!isFormError">
        {{ "MY-INVITATION.SUBMIT-SUCCESS-MESSAGE" | translate }}
      </div>
      <div *ngIf="isFormError">
        {{ "MY-INVITATION.SUBMIT-ERROR-MESSAGE" | translate }}
      </div>
      <div class="response-cta">
        <dx-button
          *ngIf="!isFormError"
          [text]="'COMMON.CLOSE' | translate"
          type="normal"
          (click)="closeModal()"
        ></dx-button>
        <dx-button
          *ngIf="isFormError"
          [text]="'COMMON.CANCEL' | translate"
          type="default"
          (click)="cancelError()"
        ></dx-button>
        <dx-button
          *ngIf="isFormError"
          [text]="'COMMON.REQUEST-PARTICIPATION' | translate"
          type="normal"
          (click)="sendRequest()"
        ></dx-button>
      </div>
    </div>
    <div [hidden]="!isRequestFormSubmitted" class="response-message">
      <div>
        {{ "MY-INVITATION.REQUEST-SUCCESS-MESSAGE" | translate }}
      </div>
      <div class="response-cta">
        <dx-button
          [text]="'COMMON.CLOSE' | translate"
          type="normal"
          (click)="closeModal()"
        ></dx-button>
      </div>
    </div>
  </div>
</dx-popup>
