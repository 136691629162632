import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PersonSummaryWithIddModel } from 'src/app/models/person/person-summary-with-idd.model';
import { PersonSummaryModel } from 'src/app/models/person/person-summary.model';
import { TableColumn } from 'src/app/models/table-column.model';
import { TimeService } from 'src/app/services/time.service';

@Component({
  selector: 'app-merge-employees-modal',
  templateUrl: './merge-employees-modal.component.html',
  styleUrls: ['./merge-employees-modal.component.scss'],
})
export class MergeEmployeesModalComponent {
  @Input() showModal = false;
  @Input() persons: PersonSummaryWithIddModel[] = [];
  selectedPerson: string = '';
  personsColumns: TableColumn[] = [
    {
      caption: this.translateService.instant('COMMON.FIELDS.LAST-NAME'),
      dataField: 'lastName',
      cssClass: 'width-200',
    },
    {
      caption: this.translateService.instant('COMMON.FIELDS.FIRST-NAME'),
      dataField: 'firstName',
      cssClass: 'width-200',
    },
    {
      caption: this.translateService.instant('COMMON.FIELDS.EMAIL'),
      dataField: 'email',
      cssClass: 'width-150',
    },
    {
      caption: 'IDD',
      dataField: 'iddTimeMinutes',
      cssClass: 'width-150',
      calculateDisplayValue: (data: PersonSummaryWithIddModel): string => {
        return TimeService.getHoursAndMinutesDisplayStringFromIddTimeMinutes(
          data.iddTimeMinutes
        );
      },
      calculateSortValue: (data: any) => {
        return data.iddTimeMinutes;
      },
      headerFilter: (data: any) => {
        data.dataSource.postProcess = (results: any) => {
          const iddTimeMinutes = results.map((result: any) => result.value);
          results.splice(0, results.length);
          for (let iddTime of iddTimeMinutes) {
            results.push({
              text: TimeService.getHoursAndMinutesDisplayStringFromIddTimeMinutes(
                iddTime
              ),
              value: iddTime,
            });
          }
        };
      },
    },
    {
      caption: this.translateService.instant('COMMON.FIELDS.ROLE'),
      dataField: 'role.name',
    },
    {
      caption: this.translateService.instant('COMMON.FIELDS.LOCATION'),
      dataField: 'location.name',
      cssClass: 'width-150',
    },
    {
      caption: this.translateService.instant('COMMON.FIELDS.TRAINING-GROUPS'),
      dataField: 'trainingGroups',
      cssClass: 'width-350',
      headerFilter: (data: any) => {
        data.dataSource.postProcess = (results: any) => {
          const trainingGroupNames = results.reduce(
            (a: any, b: any) => a.concat(b.value),
            []
          );
          const parsedTrainingGroupNames: string[] = [];
          trainingGroupNames.forEach((group: string) => {
            if (!group || !group.includes(', ')) {
              parsedTrainingGroupNames.push(group);
            } else if (group.includes(', ')) {
              const groups = group.split(', ');
              parsedTrainingGroupNames.push(...groups);
            }
          });
          const uniqueTrainingGroupNames = [
            ...new Set(parsedTrainingGroupNames),
          ];

          results.splice(0, results.length);
          uniqueTrainingGroupNames.forEach((tgName) => {
            if (tgName) {
              results.push({
                text: tgName,
                value: tgName,
              });
            } else {
              results.push({
                text: '(Blanks)',
                value: null,
              });
            }
          });
          return results;
        };
      },
      filterExpression: (value: any) => {
        function getTrainingGroupsFilterString(rowData: PersonSummaryModel) {
          const groups = rowData.trainingGroups.map((tg) => tg.name);
          return !!groups.join(',') ? groups.join(',') + ',' : 'empty';
        }

        return [
          [
            getTrainingGroupsFilterString,
            'contains',
            value ? value + ',' : 'empty',
          ],
        ];
      },
      cellValue: (data: PersonSummaryModel | any): string => {
        return data.trainingGroups.map((tg: any) => tg.name).join(', ');
      },
    },
  ];

  @Output() showModalChange = new EventEmitter<boolean>();
  @Output() submit = new EventEmitter();

  constructor(private translateService: TranslateService) {}

  dismiss = () => {
    this.selectedPerson = '';
    this.showModal = false;
    this.showModalChange.emit(this.showModal);
  };

  onSubmit = () => {
    this.submit.emit(this.selectedPerson);
    this.showModal = false;
    this.showModalChange.emit(this.showModal);
    this.selectedPerson = '';
  };

  onPersonSelected(ids: string[]) {
    this.selectedPerson = ids[0];
  }
}
