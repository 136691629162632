import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {Configuration} from "../../assets/Configuration";

@Injectable({
  providedIn: 'root',
})
export class FileProgressService {
  constructor(private httpClient: HttpClient) {}

  public getStatus(id: string): Observable<string> {
    return this.httpClient.get<string>(
      Configuration.getApiBaseUrl() + '/fileuploader/status/' + id, { responseType: 'text' as 'json' }
    );
  }

}
