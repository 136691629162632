import {Component, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TrainingService} from '../../../services/training.service';
import {BehaviorSubject, from, Observable, of} from 'rxjs';
import {LoadingService} from '../../../services/loading.service';
import {shareReplay, switchMap, tap} from 'rxjs/operators';
import {FileUtilService} from '../../../services/file-util.service';
import {TrainingCompleteModel} from '../../../models/training/training-complete.model';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {AttendeeListComponent} from "./attendee-list/attendee-list.component";
import {TrainingFullModel} from "../../../models/training/training-full.model";
import {TrainingLocationTypeModel} from "../../../models/training/training-location-type.model";
import {RawFileModel} from "../../../models/raw-file-model";

@Component({
  selector: 'app-training-complete',
  templateUrl: './training-complete.component.html',
  styleUrl: './training-complete.component.scss'
})
export class TrainingCompleteComponent {
  trainingId!: string;
  training$: Observable<TrainingFullModel>;
  isAttachmentMandatory: boolean = true;
  formData: { attachment: File | null } = {
    attachment: null
  };

  @ViewChild('attendeeList')
  attendeeList!: AttendeeListComponent;

  constructor(
    private route: ActivatedRoute,
    private trainingService: TrainingService,
    private loadingService: LoadingService,
    private router: Router,
    private fileUtilService: FileUtilService,
    private toastrService: ToastrService,
    private translate: TranslateService
  ) {
    this.trainingId = this.route.snapshot.params['id'];
    this.training$ = this.trainingService.getTraining(this.trainingId).pipe(shareReplay(1));
    this.training$.subscribe(t => {
      this.isAttachmentMandatory = t.training.trainingLocation.type == TrainingLocationTypeModel.Address || t.training.trainingLocation.type == TrainingLocationTypeModel.Web
    })

  }

  onBackClick(): void {
    this.router.navigate(['training', this.trainingId]);
  }

  onCompleteClick(): void {
    if (this.isAttachmentMandatory && !this.formData.attachment) {
      this.translate.get('COMMON.FILE-NOT-SELECTED').subscribe(value => {
        this.toastrService.error(value);
      });
      return;
    }

    const file: Observable<RawFileModel | null> = this.formData.attachment ? from(this.fileUtilService.convertFileToRawFileModel(this.formData.attachment)) : of(null);

    file
      .pipe(
        switchMap(rawFile => {
          const trainingCompleteModel: TrainingCompleteModel = {
            eventMemberPersonIds: this.attendeeList.selectedPersons.map(person => person.id),
            memberList: rawFile
          };

          return this.loadingService.load(
            this.trainingService.completeTraining(
              this.trainingId,
              trainingCompleteModel
            )
          );
        })
      )
      .subscribe({
        complete: () => {
          this.translate
            .get(
              'MY-TRAININGS.TRAINING-INVITE.THE-TRAINING-WAS-SUCCESSFULLY-COMPLETED'
            )
            .subscribe(value => {
              this.toastrService.success(value);
              this.router.navigate(['/training']);
            });
        },
        error: () => {
          this.translate
            .get('COMMON.AN-ERROR-HAS-OCCURRED')
            .subscribe(value => {
              this.toastrService.error(value);
            });
        }
      });
  }

  onSelectedFileChange(file: File[]) {
    this.formData.attachment = file[0];
  }
}
