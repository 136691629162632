import { Component, Input, Output, EventEmitter } from '@angular/core';
import { TrainingHistoryService } from '../../services/training-history.service';
import { LoadingService } from '../../services/loading.service';
import { FileUtilService } from '../../services/file-util.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-training-overview-popup',
  templateUrl: './training-overview-popup.component.html',
  styleUrls: ['./training-overview-popup.component.scss'],
})
export class TrainingOverviewPopupComponent {
  @Input()
  isPopupVisible!: boolean;

  @Input()
  personId!: string;

  @Output() onPopupClose = new EventEmitter();

  years: { id: string; name: string }[] = [];
  selectedYears: string[] = [];

  constructor(
    private loadingService: LoadingService,
    private fileUtilService: FileUtilService,
    private toastrService: ToastrService,
    private trainingHistoryService: TrainingHistoryService
  ) {}

  onShow() {
    this.years = [];
    this.selectedYears = [];

    this.loadingService
      .load(
        this.trainingHistoryService.getTrainingHistoryYearList(this.personId)
      )
      .subscribe((years) => {
        this.years = years.map((year) => ({ id: year, name: year }));
      });
  }

  onClose() {
    this.onPopupClose.emit();
  }

  onYearsSelected(e: any) {
    if (e.node.selected) {
      this.selectedYears = [...this.selectedYears, e.node.key];
    } else {
      this.selectedYears = this.selectedYears.filter((id) => id !== e.node.key);
    }
  }

  downloadOverview() {
    this.trainingHistoryService.getTrainingHistory(
      this.personId,
      this.selectedYears.join(',')
    );
  }
}
