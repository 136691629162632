<app-table
  [data]="completedEvents"
  [columns]="eventsColumns"
  [showSearch]="true"
  [searchLabel]="'COMMON.SEARCH' | translate"
  [searchPlacehoder]="'EMPLOYEE.CERTIFICATES.OVERVIEW.FIND-CERTIFICATES' | translate"
  [showActionButton]="isManager"
  actionButtonIcon="add"
  [actionButtonText]="'EMPLOYEE.CERTIFICATES.OVERVIEW.ADD-CERTIFICATE' | translate"
  (actionFunction)="showAddCerificatePopup()"
></app-table>
<dx-popup
  [maxHeight]="590"
  [maxWidth]="830"
  [hideOnOutsideClick]="true"
  [showCloseButton]="true"
  [(visible)]="externalCertificatePopupVisible"
  [title]="isEditMode ? ('EMPLOYEE.CERTIFICATES.TITLE-EDIT' | translate) : ('EMPLOYEE.CERTIFICATES.TITLE-ADD' | translate)"
  [dragEnabled]="false"
>
  <div *dxTemplate="let data of 'content'">
    <app-add-external-certificate-dialog
      *ngIf="externalCertificatePopupVisible"
      [editingPersonId]="editingPersonId"
      [isEditMode]="isEditMode"
      [certificate]="currentCertificate"
      (onEventCreated)="onEventCreated()"
    ></app-add-external-certificate-dialog>
  </div>
</dx-popup>

<app-confirmation-dialog
  [(showModal)]="showModal"
  (submit)="deleteEvent()"
  [options]="modalOptions"
></app-confirmation-dialog>
