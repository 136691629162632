import {
  Component,
  ElementRef,
  Input,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { CourseTemplateQuestionModel } from '../../../../models/course/course-template-question.model';

@Component({
  selector: 'app-course-question',
  templateUrl: './course-question.component.html',
  styleUrl: './course-question.component.scss',
})
export class CourseQuestionComponent {
  @Input()
  question!: CourseTemplateQuestionModel;

  @ViewChildren('radioInput')
  radioInputs = new QueryList<ElementRef>();

  selectedOptionIndex: number = -1;

  public isAnsweredCorrect(): boolean {
    const correctIndex = this.question.options.findIndex(
      (option) => option.isCorrect
    );
    if (this.selectedOptionIndex < 0) {
      return false;
    }
    return correctIndex === this.selectedOptionIndex;
  }

  onRadioValueChanged(e: any) {
    this.selectedOptionIndex = this.question.options.findIndex(
      (option) => option.option == e.value.option
    );
  }
}
