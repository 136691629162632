import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TrainingDateModel } from '../../models/training/training-date.model';
import { TrainingDateSelectionModel } from './training-date-selection.model';
import { DateTime } from 'luxon';
import DevExpress from 'devextreme';
import ValueChangedEvent = DevExpress.ui.dxDateBox.ValueChangedEvent;

@Component({
  selector: 'app-training-date-selection',
  templateUrl: './training-date-selection.component.html',
  styleUrls: ['./training-date-selection.component.scss'],
})
export class TrainingDateSelectionComponent implements OnInit {
  @ViewChild('dateSelectionRoot', { static: true }) dateSelectionRoot!: ElementRef<HTMLElement>;

  dates: TrainingDateSelectionModel[] = [
    this.createInitialDateSelectionModel(),
  ];
  @Input()
  onlyFutureDatesAllowed: boolean = false;

  @Output() onFutureDateCheck = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  private createInitialDateSelectionModel(): TrainingDateSelectionModel {
    const now = new Date();
    return {
      startDate: DateTime.now().plus({ day: 1 }).set({ hour: 9, minute: 0, second: 0, millisecond: 0 }),
      endDate: DateTime.now().plus({ day: 1 }).set({ hour: 10, minute: 0, second: 0, millisecond: 0 }),
    };
  }

  emitFutureStatus(): void {
    const allFuture = this.dates.every(date => this.isInFuture(date));
    this.onFutureDateCheck.emit(allFuture);
  }

  onDateAddClick(): void {
    this.dates.push(this.createInitialDateSelectionModel());
  }

  onDateRemoveClick(): void {
    this.dates.pop();
  }

  public patchDates(dates: TrainingDateModel[]): void {
    let datesTemp: TrainingDateSelectionModel[] = [];
    for (let date of dates) {
      datesTemp.push({
        startDate: DateTime.fromISO(date.start),
        endDate: DateTime.fromISO(date.end),
      });
    }
    if (this.dates.length == 0) {
      datesTemp.push(this.createInitialDateSelectionModel());
    }
    datesTemp = datesTemp.sort(function (a, b) {
      return b.startDate.valueOf() - a.startDate.valueOf();
    });
    this.dates = datesTemp;
  }

  public getDates(): TrainingDateModel[] {
    const dates: TrainingDateModel[] = [];
    for (let date of this.dates) {
      if (date.startDate && date.endDate) {
        dates.push({
          start: date.startDate.toISO()!,
          end: date.endDate.toISO()!,
        });
      }
    }
    return dates;
  }

  onStartDateChange(
    trainingDateSelectionModel: TrainingDateSelectionModel,
    valueChangedEvent: ValueChangedEvent
  ) {
    const minutesDif = trainingDateSelectionModel.endDate
      .startOf('minute')
      .diff(
        trainingDateSelectionModel.startDate.startOf('minute'),
        'minute'
      ).minutes;
    trainingDateSelectionModel.startDate = DateTime.fromJSDate(
      valueChangedEvent.value
    );

    trainingDateSelectionModel.endDate =
      trainingDateSelectionModel.startDate.plus({ minute: minutesDif });

    this.emitFutureStatus();
  }

  onEndDateChange(
    trainingDateSelectionModel: TrainingDateSelectionModel,
    valueChangedEvent: ValueChangedEvent
  ) {
    const minutesDif = trainingDateSelectionModel.endDate
      .startOf('minute')
      .diff(
        trainingDateSelectionModel.startDate.startOf('minute'),
        'minute'
      ).minutes;
    trainingDateSelectionModel.endDate = DateTime.fromJSDate(
      valueChangedEvent.value
    );

    if (
      trainingDateSelectionModel.startDate >= trainingDateSelectionModel.endDate
    ) {
      trainingDateSelectionModel.startDate =
        trainingDateSelectionModel.endDate.minus({ minute: minutesDif });
    }
    this.emitFutureStatus();
    // trainingDateSelectionModel.endDate = new Date(endDate);
    // if (moment(trainingDateSelectionModel.startDate).isSameOrAfter(moment(trainingDateSelectionModel.endDate))) {
    //     trainingDateSelectionModel.startDate = moment(trainingDateSelectionModel.endDate).subtract(minutesDif, 'minute').toDate();
    // }
  }

  isEndDate15MinutesAfterStartDate(
    trainingDateSelectionModel: TrainingDateSelectionModel
  ): boolean {
    return (
      trainingDateSelectionModel.endDate >=
      trainingDateSelectionModel.startDate.plus({ minute: 15 })
    );
  }

  isInFuture(trainingDateSelectionModel: TrainingDateSelectionModel): boolean {
    return (
      trainingDateSelectionModel.startDate > DateTime.now() &&
      trainingDateSelectionModel.endDate > DateTime.now()
    );
  }

  isValid(): boolean {
    return (
      this.dates.filter((date) => {
        return (!this.isEndDate15MinutesAfterStartDate(date));
      }).length == 0
    );
  }

  showDatePopup(e: any) {
    e.component.open();
  }

  onPopupClosed(e: any) {
    this.dateSelectionRoot.nativeElement.focus()
  }
}
