import { Injectable } from '@angular/core';
import { TrainingFullModel } from '../models/training/training-full.model';
import {
  PrimaryButtonType,
  SecondaryButtonType,
} from '../models/button-type.model';
import { TrainingStatusModel } from '../models/training/training-status-model';
import { EventMemberModel } from '../models/event-member.model';
import { EventMemberStatusModel } from '../models/event-member-status.model';

@Injectable({
  providedIn: 'root',
})
export class TrainingHelperService {
  constructor() {}

  public getPrimaryButtonType(training: TrainingFullModel): PrimaryButtonType {
    if (training.status == TrainingStatusModel.Open) {
      return PrimaryButtonType.AddAttendees;
    } else if (training.status == TrainingStatusModel.WaitingComplete) {
      return PrimaryButtonType.CompleteTraining;
    } else if (training.status == TrainingStatusModel.Completed) {
      if (training.training.sendFeedbackLink) {
        return PrimaryButtonType.ShowFeedback;
      } else {
        return PrimaryButtonType.DownloadCompletedEventMemberList;
      }
    }
    return PrimaryButtonType.None;
  }

  public getSecondaryButtonTypes(
    training: TrainingFullModel,
    members: EventMemberModel[]
  ): SecondaryButtonType[] {
    const buttonTypes: SecondaryButtonType[] = [];
    if (training.status == TrainingStatusModel.Open) {
      buttonTypes.push(SecondaryButtonType.CopyLink);
      buttonTypes.push(SecondaryButtonType.DownloadMemberList);
      buttonTypes.push(SecondaryButtonType.DownloadIcs);
      buttonTypes.push(SecondaryButtonType.SendInfo);
      buttonTypes.push(SecondaryButtonType.Edit);
      if (
        members.filter((m) => m.status == EventMemberStatusModel.Accepted)
          .length == 0
      ) {
        buttonTypes.push(SecondaryButtonType.Delete);
      }
      if (
        members.filter((m) => m.status == EventMemberStatusModel.Accepted)
          .length > 0
      ) {
        buttonTypes.push(SecondaryButtonType.Cancel);
      }
    }

    if (training.status == TrainingStatusModel.WaitingComplete) {
      buttonTypes.push(SecondaryButtonType.CopyLink);
      buttonTypes.push(SecondaryButtonType.DownloadMemberList);
      buttonTypes.push(SecondaryButtonType.DownloadIcs);
      buttonTypes.push(SecondaryButtonType.SendInfo);
      buttonTypes.push(SecondaryButtonType.Edit);
      buttonTypes.push(SecondaryButtonType.Delete);
    }

    if (training.status == TrainingStatusModel.Completed) {
      buttonTypes.push(SecondaryButtonType.DownloadCompletedEventMemberList);
      if (
        members.filter((m) => m.status == EventMemberStatusModel.Accepted)
          .length == 0
      ) {
        buttonTypes.push(SecondaryButtonType.Delete);
      }
    }

    return buttonTypes;
  }
}
