import {Storage} from "./storage";
import {HttpClient} from "@angular/common/http";
import {concatMap, Observable, of, Subject} from "rxjs";
import {catchError, tap} from "rxjs/operators";
import {Configuration} from "../../assets/Configuration";

export class SCORMAdapter {
    saveUrl: string;
    getAllUrl: string;
    private isFinishedSubject = new Subject<boolean>();
    public isFinished$ = this.isFinishedSubject.asObservable();
    private valuesToSaveSubj = new Subject<LmsValue>();
    private valuesToSave$: Observable<LmsValue> = this.valuesToSaveSubj.asObservable();

    constructor(private httpClient: HttpClient, private courseTemplateId: string) {
        this.saveUrl = Configuration.getApiBaseUrl() + "/scorm/lms/" + courseTemplateId;
        this.getAllUrl = Configuration.getApiBaseUrl() + "/scorm/lms/" + courseTemplateId;
        this.valuesToSave$.pipe(
            concatMap(valueToSave => {
                return this.httpClient.post<void>(this.saveUrl, valueToSave);
            }), catchError(() => of()),
        ).subscribe({
            complete: () => this.isFinishedSubject.next(true)
        });
    }


    apiURL = "";

    set uri(uri) {
        this.apiURL = uri;
    }

    get uri() {
        return this.apiURL;
    }

    get saveUri() {
        return this.apiURL + 'setValue'
    }

    LMSInitialize() {
        console.log('LMS Initialize');
        Storage.setItem('Initialized', true);
        return true;
    }

    loadValuesFromLmsAndInitData(personId: string, personName: string): Observable<any> {
        return this.httpClient.get<{ element: string, value: string }[]>(this.getAllUrl)
            .pipe(tap(lmsValues => {
                Storage.clearAll();
                for (let element of lmsValues) {
                    Storage.setItem(element.element, element.value);
                }
                Storage.setItem("cmi.core.lesson_mode", "normal");
                Storage.setItem("cmi.core.student_id", personId);
                Storage.setItem("cmi.core.student_name", personName);
            }));
    }

    LMSFinish() {
        if (!this._isInitialized()) {
            this._setError(301);
            return false;
        }
        this.valuesToSaveSubj.complete();
        let _return = this.LMSCommit();
        Storage.setItem('Initialized', false);
        Storage.clearAll();
        return _return;
    }

    LMSGetValue(element: any) {
        if (!this._isInitialized()) {
            this._setError(301);
            return false;
        }
        let value = Storage.getItem(element);
        if (!value) {
            this._setError(201);
            return "";
        }
        return value;
    }

    LMSSetValue(element: string, value: string) {
        if (!this._isInitialized()) {
            this._setError(301);
            return false;
        }
        this.valuesToSaveSubj.next({element: element, value: value});
        Storage.setItem(element, value);
        return Storage.getItem(element);
    }

    LMSCommit() {
        return true;
    }

    LMSGetLastError() {
        let errors = Storage.getItem('errors');
        errors = JSON.parse(errors);
        if (errors && errors.length > 0) {
            return errors.pop();
        }
        return "";
    }

    LMSGetErrorString(errorCode: string) {
        errorCode = errorCode.toString();
        const error = "BLA"
        // const error = errorDefinition['errorCode'];
        if (!error) return "";
        return "BLA2"
    }

    LMSGetDiagnostic(errorCode: any) {
        errorCode = errorCode.toString();
        let error = "BLA3"
        if (!error) return "";
        return "BLA4";
    }

    _isInitialized() {
        return Storage.getItem('Initialized');
    }

    _setError(errorCode: any) {
        errorCode = errorCode.toString();
        let errors = Storage.getItem('errors');
        if (!errors) errors = '[]';
        errors = JSON.parse(errors);
        errors.push(errorCode);
        errors = JSON.stringify(errors);
        Storage.setItem('errors', errors);
    }


    errorDefinition = {
        "0": {
            "errorString": "No Error",
            "diagnostic": "No error occurred, the previous API call was successful."
        },
        "101": {
            "errorString": "General Exception",
            "diagnostic": "No specific error code exists to describe the error."
        },
        "201": {
            "errorString": "Invalid argument error",
            "diagnostic": "An argument represents an invalid data model element or is otherwise incorrect."
        },
        "202": {
            "errorString": "Element cannot have children",
            "diagnostic": "LMSGetValue was called with a data model element name that ends in “_children” for a data model element that does not support the “_children” suffix."
        },
        "203": {
            "errorString": "Element not an array. Cannot have count",
            "diagnostic": "LMSGetValue was called with a data model element name that ends in “_count” for a data model element that does not support the “_count” suffix."
        },
        "301": {
            "errorString": "Not initialized",
            "diagnostic": "An API call was made before the call to LMSInitialize."
        },
        "401": {
            "errorString": "Not implemented error",
            "diagnostic": "The data model element indicated in a call to LMSGetValue or LMSSetValue is valid, but was not implemented by this LMS. SCORM 1.2 defines a set of data model elements as being optional for an LMS to implement."
        },
        "402": {
            "errorString": "Invalid set value, element is a keyword",
            "diagnostic": "LMSSetValue was called on a data model element that represents a keyword (elements that end in \"_children\" and \"_count\")."
        },
        "403": {
            "errorString": "Element is read only",
            "diagnostic": "LMSSetValue was called with a data model element that can only be read."
        },
        "404": {
            "errorString": "Element is write only",
            "diagnostic": "LMSGetValue was called on a data model element that can only be written to."
        },
        "405": {
            "errorString": "Incorrect Data Type",
            "diagnostic": "LMSSetValue was called with a value that is not consistent with the data format of the supplied data model element."
        },
    }
}

export interface LmsValue {
    element: string;
    value: string;
}