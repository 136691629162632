<app-page>
  <app-page-title [title]="'STRUCTURE.OVERVIEW.TITLE' | translate">
    <dx-button
      class="cta-btn"
      type="normal"
      icon="add"
      [text]="'STRUCTURE.OVERVIEW.ADD-ORGANIZATION-UNIT' | translate"
      [disabled]="!(selectedOrganisationUnit$ | async)"
      (click)="onAddClick()"
    ></dx-button>
  </app-page-title>
  <dx-tree-list
    id="employees"
    [dataSource]="organisationUnits"
    [rootValue]="null"
    keyExpr="id"
    parentIdExpr="parentId"
    [showBorders]="false"
    [showColumnLines]="false"
    [showRowLines]="true"
    [columnAutoWidth]="true"
    [allowColumnResizing]="true"
    [columnResizingMode]="'nextColumn'"
    [expandedRowKeys]="[1]"
    (selectedRowKeysChange)="onSelectionChanged($event)"
    [expandedRowKeys]="[organisationUnits[0].id]"
    [scrolling]="{mode: 'standard'}"
  >
    <dxo-selection mode="single"></dxo-selection>
    <dxi-column
      dataField="name"
      [caption]="'COMMON.FIELDS.NAME' | translate"
      alignment="left"
      width="600"
    ></dxi-column>
    <dxi-column
      dataField="type"
      [caption]="'COMMON.FIELDS.TYPE' | translate"
      cellTemplate="typeCellTemplate"
      alignment="left"
    ></dxi-column>
    <dxi-column
      dataField="description"
      [caption]="'COMMON.FIELDS.DESCRIPTION' | translate"
      alignment="left"
      cssClass="description-column"
    ></dxi-column>
    <dxi-column
      caption=""
      dataField="this"
      cellTemplate="actionsTemplate"
      width="70px"
      alignment="left"
    ></dxi-column>
    <div *dxTemplate="let data of 'typeCellTemplate'">
      <span *ngIf="data.value == OrganisationUnitTypeModel.Department"
        >{{'COMMON.FIELDS.DEPARTMENT' | translate}}</span
      >
      <span *ngIf="data.value == OrganisationUnitTypeModel.Location"
        >{{'COMMON.FIELDS.LOCATION' | translate}}</span
      >
    </div>
    <div *dxTemplate="let data of 'actionsTemplate'">
      <dx-button
        class="edit-button"
        type="default"
        [icon]="editIcon"
        (click)="onEditClick(data.value.id)"
      >
      </dx-button>
    </div>
  </dx-tree-list>
</app-page>
