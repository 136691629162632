import { Component, Input, OnInit } from '@angular/core';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'app-spinner-overlay',
  templateUrl: './spinner-overlay.component.html',
  styleUrls: ['./spinner-overlay.component.scss']
})
export class SpinnerOverlayComponent implements OnInit {
  isFileUploading: boolean = false;

  constructor(private loadingService: LoadingService) { 
    this.isFileUploading = loadingService.getIsFileUploading();
  }

  ngOnInit(): void {
  }

}
