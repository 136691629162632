import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Configuration} from "../../assets/Configuration";
import {TimeZoneModel} from "../models/time-zone.model";
import {RoleModel} from "../models/person/role.model";

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  constructor(private httpClient: HttpClient) {
  }

  public getAllRoles(): Observable<RoleModel[]> {
    return this.httpClient.get<RoleModel[]>(Configuration.getApiBaseUrl() + "/role/");
  }
}
