import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { DxFormComponent } from 'devextreme-angular';
import { WbdModel } from 'src/app/models/integration/integration.model';
import { FileUtilService } from 'src/app/services/file-util.service';

@Component({
  selector: 'app-integrate-wbd-modal',
  templateUrl: './integrate-wbd-modal.component.html',
  styleUrls: ['./integrate-wbd-modal.component.scss'],
})
export class IntegrateWbdModalComponent {
  @ViewChild('integrateWbdForm') set content(content: DxFormComponent) {
    if (content) {
      this.integrateWbdForm = content;
    }
  }
  integrateWbdForm!: DxFormComponent;

  @Input() showModal = false;
  @Output() showModalChange = new EventEmitter<boolean>();
  @Output() submit = new EventEmitter();

  integrationWbdForm: WbdModel = {
    tgicUserName: '',
    tgicKeystorePassword: '',
    tgicCertificate: '',
    file: null
  };

  constructor(private fileUtilService: FileUtilService) {}

  onDismiss = () => {
    this.showModal = false;
    this.showModalChange.emit(this.showModal);
  };

  onSubmit = () => {
    const isIntegrateWbdFormValid =
      this.integrateWbdForm.instance.validate().isValid;

    if (isIntegrateWbdFormValid) {
      this.submit.emit({
        tgicUserName: this.integrationWbdForm.tgicUserName,
        tgicKeystorePassword: this.integrationWbdForm.tgicKeystorePassword,
        tgicCertificate: this.integrationWbdForm.tgicCertificate,
      });
      this.showModal = false;
      this.showModalChange.emit(this.showModal);
    }
  };

  async onSelectedFileChange(e: any) {
    if (e[0]) {
      const rawFile = await this.fileUtilService.convertFileToRawFileModel(
        e[0]
      );

      this.integrationWbdForm.file = rawFile;
      this.integrationWbdForm.tgicCertificate = rawFile.dataBase64!;
    } else {
      this.integrationWbdForm.file = null;
      this.integrationWbdForm.tgicCertificate = '';
    }
  }
}
