<dx-popup
  [width]="615"
  [height]="400"
  [showTitle]="false"
  [title]="'USER-NAV.CHANGE-PASSWORD' | translate"
  [dragEnabled]="false"
  [hideOnOutsideClick]="false"
  [showCloseButton]="false"
  class="modal"
  [classList]="'modal'"
  [copyRootClassesToWrapper]="false"
  [visible]="showModal"
  (onHidden)="dismiss()"
>
  <div *dxTemplate="let data of 'content'" class="modal-content">
    <div class="modal-title">{{'ELEARNING-QUESTIONS.MODAL.END-CONTENT' | translate}}</div>
    <div class="modal-text">
      <p>{{'ELEARNING-QUESTIONS.MODAL.COPY-1' | translate}}</p>
      <p>{{'ELEARNING-QUESTIONS.MODAL.COPY-2' | translate}}</p>
      <p>{{'ELEARNING-QUESTIONS.MODAL.COPY-3' | translate}}</p>
    </div>
    <div class="modal-button-container">
      <dx-button
        [text]="'ELEARNING-QUESTIONS.MODAL.START-TEST' | translate"
        type="normal"
        (click)="onSubmit()"
      >
      </dx-button>
    </div>
  </div>
</dx-popup>
