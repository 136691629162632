import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingService } from '../../../services/loading.service';
import { TranslateService } from '@ngx-translate/core';
import { TrainingCategoryModel } from 'src/app/models/training-category.model';
import { TrainingCategoryService } from 'src/app/services/training-category.service';
import { MyTrainingsFilters } from '../../training/my-trainings/my-trainings.component';
import { TrainingTypeModel } from 'src/app/models/training/training-type.model';

@Component({
  selector: 'app-training-catalogue-info',
  templateUrl: './training-catalogue-info.component.html',
  styleUrls: ['./training-catalogue-info.component.scss'],
})
export class TrainingCatalogueInfoComponent {
  trainingCategoryId: string;
  trainingCategory: TrainingCategoryModel | null = null;
  trainingsCourses: any = [];
  eventsList: any = [];
  filters: MyTrainingsFilters = {
    trainingTypes: [],
    eventTypes: [],
    searchString: '',
  };

  trainingTypes: any[] = [
    {
      id: TrainingTypeModel.IDD,
      name: this.translateService.instant('COMMON.FIELDS.TRAINING-TYPES.IDD'),
    },
    {
      id: TrainingTypeModel.Compliance,
      name: this.translateService.instant(
        'COMMON.FIELDS.TRAINING-TYPES.COMPLIANCE'
      ),
    },
    {
      id: TrainingTypeModel.General,
      name: this.translateService.instant(
        'COMMON.FIELDS.TRAINING-TYPES.GENERAL'
      ),
    },
  ];
  
  eventTypes: any[] = [
    {
      id: 0,
      name: this.translateService.instant(
        'COMMON.FIELDS.EVENT-TYPES.IN-PERSON'
      ),
    },
    {
      id: 1,
      name: this.translateService.instant('COMMON.FIELDS.EVENT-TYPES.WEBINAR'),
    },
    {
      id: 2,
      name: this.translateService.instant(
        'COMMON.FIELDS.EVENT-TYPES.ELEARNING'
      ),
    },
  ];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public translateService: TranslateService,
    private location: Location,
    private loaderService: LoadingService,
    private trainingCategoryService: TrainingCategoryService,
  ) {
    this.trainingCategoryId = this.route.snapshot.params['id'];
    this.loadTrainingCategotyData();
  }

  loadTrainingCategotyData(): void {
    this.loaderService
      .load(this.trainingCategoryService.getTrainingCategory(this.trainingCategoryId))
      .subscribe((trainingCategory) => {
        this.trainingCategory = trainingCategory;

        this.trainingsCourses = [
          ...trainingCategory.trainings.map((training) => ({
            ...training,
            type: 'Training',
          })),
          ...trainingCategory.courses.map((course) => ({
            ...course,
            type: 'Course',
          })),
        ];

        this.filterEventsList();
      });
  }

  navigateBack(): void {
    this.router.navigate(['training-catalogue']);
  }

  filterByTrainingType() {
    this.eventsList = this.eventsList.filter((obj: any) => {
      if (
        !this.filters.trainingTypes.length ||
        this.filters.trainingTypes.includes(obj.trainingType)
      ) {
        return true;
      } else {
        return false;
      }
    });
  }

  filterByEventType() {
    if (this.filters.eventTypes.length) {
      let filteredTrainings: any = [];
      if (this.filters.eventTypes.includes(0)) {
        filteredTrainings = [
          ...filteredTrainings,
          ...this.eventsList.filter((obj: any) => {
            return obj.location && obj.location.hasOwnProperty('type') && obj.location.type == 0
              ? true
              : false;
          }),
        ];
      }
      if (this.filters.eventTypes.includes(1)) {
        filteredTrainings = [
          ...filteredTrainings,
          ...this.eventsList.filter((obj: any) => {
            return obj.location && obj.location.type && obj.location.type != 0
              ? true
              : false;
          }),
        ];
      }
      if (this.filters.eventTypes.includes(2)) {
        filteredTrainings = [
          ...filteredTrainings,
          ...(this.eventsList = this.eventsList.filter((obj: any) => {
            return !obj.location && !obj.date ? true : false;
          })),
        ];
      }

      this.eventsList = filteredTrainings;
    }
  }

  filterEventsList() {
    this.eventsList = [... this.trainingsCourses];

    this.filterByTrainingType();
    this.filterByEventType();

    if (!!this.filters.searchString) {
      this.eventsList = this.eventsList.filter((obj: any) => {
        return (
          obj.name
            .toLowerCase()
            .includes(this.filters.searchString.toLowerCase()) ||
          (obj.description || '')
            .toLowerCase()
            .includes(this.filters.searchString.toLowerCase())
        );
      });
    }

    this.eventsList = this.eventsList.sort((a: any, b: any) => {
      const start = a.dates ? new Date(a.dates[0].start).getTime() : null;
      const end = b.dates ? new Date(b.dates[0].start).getTime() : null;

      if (start && end) {
        return start - end;
      } else {
        return 0;
      }
    });
  }

  onSearchInput(event: any) {
    this.filters.searchString = event.value;
    this.filterEventsList();
  }

  onTrainingTypeSelected(e: any) {
    if (e.node.selected) {
      this.filters.trainingTypes = [...this.filters.trainingTypes, e.node.key];
    } else {
      this.filters.trainingTypes = this.filters.trainingTypes.filter(
        (id) => id !== e.node.key
      );
    }
    this.filterEventsList();
  }

  onEventTypeSelected(e: any) {
    if (e.node.selected) {
      this.filters.eventTypes = [...this.filters.eventTypes, e.node.key];
    } else {
      this.filters.eventTypes = this.filters.eventTypes.filter(
        (id) => id !== e.node.key
      );
    }
    this.filterEventsList();
  }
}
