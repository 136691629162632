import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Configuration} from "../../assets/Configuration";
import {PersonIddProgressModel} from "../models/dashboard/person-idd-progress.model";
import {EmployeesIddProgressModel} from "../models/dashboard/employees-idd-progress.model";
import {TrainingGroupIddProgressModel} from "../models/dashboard/training-groups-idd-progress.model";
import { ComplianceCourseProgressModel } from '../models/dashboard/compliance-course-progress.model';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private httpClient: HttpClient) {}

  public getPersonProgress(year: number): Observable<PersonIddProgressModel> {
    return this.httpClient.get<any>(
        Configuration.getApiBaseUrl() + `/dashboard/qualification-status-person/${year}`
    );
  }

  public getEmployeesProgress(year: number): Observable<EmployeesIddProgressModel> {
    return this.httpClient.get<EmployeesIddProgressModel>(
        Configuration.getApiBaseUrl() + `/dashboard/qualification-status-employees/${year}`
    );
  }
  
  public getTrainingGroupsProgress(year: number): Observable<TrainingGroupIddProgressModel[]> {
    return this.httpClient.get<TrainingGroupIddProgressModel[]>(
        Configuration.getApiBaseUrl() + `/dashboard/qualification-status-training-groups/${year}`
    );
  }

  public getComplianceCoursesProgress(year: number): Observable<ComplianceCourseProgressModel[]> {
    return this.httpClient.get<ComplianceCourseProgressModel[]>(
        Configuration.getApiBaseUrl() + `/dashboard/qualification-status-compliance/${year}`
    );
  }
}
