import { Component, QueryList, ViewChildren } from '@angular/core';
import { Observable } from 'rxjs';
import { CourseTemplateFullModel } from '../../../models/course/course-template-full.model';
import { CourseTemplateService } from '../../../services/course-template.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingService } from '../../../services/loading.service';
import { CourseQuestionComponent } from './course-question/course-question.component';
import { CourseService } from '../../../services/course.service';
import { CourseContentTypeModel } from 'src/app/models/course/course-content-type.model';

@Component({
  selector: 'app-course-questions',
  templateUrl: './course-questions.component.html',
  styleUrl: './course-questions.component.scss',
})
export class CourseQuestionsComponent {
  courseTemplateId: string;
  course$: Observable<CourseTemplateFullModel>;
  currentQuestion: number = 0;
  isQuestionsAnswered: boolean = false;
  isQuestionsAnsweredCorrectly: boolean = false;
  hideReloadQuestionsButton: boolean = false;

  @ViewChildren(CourseQuestionComponent)
  questionComponents = new QueryList<CourseQuestionComponent>();

  constructor(
    private courseTemplateService: CourseTemplateService,
    private router: Router,
    private route: ActivatedRoute,
    private loadingService: LoadingService,
    private courseService: CourseService
  ) {
    const isSkipQuestions = this.route.snapshot.queryParams['skipQuestions'];
   
    this.courseTemplateId = this.route.snapshot.params['id'];
    this.course$ = this.loadingService.load(
      this.courseTemplateService.getCourseTemplate(this.courseTemplateId)
    );

    this.course$.subscribe(course => {
      if (isSkipQuestions) {
        this.isQuestionsAnsweredCorrectly = true;
        this.isQuestionsAnswered = true;
        this.completeCourse();
      }
    })
  }

  nextQuestion() {
    this.currentQuestion++;
  }

  navigateToCourse() {
    this.router.navigate([
      'my-trainings',
      'course',
      this.courseTemplateId
    ]);
  }

  reloadQuestions() {
    window.location.reload();
  }

  onSubmitClick() {
    const questionComponents = this.questionComponents.toArray();
    for (let i = 0; i < questionComponents.length; i++) {
      if (!questionComponents[i].isAnsweredCorrect()) {
        this.isQuestionsAnswered = true;
        return;
      }
    }

    this.completeCourse();
  }

  completeCourse() {
    this.loadingService
      .load(this.courseService.complete(this.courseTemplateId))
      .subscribe(() => {
        this.isQuestionsAnsweredCorrectly = true;
        this.isQuestionsAnswered = true;
      });
  }
}
