<div class="person-container row d-flex m-auto">
    <div class="col-8 d-flex justify-content-center flex-column">
        <div class="fw-bold">
            {{person.lastName}}, {{person.firstName}}
        </div>
        <div>
            {{person.email}}
        </div>
    </div>
    <div class="col-4 d-flex justify-content-end align-items-center">
        <button *ngIf="showAddButton" mat-icon-button class="add-icon" (click)="onAddClicked.emit(person)">
            <mat-icon>add</mat-icon>
        </button>
        <button *ngIf="showRemoveButton" mat-icon-button class="remove-icon" (click)="onRemoveClicked.emit(person)">
            <mat-icon>delete</mat-icon>
        </button>
    </div>
</div>
