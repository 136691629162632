<dx-popup
  [width]="520"
  [height]="300"
  [showTitle]="false"
  [title]="'USER-NAV.CHANGE-PASSWORD' | translate"
  [dragEnabled]="false"
  [hideOnOutsideClick]="false"
  [showCloseButton]="false"
  class="modal"
  [classList]="'modal'"
  [copyRootClassesToWrapper]="false"
  [visible]="showModal"
  (onHidden)="dismiss()"
>
  <div *dxTemplate="let data of 'content'">
    <div class="confirmation-container">
      <div class="confirmation-message">
        {{ options?.message || 'COMMON.CONFIRMATION-MESSAGE' | translate }}
      </div>
      <div *ngIf="!options?.hideUndo" class="undo-message">
        <i class="dx-icon dx-icon-info"></i>
        {{ 'COMMON.THIS-CANNOT-BE-UNDONE' | translate }}
      </div>
    </div>
  </div>

  <dxi-toolbar-item
    widget="dxButton"
    toolbar="bottom"
    location="before"
    [options]="{
      type: 'default',
      text: options?.dismissButtonText || 'COMMON.CANCEL' | translate,
      onClick: dismiss
    }"
  ></dxi-toolbar-item>
  <dxi-toolbar-item
    widget="dxButton"
    toolbar="bottom"
    location="after"
    [options]="{
      stylingMode: 'contained',
      text: options?.submitButtonText || 'COMMON.CONFIRM' | translate,
      onClick: onSubmit
    }"
  ></dxi-toolbar-item>
</dx-popup>
