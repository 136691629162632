import {Pipe, PipeTransform} from '@angular/core';
import {TrainingDateModel} from "../models/training/training-date.model";

@Pipe({
  name: 'firstStartDate'
})
export class FirstStartDatePipe implements PipeTransform {

  transform(value: TrainingDateModel[]): Date | undefined {
    if (value.length == 0) {
      return undefined;
    }
    const sortedStartDates = value.map(trainingDate => new Date(trainingDate.start)).sort(function (a, b) {
      return a.valueOf() - b.valueOf()
    });
    return sortedStartDates.pop();
  }

}
