<dx-popup
  [width]="600"
  [height]="410"
  [showTitle]="true"
  [title]="'INTEGRATIONS.CONNECT-GUTBERATEN' | translate"
  [dragEnabled]="false"
  [hideOnOutsideClick]="false"
  [showCloseButton]="true"
  class="modal"
  [classList]="'modal'"
  [copyRootClassesToWrapper]="false"
  [visible]="showModal"
  (onHidden)="onDismiss()"
>
  <div *dxTemplate="let data of 'content'" class="modal-content">
    <dx-form
      #integrateWbdForm
      [formData]="integrationWbdForm"
      [showColonAfterLabel]="false"
      labelMode="floating"
    >
      <dxi-item dataField="tgicUserName">
        <dxo-label [text]="'COMMON.FIELDS.USER-ID' | translate"></dxo-label>
        <dxi-validation-rule
          type="required"
          [message]="'VALIDATIONS.USER-ID-REQUIRED' | translate"
        ></dxi-validation-rule>
      </dxi-item>
      <dxi-item
        dataField="tgicKeystorePassword"
        editorType="dxTextBox"
        [editorOptions]="{ mode: 'password' }"
      >
        <dxo-label [text]="'COMMON.FIELDS.PASSWORD' | translate"></dxo-label>
        <dxi-validation-rule
          type="required"
          [message]="'VALIDATIONS.PASSWORD-REQUIRED' | translate"
        ></dxi-validation-rule>
      </dxi-item>
      <dxi-item itemType="group">
        <dxo-col-count-by-screen
          [xs]="1"
          [sm]="1"
          [md]="1"
          [lg]="4"
        ></dxo-col-count-by-screen>
        <dxi-item dataField="file" colSpan="3">
          <div *dxTemplate>
            <dx-file-uploader
              class="certificate-uploader"
              [selectButtonText]="'COMMON.CHOOSE-FILE' | translate"
              [labelText]="'INTEGRATIONS.CERTIFICATE-UPLOAD' | translate"
              [multiple]="false"
              (valueChange)="onSelectedFileChange($event)"
              uploadMode="useForm"
            >
            </dx-file-uploader>
          </div>
        </dxi-item>
      </dxi-item>
    </dx-form>
    <div class="modal-buttons">
      <dx-button
        class="cta-btn"
        type="default"
        [text]="'COMMON.CANCEL' | translate"
        (click)="onDismiss()"
      ></dx-button>
      <dx-button
        class="cta-btn"
        type="normal"
        [text]="'INTEGRATIONS.CONNECT' | translate"
        (click)="onSubmit()"
      ></dx-button>
    </div>
  </div>
</dx-popup>
