import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FileUtilService } from 'src/app/services/file-util.service';
import { LoadingService } from 'src/app/services/loading.service';
import { TrainingService } from 'src/app/services/training.service';

@Component({
  selector: 'app-training-save-calendar-modal',
  templateUrl: './training-save-calendar-modal.component.html',
  styleUrls: ['./training-save-calendar-modal.component.scss'],
})
export class TrainingSaveCalendarModalComponent {
  @Input() showModal = false;
  @Input() trainingId!: string;

  @Output() showModalChange = new EventEmitter<boolean>();
  @Output() submit = new EventEmitter();

  constructor(
    private trainingService: TrainingService,
    private fileUtilService: FileUtilService,
    private loadingService: LoadingService,
    private toastrService: ToastrService
  ) {}

  dismiss = () => {
    this.showModal = false;
    this.showModalChange.emit(this.showModal);
  };

  onSubmit = () => {
    this.submit.emit();
    this.showModal = false;
    this.showModalChange.emit(this.showModal);
  };
}
