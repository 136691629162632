import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, catchError, of } from 'rxjs';
import { Configuration } from '../../assets/Configuration';

@Injectable({
  providedIn: 'root',
})
export class GoogleMapsService {
  private apiKey = 'AIzaSyBlfl-HDcydk9B77yXEFIBhNI_2VQ5OziE';
  private apiLoaded = new BehaviorSubject<boolean>(false);
  apiLoaded$ = this.apiLoaded.asObservable();

  constructor(private httpClient: HttpClient) {}

  public getApiKey(): string {
    return this.apiKey;
  }

  public getAutocomplete(
    input: string
  ): Observable<MapsAutocompletePrediction[]> {
    return this.httpClient
      .get<MapsAutocompletePrediction[]>(
        Configuration.getApiBaseUrl() + '/maps/autocomplete?input=' + input
      )
      .pipe(
        catchError((error) => {
          console.error('Error fetching autocomplete results:', error);
          return of([]);
        })
      );
  }

  public getDetails(placeId: string): Observable<MapsPlaceDetails> {
    return this.httpClient.get<MapsPlaceDetails>(
      Configuration.getApiBaseUrl() + '/maps/details?placeId=' + placeId
    );
  }
}

export interface MatchedSubstring {
  length: number;
  offset: number;
}

export interface MainTextMatchedSubstring {
  length: number;
  offset: number;
}

export interface StructuredFormatting {
  main_text: string;
  main_text_matched_substrings: MainTextMatchedSubstring[];
  secondary_text: string;
}

export interface Term {
  offset: number;
  value: string;
}

export interface MapsAutocompletePrediction {
  description: string;
  matched_substrings: MatchedSubstring[];
  place_id: string;
  reference: string;
  structured_formatting: StructuredFormatting;
  terms: Term[];
  types: string[];
}

export interface AddressComponent {
  long_name: string;
  short_name: string;
  types: string[];
}

export interface Location {
  lat: number;
  lng: number;
}

export interface Northeast {
  lat: number;
  lng: number;
}

export interface Southwest {
  lat: number;
  lng: number;
}

export interface Viewport {
  northeast: Northeast;
  southwest: Southwest;
}

export interface Geometry {
  location: Location;
  viewport: Viewport;
}

export interface PlusCode {
  compound_code: string;
  global_code: string;
}

export interface MapsPlaceDetails {
  address_components: AddressComponent[];
  adr_address: string;
  formatted_address: string;
  geometry: Geometry;
  icon: string;
  icon_background_color: string;
  icon_mask_base_uri: string;
  name: string;
  place_id: string;
  plus_code: PlusCode;
  reference: string;
  types: string[];
  url: string;
  utc_offset: number;
  vicinity: string;
}
