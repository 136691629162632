import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subscription} from "rxjs";
import {DownloadService} from "../services/download.service";

@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.scss']
})
export class DownloadComponent implements OnDestroy {

  private subs = new Subscription();

  runningDownloads: RunningDownload[] = [];

  @ViewChild('downloadLink')
  downloadLinkRef!: ElementRef;

  constructor(private downloadService: DownloadService) {
    const startedSub = downloadService.downloadStarted$.subscribe(downloadStartedEvent => {
      this.runningDownloads.push(
        {
          downloadId: downloadStartedEvent.downloadId,
          fileName: downloadStartedEvent.fileName,
          progressSize: 0,
          progressPercent: 0,
          totalSize: 0
        });
    })
    this.subs.add(startedSub);

    const progressSub = downloadService.downloadProgress$.subscribe(progress => {
      const runningDownload = this.runningDownloads.filter(runningDownload => runningDownload.downloadId == progress.downloadId);
      if (runningDownload.length == 1) {
        runningDownload[0].totalSize = progress.totalSize;
        runningDownload[0].progressSize = progress.progressSize;
        runningDownload[0].progressPercent = (progress.progressSize * 100) / progress.totalSize;
      }
    })
    this.subs.add(progressSub);

    const finishedSub = downloadService.downloadFinished$.subscribe(response => {
      this.runningDownloads = this.runningDownloads.filter(runningDownload => runningDownload.downloadId != response.downloadId);
      const downloadLink = this.downloadLinkRef.nativeElement as HTMLAnchorElement;
      downloadLink.href = window.URL.createObjectURL(response.file);
      downloadLink.download = response.fileName;
      downloadLink.click();
    });
    this.subs.add(finishedSub);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  getPercentage(value: number) {
    return Math.round(value * 100) / 100
  } 
}

export interface RunningDownload {
  downloadId: number;
  fileName: string;
  totalSize: number;
  progressSize: number;
  progressPercent: number;
}
