import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { first } from 'rxjs';
import { peopleIcon } from '../../app-icons';
import { AuthorizationService } from '../../services/authorization.service';
import { LoadingService } from '../../services/loading.service';
import { DashboardService } from '../../services/dashboard.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TableColumn } from 'src/app/models/table-column.model';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent {
  isManager: boolean = false;
  isTrainer: boolean = false;
  isEmployee: boolean = false;
  yearFilter: number | null = null;

  isEmployeeQualificationLoaded: boolean = false;
  isPersonQualificationLoaded: boolean = false;
  isTrainingGroupQualificationLoaded: boolean = false;
  isComplianceCourseQualificationLoaded: boolean = false;

  employeeQualification: { current: string; target: string; missing: string } =
    {
      current: '00:00h',
      target: '00:00h',
      missing: '00:00h',
    };

  managerChartData: Array<{ label: string; value: number; persons: string[] }> =
    [
      {
        label: '0-33%',
        value: 0,
        persons: [],
      },
      {
        label: '34-66%',
        value: 0,
        persons: [],
      },
      {
        label: '67-99%',
        value: 0,
        persons: [],
      },
      {
        label: '100%',
        value: 0,
        persons: [],
      },
    ];

  employeeChartData = [
    {
      label: this.translateService.instant('DASHBOARD.HOURS-MISSING'),
      value: 0,
      originalValue: 0,
    },
    {
      label: this.translateService.instant('DASHBOARD.HOURS-SPENT'),
      value: 0,
      originalValue: 0,
    },
  ];

  courseTrendlines: any[] = [
    {
      name: 'Rhetorik: Selbstbewusster auftreten und kommunizieren!',
      progress: 50,
      lastVisited: '12 Dec 2023 (05:43 AM)',
      timePassed: '1h 32min / 2h',
    },
    {
      name: 'Rhetorik: Selbstbewusster auftreten und kommunizieren!',
      progress: 50,
      lastVisited: '12 Dec 2023 (05:43 AM)',
      timePassed: '1h 32min / 2h',
    },
    {
      name: 'Rhetorik: Selbstbewusster auftreten und kommunizieren!',
      progress: 50,
      lastVisited: '12 Dec 2023 (05:43 AM)',
      timePassed: '1h 32min / 2h',
    },
    {
      name: 'Rhetorik: Selbstbewusster auftreten und kommunizieren!',
      progress: 50,
      lastVisited: '12 Dec 2023 (05:43 AM)',
      timePassed: '1h 32min / 2h',
    },
    {
      name: 'Rhetorik: Selbstbewusster auftreten und kommunizieren!',
      progress: 50,
      lastVisited: '12 Dec 2023 (05:43 AM)',
      timePassed: '1h 32min / 2h',
    },
    {
      name: 'Rhetorik: Selbstbewusster auftreten und kommunizieren!',
      progress: 50,
      lastVisited: '12 Dec 2023 (05:43 AM)',
      timePassed: '1h 32min / 2h',
    },
  ];

  yearsDropdownDataSource: any[] = [
    {
      id: new Date().getFullYear(),
      name: new Date().getFullYear(),
    },
    {
      id: new Date().getFullYear() - 1,
      name: new Date().getFullYear() - 1,
    },
    {
      id: new Date().getFullYear() - 2,
      name: new Date().getFullYear() - 2,
    },
    {
      id: new Date().getFullYear() - 3,
      name: new Date().getFullYear() - 3,
    },
    {
      id: new Date().getFullYear() - 4,
      name: new Date().getFullYear() - 4,
    },
  ];

  trainingGroupsQualificationData: any[] = [];
  trainingGroupsQualificationColumns: TableColumn[] = [
    {
      caption: this.translateService.instant('COMMON.FIELDS.TRAINING-GROUP'),
      dataField: 'trainingGroupName',
      dataType: 'string',
      disableFiltering: true,
    },
    {
      caption: this.translateService.instant('COMMON.FIELDS.PROGRESS'),
      dataField: 'progress',
      dataType: 'string',
      disableFiltering: true,
    },
    {
      caption: this.translateService.instant('COMMON.FIELDS.PROGRESS') + ' %',
      dataField: 'progressPercentage',
      dataType: 'string',
      name: 'progressPercentage',
      calculateDisplayValue: (data: any): string => {
        return data.progressPercentage + ' %';
      },
      disableFiltering: true,
    },
    {
      caption: this.translateService.instant('COMMON.FIELDS.PROGRESS-BAR'),
      dataField: 'progressPercentage',
      dataType: 'string',
      name: 'progressPercentageBar',
      cellTemplate: 'progressBullet',
      disableFiltering: true,
    },
  ];

  complianceCoursesQualificationData: any[] = [];
  complianceCoursesQualificationColumns: TableColumn[] = [
    {
      caption: this.translateService.instant('COMMON.FIELDS.NAME'),
      dataField: 'name',
      dataType: 'string',
      disableFiltering: true,
      cssClass: 'width-300',
    },
    {
      caption: this.translateService.instant('COMMON.FIELDS.PROGRESS'),
      dataField: 'progress',
      dataType: 'string',
      disableFiltering: true,
    },
    {
      caption: this.translateService.instant('COMMON.FIELDS.PROGRESS') + ' %',
      dataField: 'progressPercentage',
      dataType: 'string',
      name: 'progressPercentage',
      calculateDisplayValue: (data: any): string => {
        return data.progressPercentage + ' %';
      },
      disableFiltering: true,
    },
    {
      caption: this.translateService.instant('COMMON.FIELDS.PROGRESS-BAR'),
      dataField: 'progressPercentage',
      dataType: 'string',
      name: 'progressPercentageBar',
      cellTemplate: 'progressBullet',
      disableFiltering: true,
    },
  ];

  get peopleIcon() {
    return this.sanitized.bypassSecurityTrustHtml(peopleIcon);
  }

  constructor(
    private sanitized: DomSanitizer,
    private authorizationService: AuthorizationService,
    private loadingService: LoadingService,
    private dashboardService: DashboardService,
    private router: Router,
    private translateService: TranslateService
  ) {
    this.yearFilter = this.yearsDropdownDataSource[0].id;
    this.customizeEmployeeChartText =
      this.customizeEmployeeChartText.bind(this);
    this.customizeChartLabelText = this.customizeChartLabelText.bind(this);

    this.authorizationService
      .getCurrentPerson()
      .pipe(first())
      .subscribe((currentUser) => {
        switch (currentUser!.person.role.name) {
          case 'SuperAdmin':
          case 'Manager':
            this.isManager = true;
            break;
          case 'Trainer':
            this.isTrainer = true;
            break;
          case 'Employee':
            this.isEmployee = true;
            break;
        }

        this.loadQualificationStatusData();
      });
  }

  loadQualificationStatusData() {
    this.isEmployeeQualificationLoaded = false;
    this.isPersonQualificationLoaded = false;
    this.isTrainingGroupQualificationLoaded = false;
    this.isComplianceCourseQualificationLoaded = false;

    if (this.isManager) {
      this.dashboardService
        .getEmployeesProgress(this.yearFilter!)
        .subscribe((employeesProgress) => {
          this.managerChartData[0].value = 0;
          this.managerChartData[1].value = 0;
          this.managerChartData[2].value = 0;
          this.managerChartData[3].value = 0;

          this.managerChartData[0].persons = [];
          this.managerChartData[1].persons = [];
          this.managerChartData[2].persons = [];
          this.managerChartData[3].persons = [];

          employeesProgress!.employeeIddProgresses.forEach((progress) => {
            const personPercentage =
              (progress.employeesIddTimeMinutesReached * 100) /
              employeesProgress!.tenantIddTimeMinutesTarget;

            if (personPercentage >= 0 && personPercentage < 34) {
              this.managerChartData[0].value++;
              this.managerChartData[0].persons.push(progress.personId);
            } else if (personPercentage >= 34 && personPercentage < 67) {
              this.managerChartData[1].value++;
              this.managerChartData[1].persons.push(progress.personId);
            } else if (personPercentage >= 67 && personPercentage < 100) {
              this.managerChartData[2].value++;
              this.managerChartData[2].persons.push(progress.personId);
            } else if (personPercentage >= 100) {
              this.managerChartData[3].value++;
              this.managerChartData[3].persons.push(progress.personId);
            }
          });

          this.managerChartData = [...this.managerChartData];
          this.isEmployeeQualificationLoaded = true;
        });
    }

    if (this.isManager || this.isTrainer) {
      this.dashboardService
        .getTrainingGroupsProgress(this.yearFilter!)
        .subscribe((complianceCoursesProgress) => {
          this.trainingGroupsQualificationData = complianceCoursesProgress!
            .map((data, index) => {
              return {
                id: index,
                trainingGroupName: data.trainingGroupName,
                trainingGroupId: data.trainingGroupId,
                progress: this.getDurationStringHours(
                  data.iddTimeMinutesReached,
                  data.iddTimeMinutesTarget
                ),
                progressPercentage: Math.min(
                  Math.round(
                    (data.iddTimeMinutesReached / data.iddTimeMinutesTarget) *
                      100
                  ),
                  100
                ),
              };
            })
            .sort((a, b) => {
              if (a.trainingGroupName < b.trainingGroupName) {
                return -1;
              }
              if (a.trainingGroupName > b.trainingGroupName) {
                return 1;
              }
              return 0;
            });

          this.isTrainingGroupQualificationLoaded = true;
        });

      this.dashboardService
        .getComplianceCoursesProgress(this.yearFilter!)
        .subscribe((trainingGroupsProgress) => {
          this.complianceCoursesQualificationData = trainingGroupsProgress!
            .map((data, index) => {
              return {
                id: data.id,
                name: data.name,
                progress: `${data.numberOfPersonsCompleted} / ${
                  data.numberOfPersonsOpen + data.numberOfPersonsCompleted
                }`,
                progressPercentage:
                  data.numberOfPersonsOpen + data.numberOfPersonsCompleted > 0
                    ? Math.min(
                        Math.round(
                          (data.numberOfPersonsCompleted /
                            (data.numberOfPersonsOpen +
                              data.numberOfPersonsCompleted)) *
                            100
                        ),
                        100
                      )
                    : 0,
              };
            })
            .sort((a, b) => {
              if (a.name < b.name) {
                return -1;
              }
              if (a.name > b.name) {
                return 1;
              }
              return 0;
            });

          console.log('213213');
          this.isComplianceCourseQualificationLoaded = true;
        });
    }

    this.dashboardService
      .getPersonProgress(this.yearFilter!)
      .subscribe((personProgress) => {
        if (personProgress) {
          const missingTime =
            personProgress.tenantIddTimeMinutesTarget -
              personProgress.sumIddTimeMinutesReached <
            0
              ? 0
              : personProgress.tenantIddTimeMinutesTarget -
                personProgress.sumIddTimeMinutesReached;
          this.employeeQualification.current = this.getDurationString(
            personProgress.sumIddTimeMinutesReached
          );
          this.employeeQualification.target = this.getDurationString(
            personProgress.tenantIddTimeMinutesTarget
          );
          this.employeeQualification.missing =
            this.getDurationString(missingTime);

          const currentPercentage =
            Math.round(
              (100 * personProgress.sumIddTimeMinutesReached) /
                personProgress.tenantIddTimeMinutesTarget
            ) || 0;

          this.employeeChartData[0].value =
            currentPercentage <= 100 ? 100 - currentPercentage : 0;
          this.employeeChartData[1].value = currentPercentage;

          this.employeeChartData[0].originalValue =
            personProgress.tenantIddTimeMinutesTarget;
          this.employeeChartData[1].originalValue =
            personProgress.sumIddTimeMinutesReached;
        }

        this.employeeChartData = [...this.employeeChartData];
        this.isPersonQualificationLoaded = true;
      });
  }

  getDurationString(minutes: number) {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    const durationString = `${hours}:${
      remainingMinutes < 10 ? '0' : ''
    }${remainingMinutes}h`;

    return durationString;
  }

  getDurationStringHours(minutesReached: number, minutesTraget: number) {
    const hoursReached = Math.floor(minutesReached / 60);
    const hoursTraget = Math.floor(minutesTraget / 60);

    return `${hoursReached} / ${hoursTraget} h`;
  }

  customizeChartLabel(pointInfo: any) {
    return `${pointInfo.argument} (${pointInfo.value})`;
  }

  customizeChartLabelText(arg: any) {
    let value;

    if (arg.pointName == '0-33%') {
      value = this.managerChartData[0].value;
    } else if (arg.pointName == '34-66%') {
      value = this.managerChartData[1].value;
    } else if (arg.pointName == '67-99%') {
      value = this.managerChartData[2].value;
    } else if (arg.pointName == '100%') {
      value = this.managerChartData[3].value;
    }

    return `${arg.pointName} (${value})`;
  }

  customizeEmployeeChartText(arg: any) {
    const currentPercentage =
      Math.round(
        (100 * this.employeeChartData[1].originalValue) /
          this.employeeChartData[0].originalValue
      ) || 0;

    if (
      arg.pointName === 'Hours Missing' ||
      arg.pointName === 'Fehlende Stunden'
    ) {
      return `${arg.pointName}: ${this.employeeQualification.missing} (${
        currentPercentage < 100 ? 100 - currentPercentage : 0
      }%)`;
    } else {
      return `${arg.pointName}: ${this.employeeQualification.current} (${
        currentPercentage > 100 ? 100 : currentPercentage
      }%)`;
    }
  }

  customizeEmployeeChartPoint(pointInfo: any) {
    if (
      pointInfo.argument === 'Hours Missing' ||
      pointInfo.argument === 'Fehlende Stunden'
    ) {
      return { color: '#f53d3dcc' };
    } else {
      return { color: '#66cc66cc' };
    }
  }

  customizeManagerChartPoint(pointInfo: any) {
    if (pointInfo.argument === '0-33%') {
      return { color: '#f53d3dcc' };
    } else if (pointInfo.argument === '34-66%') {
      return { color: '#e8b34acc' };
    } else if (pointInfo.argument === '67-99%') {
      return { color: '#aed959cc' };
    } else {
      return { color: '#66cc66cc' };
    }
  }

  onManagerPointClick(event: any) {
    const persons = event.target.data.persons;

    this.router.navigate(['/employee'], {
      queryParams: { persons: JSON.stringify(persons) },
    });
  }

  onTrainingGroupClick(event: any) {
    const qualificationData = this.trainingGroupsQualificationData.find(
      (data) => data.id === event
    );

    if (qualificationData) {
      this.router.navigate(['/employee'], {
        queryParams: { trainingGroupId: qualificationData.trainingGroupId },
      });
    }
  }

  onCourseClick(event: any) {
    this.router.navigate(['/manage-elearning/' + event]);
  }

  onEmployeePointClick() {
    this.router.navigate(['profile'], {
      queryParams: { tab: 'trainings' },
    });
  }

  onYearFilterChanged(event: any) {
    this.yearFilter = event.value;
    this.loadQualificationStatusData();
  }
}
