import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Configuration} from "../../assets/Configuration";
import {TrainingCategoryFileModel, TrainingCategoryModel} from "../models/training-category.model";
import {TrainingCategoryCreationAndUpdateModel} from "../models/training-category-creation-and-update.model";
import { RawFileModel } from '../models/raw-file-model';

@Injectable({
  providedIn: 'root'
})
export class TrainingCategoryService {

  constructor(private httpClient: HttpClient) {
  }

  public getAllTrainingCategories(): Observable<TrainingCategoryModel[]> {
    return this.httpClient.get<TrainingCategoryModel[]>(Configuration.getApiBaseUrl() + "/trainingcategory/");
  }

  public getTrainingCategory(id: string): Observable<TrainingCategoryModel> {
    return this.httpClient.get<TrainingCategoryModel>(Configuration.getApiBaseUrl() + "/trainingcategory/" + id);
  }

  public addTrainingCategory(model: TrainingCategoryCreationAndUpdateModel): Observable<TrainingCategoryModel> {
    return this.httpClient.post<TrainingCategoryModel>(Configuration.getApiBaseUrl() + "/trainingcategory/", model);
  }

  public updateTrainingCategory(id: string, model: TrainingCategoryCreationAndUpdateModel): Observable<TrainingCategoryModel> {
    return this.httpClient.post<TrainingCategoryModel>(Configuration.getApiBaseUrl() + "/trainingcategory/" + id, model);
  }

  public deleteTrainingCategory(id: string): Observable<void> {
    return this.httpClient.delete<void>(Configuration.getApiBaseUrl() + "/trainingcategory/" + id);
  }

  public addTrainingCategoryFile(id: string, file: RawFileModel): Observable<TrainingCategoryFileModel> {
    return this.httpClient.post<TrainingCategoryFileModel>(Configuration.getApiBaseUrl() + "/trainingcategory/" + id + "/file", file);
  }

  public deleteTrainingCategoryFile(id: string, fileId: string): Observable<void> {
    return this.httpClient.delete<void>(Configuration.getApiBaseUrl() + "/trainingcategory/" + id + "/file/" + fileId);
  }
}
