import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import {AuthorizationService} from "../../services/authorization.service";

@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  @Output()
  menuToggle = new EventEmitter<boolean>();

  @Input()
  menuToggleEnabled = false;

  @Input()
  user: null | any;

  showChangePasswordModal = false;

  get userName() {
    return `${this.user!.firstName} ${this.user!.lastName}`;
  }

  constructor(
    private router: Router,
    private authorizationService: AuthorizationService
  ) {}

  ngOnInit() {}

  toggleMenu = () => {
    this.menuToggle.emit();
  };

  openProfile() {
    this.router.navigate(['profile']);
  }

  logout() {
    this.authorizationService.logout();
  }

  openChangePasswordModal() {
    this.showChangePasswordModal = true;
  }
}
