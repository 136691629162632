import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { EmployeeOverviewComponent } from './pages/employee/employee-overview/employee-overview.component';
import { OrganisationUnitOverviewComponent } from './pages/organization-unit/organisation-unit-overview/organisation-unit-overview.component';
import { OrganizationUnitFormComponent } from './pages/organization-unit/organization-unit-form/organization-unit-form.component';
import { EmployeeFormComponent } from './pages/employee/employee-form/employee-form.component';
import { IntegrationsComponent } from './pages/integrations/integrations.component';
import { WebexComponent } from './pages/integrations/webex/webex.component';
import { TrainingOverviewComponent } from './pages/training/training-overview/training-overview.component';
import { GotowebinarComponent } from './pages/integrations/gotowebinar/gotowebinar.component';
import { TrainingCalendarComponent } from './pages/training/training-calendar/training-calendar.component';
import { TrainingMessageComponent } from './pages/training/training-invite/training-message.component';
import { ElearningMessageComponent } from './pages/manage-elearning/elearning-invite/elearning-message.component';
import { TrainingCompleteComponent } from './pages/training/training-complete/training-complete.component';
import { ExternalContactsOverviewComponent } from './pages/external-contacts/external-contacts-overview/external-contacts-overview.component';
import { ExternalContactsFormComponent } from './pages/external-contacts/external-contacts-form/external-contacts-form.component';
import { MyTrainingsComponent } from './pages/training/my-trainings/my-trainings.component';
import { TrainingInfoComponent } from './pages/training/training-info/training-info.component';
import { CourseInfoComponent } from './pages/training/course-info/course-info.component';
import { AdministrationComponent } from './pages/administration/administration.component';
import { TrainingCategoriesDetailsComponent } from './pages/administration/training-categories-details/training-categories-details.component';
import { TrainingGroupsDetailsComponent } from './pages/administration/training-groups-details/training-groups-details.component';
import { ManageElearningOverviewComponent } from './pages/manage-elearning/manage-elearning-overview/manage-elearning-overview.component';
import { EditManageElearningComponent } from './pages/manage-elearning/edit-manage-elearning/edit-manage-elearning.component';
import { TrainingFormComponent } from './pages/training/training-form/training-form.component';
import {CourseQuestionsComponent} from "./pages/training/course-questions/course-questions.component";
import {CourseCompletedComponent} from "./pages/training/course-completed/course-completed.component";
import {TrainingTemplateFormComponent} from './pages/training-template/training-template-form/training-template-form.component';
import {GeneralSettingsComponent} from './pages/general-settings/general-settings.component';
import { TrainingFeedbackComponent } from './pages/training/training-feedback/training-feedback.component';
import { FeedbackComponent } from "./pages/feedback/feedback.component";
import { MyInvitationComponent } from "./pages/my-invitation/my-invitation.component";
import { TestComponent } from "./pages/test/test.component";
import { LoginComponent } from "./pages/login/login.component";
import { ImportDataComponent } from "./pages/administration/import-data/import-data.component";
import { TrainingCatalogueOverviewComponent } from "./pages/training-catalogue/training-catalogue-overview/training-catalogue-overview.component";
import { TrainingCatalogueInfoComponent } from "./pages/training-catalogue/training-catalogue-info/training-catalogue-info.component";

const routes: Routes = [
  {
    path: 'dashboard',
    component: DashboardComponent
  },
  {
    path: 'organisation-unit',
    component: OrganisationUnitOverviewComponent
  },
  {
    path: 'organisation-unit/add',
    component: OrganizationUnitFormComponent
  },
  {
    path: 'organisation-unit/edit/:id',
    component: OrganizationUnitFormComponent
  },
  {
    path: 'employee',
    component: EmployeeOverviewComponent
  },
  {
    path: 'employee/add',
    component: EmployeeFormComponent
  },
  {
    path: 'employee/edit/:id',
    component: EmployeeFormComponent
  },
  {
    path: 'external-contacts',
    component: ExternalContactsOverviewComponent
  },
  {
    path: 'external-contacts/add',
    component: ExternalContactsFormComponent
  },
  {
    path: 'external-contacts/edit/:id',
    component: ExternalContactsFormComponent
  },
  {
    path: 'training-template/add',
    component: TrainingTemplateFormComponent
  },
  {
    path: 'training-template/:id',
    component: TrainingTemplateFormComponent
  },
  {
    path: 'training-template/edit/:id',
    component: TrainingTemplateFormComponent
  },
  {
    path: 'training-calendar',
    component: TrainingCalendarComponent
  },
  {
    path: 'training',
    component: TrainingOverviewComponent
  },
  {
    path: 'training/add',
    component: TrainingFormComponent
  },
  {
    path: 'training/:id',
    component: TrainingFormComponent
  },
  {
    path: 'training/edit/:id',
    component: TrainingFormComponent
  },
  {
    path: 'training/invite/:id',
    component: TrainingMessageComponent
  },
  {
    path: 'training/message/:id',
    component: TrainingMessageComponent
  },
  {
    path: 'training/complete/:id',
    component: TrainingCompleteComponent
  },
  {
    path: 'training/feedback/:id',
    component: TrainingFeedbackComponent
  },
  {
    path: 'training-catalogue',
    component: TrainingCatalogueOverviewComponent
  },
  {
    path: 'training-catalogue/:id',
    component: TrainingCatalogueInfoComponent
  },
  {
    path: 'training-catalogue/:categoryId/training/:id',
    component: TrainingInfoComponent
  },
  {
    path: 'training-catalogue/:categoryId/course/:id',
    component: CourseInfoComponent
  },
  {
    path: 'my-trainings',
    component: MyTrainingsComponent
  },
  {
    path: 'my-trainings/training/:id',
    component: TrainingInfoComponent
  },
  {
    path: 'my-trainings/course/:id',
    component: CourseInfoComponent
  },
  {
    path: 'my-trainings/course/:id/questions',
    component: CourseQuestionsComponent
  },
  {
    path: 'my-trainings/course/:id/completed',
    component: CourseCompletedComponent
  },
  {
    path: 'settings',
    component: GeneralSettingsComponent
  },
  {
    path: 'integrations',
    component: IntegrationsComponent
  },
  {
    path: 'integrations/webex/oauth',
    component: WebexComponent
  },
  {
    path: 'integrations/gotowebinar/oauth',
    component: GotowebinarComponent
  },
  {
    path: 'administration',
    component: AdministrationComponent
  },
  {
    path: 'administration/training-categories/:id',
    component: TrainingCategoriesDetailsComponent
  },
  {
    path: 'administration/training-group/:id',
    component: TrainingGroupsDetailsComponent
  },
  {
    path: 'manage-elearning',
    component: ManageElearningOverviewComponent
  },
  {
    path: 'manage-elearning/:id',
    component: EditManageElearningComponent
  },
  {
    path: 'manage-elearning/:mode/:id',
    component: EditManageElearningComponent
  },
  {
    path: 'elearning/invite/:id',
    component: ElearningMessageComponent
  },
  {
    path: 'elearning/feedback/:id',
    component: TrainingFeedbackComponent
  },
  {
    path: 'feedback/v2/:key',
    component: FeedbackComponent
  },
  {
    path: 'Feedback/v2/:key',
    component: FeedbackComponent
  },
  {
    path: 'my-invitation/:key',
    component: MyInvitationComponent
  },
  {
    path: 'my-invitation/:key/:token',
    component: MyInvitationComponent
  },
  {
    path: 'profile',
    component: EmployeeFormComponent
  },
  {
    path: 'test',
    component: TestComponent
  },
  {
    path: 'importdata',
    component: ImportDataComponent
  },
  {
    path: '',
    component: LoginComponent
  },
  {
    path: 'login/:company',
    component: LoginComponent
  },
  {
    path: '**',
    redirectTo: '/dashboard',
    pathMatch: 'full'
  }
];
const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
      initialNavigation: !isIframe ? 'enabledNonBlocking' : 'disabled'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
