import {Injectable} from '@angular/core';
import {HttpBackend, HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {FeedbackModel} from "../models/feedback/feedback.model";
import {Configuration} from "../../assets/Configuration";
import {SurveyAnswerCreationModel} from "../models/feedback/survey-answer-creation.model";

@Injectable({
    providedIn: 'root'
})
export class FeedbackService {

    private httpClient: HttpClient;

    constructor(private httpBackend: HttpBackend) {
        this.httpClient = new HttpClient(httpBackend);
    }

    public getFeedback(eventKey: string): Observable<FeedbackModel> {
        return this.httpClient.get<FeedbackModel>(Configuration.getApiBaseUrl() + '/feedback/' + eventKey);
    }

    public sendFeedback(eventKey: string, answerCreationModels: SurveyAnswerCreationModel[]): Observable<void> {
        return this.httpClient.post<void>(Configuration.getApiBaseUrl() + '/feedback/' + eventKey, answerCreationModels);
    }
}
