import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class StorageService {

    constructor() {
    }

    public set(key: StorageKey, value: string): void {
        sessionStorage.setItem(key, value);
    }

    public get(key: StorageKey): string | null {
        return sessionStorage.getItem(key);
    }

    public getNumber(key: StorageKey): number | null {
        const str = this.get(key);
        return str ? +str : null;
    }
}

export enum StorageKey {
    TRAINING_OVERVIEW_TAB_INDEX = 'TRAINING_OVERVIEW_TAB_INDEX'
}
