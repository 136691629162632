import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {CourseTemplateSummaryModel} from "../models/course/course-template-summary.model";
import {Configuration} from "../../assets/Configuration";
import {HttpClient} from "@angular/common/http";
import {CourseSummaryModel} from "../models/course/course-summary.model";
import {WebinarAttendeeModel} from "../models/course/webinar-attendee.model";
import {CourseParticipationStatusModel} from "../models/course/course-participation-status.model";

@Injectable({
    providedIn: 'root'
})
export class CourseService {

    constructor(private httpClient: HttpClient) {
    }

    public getAllCourses(): Observable<CourseSummaryModel[]> {
        return this.httpClient.get<CourseTemplateSummaryModel[]>(Configuration.getApiBaseUrl() + "/CourseTemplate");
    }

    public trackVideoProgress(courseTemplateId: string, videoProgressSeconds: number): Observable<WebinarAttendeeModel> {
        return this.httpClient.post<WebinarAttendeeModel>(Configuration.getApiBaseUrl() + "/course/" + courseTemplateId + "/track-video-progress?videoProgressSeconds=" + videoProgressSeconds, {});
    }

    public complete(courseTemplateId: string): Observable<void> {
        return this.httpClient.post<void>(Configuration.getApiBaseUrl() + "/course/" + courseTemplateId + "/complete", {});
    }

    public getParticipationStatus(courseTemplateId: string): Observable<CourseParticipationStatusModel> {
        return this.httpClient.get<CourseParticipationStatusModel>(Configuration.getApiBaseUrl() + "/course/" + courseTemplateId + "/participation-status");
    }

    public getExternalUrl(courseTemplateId: string): Observable<string> {
        return this.httpClient.get(Configuration.getApiBaseUrl() + "/course/" + courseTemplateId + "/external-url", {responseType: 'text'});
    }
}
