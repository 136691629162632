import {Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
    selector: 'img[appTrainingImageFallback]'
})
export class TrainingImageFallbackDirective {

    trainingPlaceholderImageUrl: string = 'https://file.skeddle.com/skeddle/_crm%2Fd787cc26b08a4a8e873f51b578c934f7.jpg';

    constructor(private elementRef: ElementRef) {
    }

    @HostListener('error')
    loadFallbackOnError(): void {
        const element: HTMLImageElement = <HTMLImageElement>this.elementRef.nativeElement;
        element.src = this.trainingPlaceholderImageUrl;
    }
}
