import { Component, OnInit, ViewChild } from '@angular/core';
import { TrainingTemplateService } from '../../../services/training-template.service';
import { OrganisationUnitService } from '../../../services/organisation-unit.service';
import { forkJoin, Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import { OrganisationUnitModel } from '../../../models/organisation-unit/organisation-unit.model';
import { shareReplay, switchMap } from 'rxjs/operators';
import { TrainingGroupModel } from '../../../models/training-group.model';
import { PersonService } from '../../../services/person.service';
import { TrainingGroupService } from '../../../services/training-group.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoadingService } from '../../../services/loading.service';
import { DxSelectBoxTypes } from 'devextreme-angular/ui/select-box';
import { DxFormComponent } from 'devextreme-angular/ui/form';
import { FileUtilService } from '../../../services/file-util.service';
import { TrainingTypeModel } from '../../../models/training/training-type.model';
import { TrainingLocationTypeModel } from '../../../models/training/training-location-type.model';
import { GoogleMapsService } from '../../../services/google-maps.service';
import { TimeService } from '../../../services/time.service';
import { TrainingHelperService } from '../../../services/training-helper.service';
import { TrainingTemplateFullModel } from 'src/app/models/training-template/training-template-full.model';
import { TrainingTemplateCreationAndUpdateModel } from 'src/app/models/training-template/training-template-creation-and-update.model';
import { TrainingLocationCreationAndUpdateModel } from 'src/app/models/training/training-location-creation-and-update.model';
import { TranslateService } from '@ngx-translate/core';
import { ExternalContactService } from '../../../services/external-contacts.service';
import { IntegrationsService } from '../../../services/integration/integrations.service';
import { IntegrationTypeModel } from '../../../models/integration/integration-type.model';
import { DxTabsTypes } from 'devextreme-angular/ui/tabs';

interface SelectOption {
  id: string;
  name: string;
  selected?: boolean;
}

@Component({
  selector: 'app-training-template-form',
  templateUrl: './training-template-form.component.html',
  styleUrls: ['./training-template-form.component.scss'],
})
export class TrainingTemplateFormComponent implements OnInit {
  @ViewChild('descriptionForm', { static: true })
  descriptionForm!: DxFormComponent;
  @ViewChild('settingsForm', { static: true }) settingsForm!: DxFormComponent;
  @ViewChild('locationForm', { static: true }) locationForm!: DxFormComponent;

  private googlePlaceinputChanged = new Subject<string>();
  googleAutocompleteResults$ = this.googlePlaceinputChanged.pipe(
    debounceTime(300),
    distinctUntilChanged(),
    switchMap((searchTerm) => this.mapsService.getAutocomplete(searchTerm))
  );

  isDuplicate: boolean = false;
  duplicateTrainingTemplateId!: string;
  isViewMode: boolean = false;
  googlePlaces: any[] = [];
  googleSearchText: string = '';
  TrainingLocationType = TrainingLocationTypeModel;
  TrainingTypeModel = TrainingTypeModel;
  editingTrainingTemplateId!: string;

  organizationUnits!: OrganisationUnitModel[];
  trainingGroups!: TrainingGroupModel[];
  trainers!: SelectOption[];
  trainingTypeOptions!: DxSelectBoxTypes.Properties;
  trainingLocationTypeOptions!: DxSelectBoxTypes.Properties;
  integrations: Array<any> = [];
  isDescriptionExpanded: boolean = false;
  isCertificateDescriptionExpanded: boolean = false;
  selectedDescriptionTab: number = 0;

  descriptionTabs: any[] = [
    {
      id: 0,
      text: this.translateService.instant('TRAINING.FORM.EXTENDED-DESCRIPTION'),
    },
    {
      id: 1,
      text: this.translateService.instant('TRAINING.FORM.TEXT-FOR-CERTIFICATES'),
    },
  ];

  trainingTemplateForm: TrainingTemplateCreationAndUpdateModel = {
    name: '',
    description: '',
    descriptionExtended: null,
    descriptionCertificate: null,
    trainerIds: [],
    trainingType: null,
    iddTimeMinutes: null,
    iddHours: 0,
    iddMinutes: 0,
    sendCertificateLink: true,
    sendFeedbackLink: true,
    organisationUnitLocationIds: [],
    trainingGroupIds: [],
    maximumMembers: null,
    trainingLocation: {
      locationType: null,
      location: '',
      suffix: '',
      placeId: '',
      latitude: null,
      longitude: null,
    },
    emailBody: '',
    imageBase64: null,
    parentTemplateIds: [],
  };

  get getTrainingLocationLabel() {
    return this.trainingTemplateForm.trainingLocation!.locationType == 1
      ? this.translateService.instant('TRAINING.FORM.PLATFORM')
      : this.translateService.instant('COMMON.FIELDS.TRAINING-LOCATION');
  }

  get getTrainingLocationDetailsLabel() {
    return this.trainingTemplateForm.trainingLocation!.locationType == 1
      ? this.translateService.instant('TRAINING.FORM.LINK-OR-MEETING')
      : this.translateService.instant('COMMON.FIELDS.LOCATION-DETAILS');
  }

  constructor(
    private organisationUnitService: OrganisationUnitService,
    private personService: PersonService,
    private externalContactService: ExternalContactService,
    private trainingGroupService: TrainingGroupService,
    private route: ActivatedRoute,
    private toastrService: ToastrService,
    private router: Router,
    private loadingService: LoadingService,
    private fileUtilService: FileUtilService,
    private trainingTemplateService: TrainingTemplateService,
    readonly trainingHelperService: TrainingHelperService,
    private mapsService: GoogleMapsService,
    private translateService: TranslateService,
    private integrationsService: IntegrationsService
  ) {
    this.autocompleteSearchFilter = this.autocompleteSearchFilter.bind(this);
    this.editingTrainingTemplateId = this.route.snapshot.params['id'];

    if (history.state.duplicateId) {
      this.isDuplicate = true;
      this.duplicateTrainingTemplateId = history.state.duplicateId;
    }

    const path = this.route.snapshot.routeConfig!.path!;
    if (
      !path.includes('training-template/add') &&
      !path.includes('training-template/edit')
    ) {
      this.isViewMode = true;
    }

    const formData = this.loadingService
      .load(
        forkJoin({
          trainingGroups: this.trainingGroupService.getAllTrainingGroups(),
          organizationUnits:
            this.organisationUnitService.getAllOrganisationUnits(),
          trainers: this.personService.getAllPersons(),
          externalContacts: this.externalContactService.getAllContacts(),
          integrations: this.integrationsService.getIntegrations(),
        })
      )
      .pipe(shareReplay(1));

    formData.subscribe(({ trainingGroups, organizationUnits, trainers, externalContacts, integrations }) => {
      this.trainingGroups = trainingGroups;
      this.organizationUnits = organizationUnits;
      this.integrations = integrations;

      this.trainers = trainers.map((trainer) => ({
        id: trainer.id,
        name: `${trainer.firstName} ${trainer.lastName}`,
      }));

      externalContacts.forEach(contact => {
        this.trainers.push({
          id: contact.id,
          name: `${contact.firstName} ${contact.lastName}`,
        })
      });

      this.setDropdownOptions();

      if (this.duplicateTrainingTemplateId) {
        this.loadingService
          .load(
            this.trainingTemplateService.getTrainingTemplateClone(
              this.duplicateTrainingTemplateId
            )
          )
          .subscribe((trainingTemplate) => {
            this.patchForm(trainingTemplate);
          });
      }

      if (this.editingTrainingTemplateId) {
        this.loadingService
          .load(
            this.trainingTemplateService.getTrainingTemplate(
              this.editingTrainingTemplateId
            )
          )
          .subscribe((trainingTemplate) => {
            this.patchForm(trainingTemplate);
          });
      }
    });
  }

  ngOnInit(): void {
    this.googleAutocompleteResults$.subscribe((results) => {
      this.googlePlaces = results;
    });
  }

  navigateBack(trainingTemplateId?: string): void {
    if (this.isViewMode) {
      this.router.navigate(['/training']);
    } else {
      this.router.navigate([
        '/training-template/' +
          (trainingTemplateId || this.editingTrainingTemplateId),
      ]);
    }
  }

  onFormSubmit(): void {
    const isDescriptionFormValid =
      this.descriptionForm.instance.validate().isValid;
    const isSettingsFormValid = this.settingsForm.instance.validate().isValid;
    const isLocationFormValid = this.locationForm.instance.validate().isValid;
    if (isDescriptionFormValid && isSettingsFormValid && isLocationFormValid) {
      if (this.editingTrainingTemplateId) {
        const trainingTemplate = {
          ...this.trainingTemplateForm,
          imageBase64: this.trainingTemplateForm.imageBase64
            ? this.trainingTemplateForm.imageBase64!.split(',')[1]
            : null,
        };

        if (this.trainingTemplateForm.trainingType === 0) {
          trainingTemplate.iddTimeMinutes =
            this.trainingTemplateForm.iddHours! * 60 +
            this.trainingTemplateForm.iddMinutes!;
        } else {
          trainingTemplate.iddTimeMinutes = 0;
        }

        const result = this.trainingTemplateService.updateTrainingTemplate(
          this.editingTrainingTemplateId,
          trainingTemplate
        );

        this.loadingService.load(result).subscribe({
          next: () => {
            this.toastrService.success(
              this.translateService.instant(
                'TRAINING-TEMPLATE.FORM.TEMPLATE-UPDATED'
              )
            );
            this.navigateViewMode(this.editingTrainingTemplateId);
          },
          error: () =>
            this.toastrService.error(
              this.translateService.instant('COMMON.FIELDS.UNKNOWN-ERROR')
            ),
        });
      } else {
        const trainingTemplate: TrainingTemplateCreationAndUpdateModel = {
          ...this.trainingTemplateForm,
          imageBase64: this.trainingTemplateForm.imageBase64
            ? this.trainingTemplateForm.imageBase64!.split(',')[1]
            : null,
        };
        if (!trainingTemplate.imageBase64) {
          delete trainingTemplate.imageBase64;
        }
        if (this.trainingTemplateForm.trainingType === 0) {
          trainingTemplate.iddTimeMinutes =
            this.trainingTemplateForm.iddHours! * 60 +
            this.trainingTemplateForm.iddMinutes!;
        } else {
          trainingTemplate.iddTimeMinutes = 0;
        }
        const result =
          this.trainingTemplateService.addTrainingTemplate(trainingTemplate);
        this.loadingService.load(result).subscribe({
          next: (trainingTemplate) => {
            this.toastrService.success(
              this.translateService.instant(
                'TRAINING-TEMPLATE.FORM.TEMPLATE-CREATED'
              )
            );
            this.navigateViewMode(trainingTemplate.trainingTemplate.id);
          },
          error: () =>
            this.toastrService.error(
              this.translateService.instant('COMMON.FIELDS.UNKNOWN-ERROR')
            ),
        });
      }
    }
  }

  setDropdownOptions() {
    const defaultSelectOptions = {
      showClearButton: true,
      stylingMode: 'outlined',
      displayExpr: 'name',
      valueExpr: 'id'
    } as DxSelectBoxTypes.Properties;
    this.trainingTypeOptions = {
      ...defaultSelectOptions,
      dataSource: [
        {
          id: 0,
          name: this.translateService.instant(
            'COMMON.FIELDS.TRAINING-TYPES.IDD'
          ),
        },
        {
          id: 1,
          name: this.translateService.instant(
            'COMMON.FIELDS.TRAINING-TYPES.COMPLIANCE'
          ),
        },
        {
          id: 2,
          name: this.translateService.instant(
            'COMMON.FIELDS.TRAINING-TYPES.GENERAL'
          ),
        },
      ],
      placeholder: '',
    };

    const trainingLocationTypeOptionsDataSource = [
      {
        id: TrainingLocationTypeModel.Address,
        name: this.translateService.instant(
          'COMMON.FIELDS.EVENT-TYPES.FACE-TO-FACE'
        ),
      },
      {
        id: TrainingLocationTypeModel.Web,
        name: this.translateService.instant('COMMON.FIELDS.EVENT-TYPES.ONLINE'),
      },
    ];

    for (let integration of this.integrations) {
      if (integration.type == IntegrationTypeModel.GoToWebinar) {
        trainingLocationTypeOptionsDataSource.push({
          id: TrainingLocationTypeModel.GoToWebinar,
          name: this.translateService.instant(
            'COMMON.FIELDS.EVENT-TYPES.GO-TO-WEBINAR'
          ),
        });
      }
      if (integration.type == IntegrationTypeModel.Webex) {
        trainingLocationTypeOptionsDataSource.push({
          id: TrainingLocationTypeModel.Webex,
          name: this.translateService.instant(
            'COMMON.FIELDS.EVENT-TYPES.WEBEX-WEBINAR'
          ),
        });
      }
    }

    this.trainingLocationTypeOptions = {
      ...defaultSelectOptions,
      onItemClick: this.onTrainingLocationTypeSelected.bind(this),
      dataSource: trainingLocationTypeOptionsDataSource,
      placeholder: '',
    };
  }

  setTreeViewValues() {
    this.trainingGroups.forEach((trainingGroup) => {
      if (
        this.trainingTemplateForm.trainingGroupIds.indexOf(trainingGroup.id) >
        -1
      ) {
        trainingGroup.selected = true;
      }
    });

    this.organizationUnits.forEach((organizationUnit) => {
      if (
        this.trainingTemplateForm.organisationUnitLocationIds.indexOf(
          organizationUnit.id
        ) > -1
      ) {
        organizationUnit.selected = true;
      }
    });

    this.trainers.forEach((trainer) => {
      if (this.trainingTemplateForm.trainerIds.indexOf(trainer.id) > -1) {
        trainer.selected = true;
      }
    });
  }

  onTrainingLocationTypeSelected() {
    this.trainingTemplateForm.trainingLocation!.location = '';
    this.trainingTemplateForm.trainingLocation!.suffix = '';
    this.trainingTemplateForm.trainingLocation!.placeId = '';
    this.trainingTemplateForm.trainingLocation!.latitude = null;
    this.trainingTemplateForm.trainingLocation!.longitude = null;
    this.googleSearchText = '';
  }

  onTrainingGroupSelected(e: any) {
    if (e.node.selected) {
      this.trainingTemplateForm.trainingGroupIds = [
        ...this.trainingTemplateForm.trainingGroupIds,
        e.node.key,
      ];
    } else {
      this.trainingTemplateForm.trainingGroupIds =
        this.trainingTemplateForm.trainingGroupIds.filter(
          (id) => id !== e.node.key
        );
    }
  }

  onLocationSelected(e: any) {
    this.trainingTemplateForm.organisationUnitLocationIds = [];

    this.organizationUnits.forEach(unit => {
      if (unit.selected) {
        this.trainingTemplateForm.organisationUnitLocationIds.push(unit.id);
      }
    });
    // if (e.node.selected) {
    //   this.trainingTemplateForm.organisationUnitLocationIds = [
    //     ...this.trainingTemplateForm.organisationUnitLocationIds,
    //     e.node.key,
    //   ];
    // } else {
    //   this.trainingTemplateForm.organisationUnitLocationIds =
    //     this.trainingTemplateForm.organisationUnitLocationIds.filter(
    //       (id) => id !== e.node.key
    //     );
    // }
  }

  onTrainerSelected(e: any) {
    if (e.node.selected) {
      this.trainingTemplateForm.trainerIds = [
        ...this.trainingTemplateForm.trainerIds,
        e.node.key,
      ];
    } else {
      this.trainingTemplateForm.trainerIds =
        this.trainingTemplateForm.trainerIds.filter((id) => id !== e.node.key);
    }
  }

  onAvatarUploaded(files: any) {
    const file = files[0];
    const reader = new FileReader();
    reader.onload = () => {
      this.trainingTemplateForm!.imageBase64 = reader!.result!.toString();
    };
    reader.readAsDataURL(file);
  }

  handleTimePicker(value: { hours: number; minutes: number }) {
    this.trainingTemplateForm.iddHours = value.hours;
    this.trainingTemplateForm.iddMinutes = value.minutes;
  }

  patchForm(trainingTemplate: TrainingTemplateFullModel) {
    this.trainingTemplateForm.organisationUnitLocationIds =
      trainingTemplate.organisationUnits.map(
        (organizationUnit) => organizationUnit.id
      );
    this.trainingTemplateForm.name = trainingTemplate.trainingTemplate.name;
    this.trainingTemplateForm.trainingType =
      trainingTemplate.trainingTemplate.trainingType;
    this.trainingTemplateForm.description =
      trainingTemplate.trainingTemplate.description!;
    this.trainingTemplateForm.descriptionExtended =
      trainingTemplate.trainingTemplate.descriptionExtended!;
    this.trainingTemplateForm.descriptionCertificate =
      trainingTemplate.trainingTemplate.descriptionCertificate!;
    this.trainingTemplateForm.trainerIds = trainingTemplate.trainers.map(
      (trainer) => trainer.id
    );
    this.trainingTemplateForm.trainingGroupIds =
      trainingTemplate.trainingGroups.map((trainingGroup) => trainingGroup.id);
    this.trainingTemplateForm.sendCertificateLink =
      trainingTemplate.trainingTemplate.sendCertificateLink;
    this.trainingTemplateForm.sendFeedbackLink =
      trainingTemplate.trainingTemplate.sendFeedbackLink;
    this.trainingTemplateForm.sendFeedbackLink =
      trainingTemplate.trainingTemplate.sendFeedbackLink;
    this.trainingTemplateForm.maximumMembers =
      trainingTemplate.trainingTemplate.maximumMembers;
    this.trainingTemplateForm.emailBody =
      trainingTemplate.trainingTemplate.emailBody;
    this.trainingTemplateForm.iddTimeMinutes =
      trainingTemplate.trainingTemplate.iddTimeMinutes;

    if (trainingTemplate.trainingTemplate.trainingLocation) {
      this.googleSearchText =
        trainingTemplate.trainingTemplate.trainingLocation!.location;
      this.trainingTemplateForm.trainingLocation!.locationType =
        trainingTemplate.trainingTemplate.trainingLocation!.type;
      this.trainingTemplateForm.trainingLocation!.location =
        trainingTemplate.trainingTemplate.trainingLocation!.location;
      this.trainingTemplateForm.trainingLocation!.suffix =
        trainingTemplate.trainingTemplate.trainingLocation!.suffix!;
      this.trainingTemplateForm.trainingLocation!.placeId =
        trainingTemplate.trainingTemplate.trainingLocation!.placeId!;
      this.trainingTemplateForm.trainingLocation!.latitude =
        trainingTemplate.trainingTemplate.trainingLocation!.latitude!;
      this.trainingTemplateForm.trainingLocation!.longitude =
        trainingTemplate.trainingTemplate.trainingLocation!.longitude!;
    }

    this.trainingTemplateForm.iddHours =
      TimeService.getHoursFromAbsoluteMinutes(
        this.trainingTemplateForm.iddTimeMinutes || 0
      );
    this.trainingTemplateForm.iddMinutes =
      TimeService.getMinutesFromAbsoluteMinutes(
        this.trainingTemplateForm.iddTimeMinutes || 0
      );
    if (trainingTemplate.trainingTemplate.imageUrl) {
      this.loadingService
        .load(
          this.fileUtilService.avatarUrlToBase64(
            trainingTemplate.trainingTemplate.imageUrl!
          )
        )
        .subscribe((base64) => {
          const mimeType = this.getImageMimeType(
            trainingTemplate.trainingTemplate.imageUrl!
          );
          this.trainingTemplateForm.imageBase64 = `data:${mimeType};base64,${base64}`;
        });
    }
    this.setTreeViewValues();
  }

  getImageMimeType(url: string) {
    const extension = url.split('.').pop()?.split(/\#|\?/)[0].toLowerCase();

    switch (extension) {
      case 'png':
        return 'image/png';
      case 'jpg':
      case 'jpeg':
        return 'image/jpeg';
      case 'gif':
        return 'image/gif';
      default:
        return 'image/jpeg';
    }
  }

  refreshComponent() {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([
        '/training-template/edit/' + this.editingTrainingTemplateId,
      ]);
    });
  }

  navigateViewMode(id: string): void {
    this.router.navigate(['/training-template/' + id]);
  }

  getGooglePlaces(e: any) {
    this.googlePlaceinputChanged.next(e.value);
  }

  setLocationPlace(e: any) {
    this.loadingService
      .load(this.mapsService.getDetails(e.itemData.place_id))
      .subscribe((details) => {
        const location = details.formatted_address.startsWith(details.name)
          ? details.formatted_address
          : details.name + ', ' + details.formatted_address;
        this.trainingTemplateForm.trainingLocation!.location = location;
        this.trainingTemplateForm.trainingLocation!.placeId = details.place_id;
        this.trainingTemplateForm.trainingLocation!.latitude =
          details.geometry.location.lat;
        this.trainingTemplateForm.trainingLocation!.longitude =
          details.geometry.location.lng;
        this.trainingTemplateForm.trainingLocation = {
          ...this.trainingTemplateForm.trainingLocation,
        } as TrainingLocationCreationAndUpdateModel;
      });
  }

  autocompleteSearchFilter(e: any) {
    return this.googleSearchText;
  }

  onEditClick() {
    this.router.navigate([
      'training-template',
      'edit',
      this.editingTrainingTemplateId,
    ]);
  }

  duplicateTemplate() {
    this.router.navigate(['/training-template/add'], {
      state: { duplicateId: this.editingTrainingTemplateId },
    });
  }

  toggleDescriptionExpanded() {
    this.isDescriptionExpanded = !this.isDescriptionExpanded;
  }

  toggleCertificateDescriptionExpanded() {
    this.isCertificateDescriptionExpanded =
      !this.isCertificateDescriptionExpanded;
  }

  onDescriptionTabChange(e: DxTabsTypes.ItemClickEvent) {
    this.selectedDescriptionTab = e.itemData.id;
  }
}
