<div>
  <app-page>
    <div class="top-cta">
      <dx-button
        [text]="'COMMON.BACK' | translate"
        icon="back"
        type="default"
        class="integration-btn"
        (click)="navigateBack()"
      >
      </dx-button>
      <button
        *ngIf="pagesData.length"
        [useExistingCss]="true"
        printSectionId="print-section"
        ngxPrint
        class="fake-btn"
      >
        <dx-button [text]="'COMMON.PRINT' | translate" class="integration-btn">
        </dx-button>
      </button>
    </div>
    <div id="print-section">
      <app-page-title
        [title]="
          ('TRAINING.FEEDBACK.SURVEY-RESULT' | translate) +
          ' ' +
          (feedback?.name || '')
        "
      >
      </app-page-title>
      <div *ngIf="feedback" class="row">
        <div class="col">
          {{ "COMMON.DATE" | translate }}: <br /><span
            [innerHTML]="feedback.date"
          ></span>
        </div>
        <div class="col">
          {{ "TRAINING.FEEDBACK.SEMINAR-LEADER" | translate }}:<br />
          <span [innerHTML]="feedback.trainer"></span>
        </div>
      </div>

      <div *ngIf="!pagesData.length" class="no-data-message">
        {{ "TRAINING.FEEDBACK.NO-FEEDBACK" | translate }}
      </div>

      <app-panel
        [title]="'COMMON.OVERVIEW' | translate"
        *ngIf="pagesData.length"
      >
        <div class="row">
          <div class="col-8">
            <div class="row">
              <div class="col-6">
                <p>{{ "TRAINING.FEEDBACK.NUMBER-OF-ANSWERS" | translate }}:</p>
                <div>
                  <b>{{ feedback?.participants }}</b>
                </div>
              </div>
              <ng-container *ngFor="let page of pagesData">
                <div class="col-6 average-score-info" *ngIf="page.averageScore">
                  <p>{{ page.title }}:</p>
                  <div>
                    <b>{{ page.averageScore | number : "1.2-2" }}</b>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </app-panel>
      <app-panel
        [title]="page.title"
        *ngFor="let page of pagesData; let idx = index"
      >
        <!-- <div class="page-explanation" *ngIf="idx == 0"> -->
        <div class="page-explanation" *ngIf="idx !== pagesData.length - 1">
          {{ "FEEDBACK.EXPLANATION" | translate }}
        </div>
        <ng-container *ngFor="let question of page.questions">
          <ng-container *ngIf="question.chartData">
            <ng-container
              [ngTemplateOutlet]="chartInfoBoxTemplate"
              [ngTemplateOutletContext]="{ question: question }"
            ></ng-container>
          </ng-container>
          <ng-container *ngIf="!question.chartData">
            <div class="container-fluid">
              <div class="row chart-info-box">
                <div class="col-6">
                  <p>{{ question.question }}</p>
                  <ul class="question-options">
                    <li *ngFor="let option of question.answers">
                      {{ option }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </app-panel>
    </div>
  </app-page>
</div>

<ng-template #chartInfoBoxTemplate let-question="question">
  <div class="chart-info-box">
    <div>
      <p>{{ question.question }}</p>
      <p>
        <b>{{ question.averageScore | number : "1.2-2" }}</b> Average score
      </p>
    </div>
    <div>
      <dx-chart
        id="chart"
        [dataSource]="question.chartData"
        [size]="{ height: 120, width: 300 }"
        [commonAxisSettings]="commonAxisSettings"
      >
        <dxi-series
          argumentField="id"
          valueField="value"
          type="bar"
          color="#585e98"
          [showInLegend]="false"
          barWidth="30"
          cornerRadius="3"
        >
          <dxo-label
            [visible]="true"
            backgroundColor="transparent"
            [position]="'inside'"
            [font]="{ size: 10 }"
            [customizeText]="customizeText"
          ></dxo-label>
        </dxi-series>
        <dxi-value-axis
          [visualRange]="[0, question.maxChartData]"
        ></dxi-value-axis>
        <dxo-argument-axis type="continuous" argumentType="string">
        </dxo-argument-axis>
      </dx-chart>
    </div>
  </div>
</ng-template>
