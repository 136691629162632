<div class="feedback" *ngIf="feedback">
  <div *ngIf="!isFeebackStarted && !isFeebackFinihsed" class="feedback-intro">
    <div class="feedback-intro-wrapper">
      <img class="feedback-logo" [src]="feedback.logoUrl" />
      <div class="feedback-description">
        {{ "FEEDBACK.INTRO" | translate }}
      </div>
      <div class="feedback-stats">
        <div class="feedback-stat">
          <div class="feedback-stat-name">
            {{ ("COMMON.FIELDS.DATE" | translate) + ":" }}
          </div>
          <div class="feedback-stat-value">{{ feedback.eventDate }}</div>
        </div>
        <div class="feedback-stat">
          <div class="feedback-stat-name">
            {{ ("COMMON.FIELDS.EVENT" | translate) + ":" }}
          </div>
          <div class="feedback-stat-value">{{ feedback.eventTitle }}</div>
        </div>
        <div *ngIf="feedback.eventTrainer" class="feedback-stat">
          <div class="feedback-stat-name">
            {{ ("COMMON.FIELDS.TRAINER" | translate) + ":" }}
          </div>
          <div class="feedback-stat-value">{{ feedback.eventTrainer }}</div>
        </div>
      </div>
      <dx-button
        [text]="'FEEDBACK.GIVE-FEEDBACK' | translate"
        type="normal"
        (click)="onStartFeedback()"
      ></dx-button>
    </div>
  </div>
  <div *ngIf="isFeebackStarted && !isFeebackFinihsed">
    <div class="feedback-progress">
      <div
        class="feedback-progress-value"
        [ngStyle]="{ 'width.%': progressPersentage }"
      >
        {{ progressPersentage }}%
      </div>
    </div>
    <div class="feedback-questions">
      <div
        [hidden]="idx !== currentPage"
        *ngFor="let page of feedback.survey.pages; let idx = index"
      >
        <div class="feedback-questions-wrapper">
          <div class="feedback-questions-page">
            {{ feedback.survey.pages[currentPage].title }}
          </div>
          <div class="feedback-questions-question-explanation" *ngIf="currentPage !== feedback.survey.pages.length - 1">
            {{ "FEEDBACK.EXPLANATION" | translate }}
          </div>
          <div *ngFor="let question of page.questions">
            <div
              [hidden]="question.type === SurveyQuestionType.GroupLabel"
              class="feedback-questions-question"
            >
              <div class="feedback-questions-question-text">
                {{ question.text }}
              </div>
              <div
                *ngIf="question.type == SurveyQuestionType.RadioSelectNumeric"
              >
                <dx-radio-group
                  class="feedback-questions-question-radio"
                  layout="horizontal"
                  [items]="[1, 2, 3, 4, 5, 6]"
                  (onValueChanged)="
                    onRadioQuestionAnswered($event, question.id)
                  "
                >
                </dx-radio-group>
              </div>
              <div *ngIf="question.type == SurveyQuestionType.FreeTextInput">
                <dx-text-area
                  class="feedback-questions-question-answer"
                  valueChangeEvent="input"
                  (onValueChanged)="onTextQuestionAnswered($event, question.id)"
                ></dx-text-area>
              </div>
            </div>
            <div
              [hidden]="question.type !== SurveyQuestionType.GroupLabel"
              class="feedback-questions-question-separator"
            >
              {{ question.text }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="feedback-questions-cta">
      <dx-button
        *ngIf="currentPage !== 0"
        class="feedback-questions-back"
        [text]="'COMMON.BACK' | translate"
        type="default"
        (click)="previousPage()"
      ></dx-button>
      <dx-button
        *ngIf="currentPage !== feedback.survey.pages.length - 1"
        [text]="'COMMON.NEXT' | translate"
        type="normal"
        (click)="nextPage()"
      ></dx-button>
      <dx-button
        *ngIf="currentPage === feedback.survey.pages.length - 1"
        [text]="'COMMON.SUBMIT' | translate"
        type="normal"
        (click)="submitFeedback()"
      ></dx-button>
    </div>
  </div>
  <div *ngIf="isFeebackStarted && isFeebackFinihsed" class="feedback-finish">
    <div class="feedback-finish-wrapper">
      <div class="feedback-finish-title">
        {{ "FEEDBACK.SURVEY-COMPLETED" | translate }}
      </div>
      <div class="feedback-finish-description">
        {{ "FEEDBACK.THANK-YOU" | translate }}
      </div>
      <!-- <dx-button
        [text]="'To main'"
        type="normal"
        (click)="onCloseFeedback()"
      ></dx-button> -->
    </div>
  </div>
</div>
