<app-page>
  <app-page-title title="{{ 'TRAINING-CATALOGUE.OVERVIEW.TITLE' | translate }}">
  </app-page-title>

  <div class="training-overview-container">
    <div class="training-overview-filters">
      <dx-text-box
        class="search-input"
        icon="search"
        width="100%"
        labelMode="floating"
        stylingMode="outlined"
        [label]="'COMMON.SEARCH' | translate"
        [value]="searchString"
        valueChangeEvent="input"
        (onValueChanged)="onSearchInput($event)"
      >
        <dxi-button
          name="search"
          type="danger"
          location="before"
          [options]="{ icon: 'search', type: 'default', disabled: true }"
        ></dxi-button>
      </dx-text-box>
    </div>
    <div class="cards-container">
      <div *ngFor="let category of trainingCategories" class="card-wrapper">
        <app-training-catalogue-card [category]="category" />
      </div>
    </div>
  </div>
</app-page>
