<div class="course-questions">
  <app-page>
    <div *ngIf="!isQuestionsAnswered">
      <div *ngIf="course$ | async as course">
        <app-page-title
          [title]="
            ('ELEARNING-QUESTIONS.QUESTIONS.QUESTIONS-ABOUT' | translate) + ' &quot;' + course.courseTemplate.name + '&quot;'
          "
        ></app-page-title>
        <div class="questions-details">
          {{'ELEARNING-QUESTIONS.QUESTIONS.QUESTIONS-GUIDE' | translate}}
        </div>
        <div *ngFor="let question of course.questions; let idx = index">
          <app-course-question
            [hidden]="idx !== currentQuestion"
            [question]="question"
          >
          </app-course-question>
        </div>
        <div class="cta-container">
          <dx-button
            *ngIf="currentQuestion !== course.questions.length - 1"
            [text]="'ELEARNING-QUESTIONS.QUESTIONS.NEXT' | translate"
            type="normal"
            (click)="nextQuestion()"
          ></dx-button>
          <dx-button
            *ngIf="currentQuestion === course.questions.length - 1"
            [text]="'ELEARNING-QUESTIONS.QUESTIONS.SUBMIT' | translate"
            type="normal"
            (click)="onSubmitClick()"
          ></dx-button>
        </div>
      </div>
    </div>
    <div *ngIf="isQuestionsAnswered" class="questions-result">
      @switch (isQuestionsAnsweredCorrectly) {
        @case (true) {
          <div class="questions-result-title">
            {{'ELEARNING-QUESTIONS.QUESTIONS.CONGRATULATIONS' | translate}}
          </div>
          <div class="questions-result-description">
            {{'ELEARNING-QUESTIONS.QUESTIONS.CONGRATULATIONS-INFO' | translate}}
          </div>
          <div class="questions-result-cta">
            <dx-button
              [text]="'ELEARNING-QUESTIONS.QUESTIONS.DONE' | translate"
              type="normal"
              (click)="navigateToCourse()"
            ></dx-button>
          </div>
        } @case (false) {
          <div class="questions-result-title">
            {{'ELEARNING-QUESTIONS.QUESTIONS.TRY-AGAIN' | translate}}
          </div>
          <div class="questions-result-description">
            {{'ELEARNING-QUESTIONS.QUESTIONS.TRY-AGAIN-INFO' | translate}}
          </div>
          <div class="questions-result-cta" *ngIf="!hideReloadQuestionsButton">
            <dx-button
              [text]="'ELEARNING-QUESTIONS.QUESTIONS.RETURN-QUESTIONS' | translate"
              type="normal"
              (click)="reloadQuestions()"
            ></dx-button>
          </div>
          <div class="questions-result-cta">
            <dx-button
            [text]="'ELEARNING-QUESTIONS.QUESTIONS.RETURN-CONTENT' | translate"
              type="normal"
              (click)="navigateToCourse()"
            ></dx-button>
          </div>
        }
      }
    </div>
  </app-page>
</div>
