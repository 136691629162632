import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import { DxFormComponent } from 'devextreme-angular';
import { ToastrService } from 'ngx-toastr';
import { AuthorizationService } from 'src/app/services/authorization.service';

@Component({
  selector: 'app-change-password-modal',
  templateUrl: './change-password-modal.component.html',
  styleUrl: './change-password-modal.component.scss'
})
export class ChangePasswordModalComponent {
  @Input() showModal = false;
  @Output() showModalChange = new EventEmitter<boolean>();
  formData = {
    password: '',
    repeatPassword: ''
  };

  @ViewChild('form') form!: DxFormComponent;

  constructor(
    private toastrService: ToastrService,
    private authorizationService: AuthorizationService,
    private router: Router
  ) {}

  submit = () => {
    const isValid = this.form.instance.validate().isValid;
    if (!isValid) {
      this.toastrService.error('Input new password');
      return;
    }

    this.authorizationService
      .changePassword({
        password: this.formData.password
      })
      .subscribe(() => {
        this.formData = {
          password: '',
          repeatPassword: ''
        };
        this.closeModal();
      });
  };

  closeModal() {
    this.showModal = false;
    this.showModalChange.emit(this.showModal);
  }

  validation = (params: any) => {
    return params.value === this.formData.password;
  };
}
