import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-training-cancel-dialog',
  templateUrl: './training-cancel-dialog.component.html',
  styleUrl: './training-cancel-dialog.component.scss',
})
export class TrainingCancelDialogComponent {
  @Input() showModal = false;
  @Output() showModalChange = new EventEmitter<boolean>();
  @Output() submit = new EventEmitter();

  formData = {
    infoInput: '',
    infoCheckbox: '',
  };

  dismiss = () => {
    this.showModal = false;
    this.showModalChange.emit(this.showModal);
  };

  onSubmit = () => {
    this.submit.emit({
      cancelInformationText: this.formData.infoCheckbox
        ? this.formData.infoInput
        : null,
    });
  };
}

export interface TrainingCancelDialogResult {
  cancelEvent: boolean;
  cancelInformationText: string | null;
}
