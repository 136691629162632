import { Component, Output, EventEmitter, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrl: './time-picker.component.scss'
})
export class TimePickerComponent implements OnInit {
  @Input() isInvalid = true;
  @Input() disabled = false;
  @Input() defaultValue!: {
    hours: number;
    minutes: number;
  };
  formData = {
    hours: 0,
    minutes: 0
  };
  viewTime = '0 h';
  get validationStatus() {
    return this.isInvalid ? 'invalid' : 'valid';
  }


  @Output() timeChanged = new EventEmitter();

  ngOnInit() {
    if (!this.defaultValue) return;
    this.formData.hours = this.defaultValue.hours;
    this.formData.minutes = this.defaultValue.minutes;
    this.setTimeView();
  }

  setTimeView() {
    const h = this.formData.hours;
    const m = this.formData.minutes;

    if (h === 0 && m === 0) {
      this.viewTime = '0 h';
    } else {
      this.viewTime = `${String(h).padStart(2, '0')}:${String(m).padStart(
        2,
        '0'
      )} h`;
    }
    this.timeChanged.emit(this.formData);
  }
}
