import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {CompletedEventSummaryModel} from "../models/completed-event/completed-event-summary.model";
import {Configuration} from "../../assets/Configuration";
import {DownloadService} from "./download.service";

@Injectable({
  providedIn: 'root'
})
export class CompletedEventService {

  constructor(private httpClient: HttpClient, private downloadService: DownloadService) {
  }

  public getAllCompletedEvents(personId: string): Observable<CompletedEventSummaryModel[]> {
    return this.httpClient.get<CompletedEventSummaryModel[]>(Configuration.getApiBaseUrl() + "/completedevent?personId=" + personId);
  }

  public downloadCompletedEventMemberListByTrainingId(trainingId: string): void {
    this.downloadService.download(Configuration.getApiBaseUrl() + "/completedevent/member-list/by-training-id?trainingId=" + trainingId);
  }
}
