import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {PersonSearchResultModel} from "../models/person/person-search-result.model";
import {TrainingInvitationSearchModel} from "../models/training/training-invitation-search.model";
import {TrainingMessageSendModel} from "../models/training/training-message-send.model";
import {Configuration} from "../../assets/Configuration";

@Injectable({
    providedIn: 'root'
})
export class CourseTemplateMessageService {

    constructor(private httpClient: HttpClient) {
    }

    public searchPersonsForInvitation(searchModel: TrainingInvitationSearchModel): Observable<PersonSearchResultModel> {
        return this.httpClient.post<PersonSearchResultModel>(Configuration.getApiBaseUrl() + "/coursetemplatemessage/search-persons", searchModel);
    }

    sendInvitation(trainingId: string, model: TrainingMessageSendModel): Observable<any> {
        return this.httpClient.post<void>(Configuration.getApiBaseUrl() + "/coursetemplatemessage/invitation?courseTemplateId=" + trainingId, model);
    }

    sendInfo(trainingId: string, model: TrainingMessageSendModel): Observable<any> {
        return this.httpClient.post<PersonSearchResultModel>(Configuration.getApiBaseUrl() + "/coursetemplatemessage/info?courseTemplateId=" + trainingId, model);
    }
}

