import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {Configuration} from "../../assets/Configuration";
import {TrainingAnonymousModel} from "../models/training/training-anonymous.model";
import {HttpBackend, HttpClient} from "@angular/common/http";
import {AcceptTrainingAnonymousModel} from "../models/training/accept-training-anonymous.model";
import { RawFileModel } from '../models/raw-file-model';

@Injectable({
    providedIn: 'root'
})
export class TrainingAnonymousService {

    private httpClient: HttpClient;

    constructor(private httpBackend: HttpBackend) {
        this.httpClient = new HttpClient(httpBackend);
    }

    public getTrainingAnonymous(eventKey: string, accessToken: string | null): Observable<TrainingAnonymousModel> {
        return this.httpClient.get<TrainingAnonymousModel>(
            Configuration.getApiBaseUrl() + '/traininganonymous/' + eventKey + (accessToken ? `?token=${accessToken}` : ''));
    }

    public acceptTrainingAnonymous(eventKey: string, model: AcceptTrainingAnonymousModel): Observable<string | null> {
        return this.httpClient.post<string | null>(Configuration.getApiBaseUrl() + '/traininganonymous/' + eventKey + '/accept', model, { responseType: 'text' as 'json' });
    }

    public getTrainingAnonymousIcs(eventKey: string): Observable<RawFileModel> {
        return this.httpClient.get<RawFileModel>(Configuration.getApiBaseUrl() + '/traininganonymous/' + eventKey + '/ics');
    }

    public requestTrainingAnonymous(eventKey: string, model: AcceptTrainingAnonymousModel): Observable<void> {
        return this.httpClient.post<void>(Configuration.getApiBaseUrl() + '/traininganonymous/' + eventKey + '/request', model);
    }
}
