import { DatePipe } from '@angular/common';
import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'trainingDate'
})
export class TrainingDatePipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}

  transform(dateRange: { start: string; end: string }, language: string = 'en'): string | null {
    if (!dateRange) return '';

    const startDate = new Date(dateRange.start);
    const endDate = new Date(dateRange.end);

    if (
      startDate.getFullYear() === endDate.getFullYear() &&
      startDate.getMonth() === endDate.getMonth() &&
      startDate.getDate() === endDate.getDate()
    ) {
      return this.formatDate(startDate, language);
    } else {
      return (
        this.formatDate(startDate, language) +
        ' - ' +
        this.formatDate(endDate, language)
      );
    }
  }

  private formatDate(date: Date, language: string): string | null {
    return this.datePipe.transform(date,  'EEEE dd.LL.yyyy', '', language);
  }
}
