import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DxFormComponent } from 'devextreme-angular';
import { ToastrService } from 'ngx-toastr';
import { filter, map, of, switchMap, tap } from 'rxjs';
import { TrainingCategoryCreationAndUpdateModel } from 'src/app/models/training-category-creation-and-update.model';
import { TrainingCategoryModel } from 'src/app/models/training-category.model';
import { LoadingService } from 'src/app/services/loading.service';
import { TrainingCategoryService } from 'src/app/services/training-category.service';
import { FileUtilService } from 'src/app/services/file-util.service';
import { ConfirmationDialogData } from 'src/app/components/shared/confirmation-dialog/confirmation-dialog.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-training-categories-details',
  templateUrl: './training-categories-details.component.html',
  styleUrl: './training-categories-details.component.scss',
})
export class TrainingCategoriesDetailsComponent implements OnInit {
  formData = {
    name: '',
    description: '',
    descriptionExtended: '',
    image: '',
  };
  currentTrainingCategory: TrainingCategoryModel | null = null;
  editingMode = false;
  selectedDocument = {
    name: '',
    dataBase64: '',
  };

  fileToRemoveId: string | null = null;
  showDeleteFileModal: boolean = false;
  modalConfirmationOptions: ConfirmationDialogData | null = null;

  @ViewChild('form', { static: true }) form!: DxFormComponent;

  constructor(
    private activatedRoute: ActivatedRoute,
    private trainingCategoryService: TrainingCategoryService,
    private router: Router,
    private loadingService: LoadingService,
    private toastrService: ToastrService,
    private fileUtilService: FileUtilService,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params
      .pipe(
        map((params) => params['id']),
        filter((id) => !!id),
        tap((id) => {
          this.editingMode = id !== 'new';
        }),
        switchMap((id) =>
          this.loadingService.load(
            this.editingMode
              ? this.trainingCategoryService.getTrainingCategory(id)
              : of(null)
          )
        )
      )
      .subscribe((result) => {
        this.currentTrainingCategory = result;
        if (!result) return;

        if (this.currentTrainingCategory?.trainingCategory.imageUrl) {
          this.loadingService
            .load(
              this.fileUtilService.avatarUrlToBase64(
                this.currentTrainingCategory?.trainingCategory.imageUrl
              )
            )
            .subscribe((base64) => {
              const mimeType = this.getImageMimeType(
                this.currentTrainingCategory!.trainingCategory!.imageUrl!
              );
              this.formData.image = `data:${mimeType};base64,${base64}`;
            });
        }

        this.formData.name = result.trainingCategory.name;
        this.formData.description = result.trainingCategory.description || '';
        this.formData.descriptionExtended =
          result.trainingCategory.descriptionExtended || '';
      });
  }

  navigateBack(): void {
    this.router.navigate(['administration'], {
      queryParams: { tab: 'categories' },
    });
  }

  submit(): void {
    const formValid = this.form.instance.validate().isValid;
    if (!formValid) {
      return;
    }

    const model: TrainingCategoryCreationAndUpdateModel = {
      name: this.formData.name,
      description: this.formData.description,
      descriptionExtended: this.formData.descriptionExtended,
      image: this.formData.image ? this.formData.image!.split(',')[1] : '',
    };

    if (this.editingMode && this.currentTrainingCategory) {
      const obs = this.trainingCategoryService.updateTrainingCategory(
        this.currentTrainingCategory.trainingCategory.id,
        model
      );
      this.loadingService.load(obs).subscribe(() => {
        this.toastrService.success('Training category saved');
      });
    } else {
      const obs = this.trainingCategoryService.addTrainingCategory(model);
      this.loadingService.load(obs).subscribe((createdCategory) => {
        this.toastrService.success('Training category added');

        this.router.navigate([
          'administration',
          'training-categories',
          createdCategory.trainingCategory.id,
        ]);
      });
    }
  }

  getImageMimeType(url: string) {
    const extension = url.split('.').pop()?.split(/\#|\?/)[0].toLowerCase();

    switch (extension) {
      case 'png':
        return 'image/png';
      case 'jpg':
      case 'jpeg':
        return 'image/jpeg';
      case 'gif':
        return 'image/gif';
      default:
        return 'image/jpeg';
    }
  }

  onCategoryImageSelected(files: any) {
    const file = files[0];
    const reader = new FileReader();
    reader.onload = () => {
      this.formData!.image = reader!.result!.toString();
    };
    reader.readAsDataURL(file);
  }

  onDocumentSelected(files: any) {
    const file = files[0];
    const reader = new FileReader();
    reader.onload = () => {
      this.selectedDocument.name = file.name;
      this.selectedDocument.dataBase64 = reader!
        .result!.toString()
        .split(',')[1];

      this.loadingService
        .load(
          this.trainingCategoryService.addTrainingCategoryFile(
            this.currentTrainingCategory!.trainingCategory!.id,
            this.selectedDocument
          )
        )
        .subscribe((file) => {
          this.selectedDocument.name = '';
          this.selectedDocument.dataBase64 = '';
          this.currentTrainingCategory?.files.push(file);
        });
    };
    reader.readAsDataURL(file);
  }

  onRemoveFileClicked(file: any) {
    this.showDeleteFileModal = true;
    this.fileToRemoveId = file.fileId;

    this.modalConfirmationOptions = {
      hideUndo: true,
      submitButtonText: this.translateService.instant('COMMON.YES'),
      message: this.translateService.instant(
        'ADMINISTRATION.DELETE-CATEGORY-FILE.TITLE'
      ),
      dismissButtonText: this.translateService.instant('COMMON.NO'),
    };
  }

  onRemoveFileConfirm() {
    this.loadingService
      .load(
        this.trainingCategoryService.deleteTrainingCategoryFile(
          this.currentTrainingCategory!.trainingCategory!.id,
          this.fileToRemoveId!
        )
      )
      .subscribe(() => {
        this.showDeleteFileModal = false;
        this.currentTrainingCategory!.files =
          this.currentTrainingCategory!.files.filter(
            (f) => f.fileId !== this.fileToRemoveId!
          );
      });
  }
}
