import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Configuration} from "../../assets/Configuration";
import {TimeZoneModel} from "../models/time-zone.model";

@Injectable({
  providedIn: 'root'
})
export class TimeZoneService {

  constructor(private httpClient: HttpClient) {
  }

  public getAllTimeZones(): Observable<TimeZoneModel[]> {
    return this.httpClient.get<TimeZoneModel[]>(Configuration.getApiBaseUrl() + "/timezone/");
  }
}
