import { Component } from '@angular/core';
import { LoadingService } from '../../../services/loading.service';
import { TrainingCategoryService } from 'src/app/services/training-category.service';
import { TrainingCategoryModel } from 'src/app/models/training-category.model';

@Component({
  selector: 'app-training-catalogue-overview',
  templateUrl: './training-catalogue-overview.component.html',
  styleUrls: ['./training-catalogue-overview.component.scss'],
})
export class TrainingCatalogueOverviewComponent {
  
  searchString: string = '';
  allTrainingCategories: Array<TrainingCategoryModel> = [];
  trainingCategories: Array<TrainingCategoryModel> = [];

  constructor(
    private trainingCategoryService: TrainingCategoryService,
    private loaderService: LoadingService,
  ) {
    this.loadMyTrainings();
  }

  loadMyTrainings() {
    this.loaderService
      .load(this.trainingCategoryService.getAllTrainingCategories())
      .subscribe((trainingCategories) => {
        this.allTrainingCategories = trainingCategories;
        this.trainingCategories = trainingCategories;
      });
  }

  onSearchInput(event: any) {
    this.searchString = event.value;

    if (!!this.searchString) {
      this.trainingCategories = this.allTrainingCategories.filter((obj: any) => {
        return (
          obj.trainingCategory.name
            .toLowerCase()
            .includes(this.searchString.toLowerCase()) ||
          (obj.trainingCategory.description || '')
            .toLowerCase()
            .includes(this.searchString.toLowerCase())
        );
      });
    } else {
      this.trainingCategories = [...this.allTrainingCategories];
    }
  }
}
