export enum PrimaryButtonType {
    None,
    AddAttendees,
    CompleteTraining,
    ShowFeedback,
    DownloadCompletedEventMemberList
}

export enum SecondaryButtonType {
    None,
    CopyLink,
    DownloadMemberList,
    DownloadCompletedEventMemberList,
    DownloadIcs,
    SendInfo,
    Edit,
    Cancel,
    Delete
}