<dx-file-uploader
  name="file"
  accept="*"
  [uploadUrl]="uploadUrl"
  [chunkSize]="1000000"
  [multiple]="false"
  uploadMode="instantly"
  (onUploadStarted)="onUploadStarted($event)"
  (onProgress)="onUploadProgress($event)"
  (onBeforeSend)="onBeforeSend($event, accessToken)"
>
</dx-file-uploader>

<div class="chunk-panel"> </div>
