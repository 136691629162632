import {Pipe, PipeTransform} from '@angular/core';
import {TrainingTypeModel} from "../models/training/training-type.model";

@Pipe({
  name: 'trainingTypeDisplay'
})
export class TrainingTypeDisplayPipe implements PipeTransform {

  transform(value: TrainingTypeModel): string {
    switch (value) {
      case TrainingTypeModel.IDD:
        return 'IDD';
      case TrainingTypeModel.Compliance:
        return 'Compliance';
      case TrainingTypeModel.General:
        return 'Allgemein';
      default:
        return value;
    }
  }

}
