
export class Storage {
    static setItem(element: any, value: any) {
        let item = window.localStorage.getItem('scormData');
        if (!item) {
            window.localStorage.setItem('scormData', '{}');
        }
        item = window.localStorage.getItem('scormData');
        item = !item ? '{}' : item;

        const parsedItem = JSON.parse(item);
        parsedItem[element] = value;
        window.localStorage.setItem('scormData', JSON.stringify(parsedItem));
    }

    static getItem(element: any) {
        let item = window.localStorage.getItem('scormData');
        if (!item) {
            return null;
        }
        const parsedItem = JSON.parse(item);
        let _return = parsedItem[element] || null;
        return _return;
    }

    static getAll() {
        let item = window.localStorage.getItem('scormData');
        if (!item) {
            return "";
        }
        item = JSON.parse(item);
        return item || "";
    }

    static clearAll() {
        window.localStorage.removeItem('scormData');
    }
}