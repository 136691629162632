<dx-map
  [center]="center"
  [zoom]="zoom"
  [height]="297"
  width="100%"
  [apiKey]="apiKey"
  markerIconSrc="assets/img/map-pin.svg"
  [markers]="markers"
>
</dx-map>
