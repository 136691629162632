import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { PersonModel } from '../../../../models/person/person.model';

@Component({
  selector: 'app-invite-person-select-dialog',
  templateUrl: './invite-person-select-dialog.component.html',
  styleUrls: ['./invite-person-select-dialog.component.scss']
})
export class InvitePersonSelectDialogComponent implements OnInit {
  showModal = false;
  selectedPersons: PersonModel[] = [];

  @Output() onSubmit = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  selectionChange(selectedPersons: PersonModel[]) {
    this.selectedPersons = selectedPersons;
  }

  onSubmitClick = () => {
    this.onSubmit.emit(this.selectedPersons);
    this.toggleShowModal();
  };

  toggleShowModal() {
    this.showModal = !this.showModal;
  }

  toggle = () => {
    this.toggleShowModal();
  };
}
