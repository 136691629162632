import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserModel } from '../models/person/user.model';
import {Configuration} from "../../assets/Configuration";
import { ManageUserModel } from '../models/person/manage-user.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private httpClient: HttpClient) { }

  public getUserByPersonId(personId: string): Observable<UserModel> {
    return this.httpClient.get<UserModel>(Configuration.getApiBaseUrl() + "/user/by-person-id?personId=" + personId);
  }

  public addUserByPersonId(manageUserModel: ManageUserModel): Observable<UserModel> {
    return this.httpClient.post<UserModel>(Configuration.getApiBaseUrl() + "/user/by-person-id", manageUserModel);
  }

  public changeUserPasswordByPersonId(manageUserModel: ManageUserModel): Observable<UserModel> {
    return this.httpClient.post<UserModel>(Configuration.getApiBaseUrl() + "/user/change-password", manageUserModel);
  }

  public registerMissingAccounts(roleId: string): Observable<void> {
    return this.httpClient.post<void>(Configuration.getApiBaseUrl() + "/user/register-missing-users-by-role/" + roleId, {});
  }

  public getWbdConfigured(): Observable<boolean> {
    return this.httpClient.get<boolean>(Configuration.getApiBaseUrl() + "/Wbd/configured");
  }  
}
