import { Injectable } from '@angular/core';
import { RawFileModel } from '../models/raw-file-model';
import { HttpClient } from '@angular/common/http';
import { Buffer } from 'buffer';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FileUtilService {
  constructor(private httpClient: HttpClient) {}

  convertDataUrlToFile(dataURI: string, fileName: string): File {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);

    // create a view into the buffer
    var ia = new Uint8Array(ab);

    // set the bytes of the buffer to the correct values
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    var blob = new Blob([ab], { type: mimeString });
    return new File([blob], fileName);
  }

  public imageUrlToBlob(url: string): Observable<Blob> {
    return this.httpClient
      .get(url, {
        observe: 'response',
        responseType: 'arraybuffer',
      })
      .pipe(
        take(1),
        map((response) => {
          const arrayBuffer = response.body as ArrayBuffer;
          return new Blob([arrayBuffer], {
            type:
              response.headers.get('content-type') ||
              'application/octet-stream',
          });
        })
      );
  }

  convertDataUrlToBase64(dataURI: string): string {
    return dataURI.split(',')[1];
  }

  convertArrayBufferToBase64(arrayBuffer: ArrayBuffer): string {
    return Buffer.from(arrayBuffer).toString('base64');
  }

  convertBase64ToArrayBuffer(base64String: string): ArrayBuffer {
    return Buffer.from(base64String, 'base64');
  }

  convertFileToRawFileModel(file: File): Promise<RawFileModel> {
    return new Promise<RawFileModel>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64 = reader.result ? this.convertDataUrlToBase64(reader.result.toString()) : '';
        return resolve({
          name: file.name,
          dataBase64: base64,
        } as RawFileModel);
      }

      reader.onerror = (error) => reject(error);
    });
  }

  avatarUrlToBase64(url: string): Observable<string> {
    return this.httpClient
      .get(url, {
        observe: 'body',
        responseType: 'arraybuffer',
      })
      .pipe(
        take(1),
        map((arrayBuffer) =>
          btoa(
            Array.from(new Uint8Array(arrayBuffer))
              .map((b) => String.fromCharCode(b))
              .join('')
          )
        )
      );
  }

  downloadFile(file: RawFileModel): void {
    const downloadURL = window.URL.createObjectURL(
      new Blob([this.convertBase64ToArrayBuffer(file.dataBase64!)])
    );
    const link = document.createElement('a');
    link.href = downloadURL;
    link.download = file.name;
    link.click();
  }
}
