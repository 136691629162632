<app-page>
  <app-page-title
    [title]="'INTEGRATIONS.OVERVIEW.TITLE' | translate"
  ></app-page-title>
  <h2 class="title">{{ "INTEGRATIONS.INTEGRATIONS" | translate }}</h2>
  <div class="cards-container" *ngIf="integrations$ | async as integrations">
    <div class="card-content">
      <div class="info">
        <img class="logo" src="assets/img/webex-logo.jpg" />
        <div class="data">
          <div class="name">Webex</div>
          <div *ngIf="!(webexIntegration$ | async)" class="status">
            {{ "INTEGRATIONS.ACCOUNT-NOT-CONNECTED" | translate }}
          </div>
          <div
            *ngIf="webexIntegration$ | async as webexIntegration"
            class="status"
          >
            {{ "INTEGRATIONS.CONNECTED-TO" | translate }}:
            <b>{{ webexIntegration.displayName }}</b>
          </div>
        </div>
      </div>
      <dx-button
        *ngIf="!(webexIntegration$ | async)"
        type="normal"
        (click)="integrateWebex()"
      >
        {{ "INTEGRATIONS.CONNECT" | translate }}
      </dx-button>
      <dx-button
        *ngIf="webexIntegration$ | async"
        class="cta-btn"
        type="danger"
        (click)="showDisconnectModal('webex')"
      >
        {{ "INTEGRATIONS.DISCONNECT" | translate }}
      </dx-button>
    </div>
    <div class="card-content">
      <div class="info">
        <img class="logo" src="assets/img/gotowebinar-logo.jpg" />
        <div class="data">
          <div class="name">GoToWebinar</div>
          <div *ngIf="!(goToWebinarIntegration$ | async)" class="status">
            {{ "INTEGRATIONS.ACCOUNT-NOT-CONNECTED" | translate }}
          </div>
          <div
            *ngIf="goToWebinarIntegration$ | async as goToWebinarIntegration"
            class="status"
          >
            {{ "INTEGRATIONS.CONNECTED-TO" | translate }}:
            <b>{{ goToWebinarIntegration.displayName }}</b>
          </div>
        </div>
      </div>
      <dx-button
        *ngIf="!(goToWebinarIntegration$ | async)"
        class="cta-btn"
        type="normal"
        (click)="integrateGoToWebinar()"
      >
        {{ "INTEGRATIONS.CONNECT" | translate }}
      </dx-button>
      <dx-button
        *ngIf="goToWebinarIntegration$ | async"
        class="cta-btn"
        type="danger"
        (click)="showDisconnectModal('gotowebinar')"
      >
        {{ "INTEGRATIONS.DISCONNECT" | translate }}
      </dx-button>
    </div>
    <div class="card-content">
      <div class="info">
        <img class="logo" src="assets/img/gut-beraten-logo.png" />
        <div class="data">
          <div class="name">Gut beraten</div>
          <div *ngIf="!(wbdIntegration$ | async)" class="status">
            {{ "INTEGRATIONS.ACCOUNT-NOT-CONNECTED" | translate }}
          </div>
          <div *ngIf="wbdIntegration$ | async as wbdIntegration" class="status">
            {{ "INTEGRATIONS.CONNECTED" | translate }}
          </div>
        </div>
      </div>
      <dx-button
        *ngIf="!(wbdIntegration$ | async)"
        class="cta-btn"
        type="normal"
        (click)="showIntegrateWbd()"
      >
        {{ "INTEGRATIONS.CONNECT" | translate }}
      </dx-button>
      <dx-button
        *ngIf="wbdIntegration$ | async"
        class="cta-btn"
        type="danger"
        (click)="showDisconnectModal('gutberaten')"
      >
        {{ "INTEGRATIONS.DISCONNECT" | translate }}
      </dx-button>
    </div>
  </div>
</app-page>

<dx-popup
  [maxHeight]="280"
  [maxWidth]="550"
  [hideOnOutsideClick]="true"
  [showCloseButton]="true"
  [(visible)]="disconnectModalVisible"
  [title]="'INTEGRATIONS.ATTENTION' | translate"
  [dragEnabled]="false"
  [wrapperAttr]="{ class: 'disconnect-modal' }"
>
  <div *dxTemplate="let data of 'content'">
    <div class="disconnect-modal-copy">
      {{
        disconnectType == "gutberaten"
          ? ("INTEGRATIONS.DISCONNECT-WBD-CONFIRMATION" | translate)
          : ("INTEGRATIONS.DISCONNECT-CONFIRMATION" | translate)
      }}
    </div>
    <div class="disconnect-modal-cta">
      <dx-button class="cta-btn" type="normal" (click)="closeDisconnectModal()">
        {{ "COMMON.CANCEL" | translate }}
      </dx-button>
      <dx-button
        class="cta-btn"
        type="danger"
        (click)="disconnectIntegration()"
      >
        {{ "INTEGRATIONS.DISCONNECT" | translate }}
      </dx-button>
    </div>
  </div>
</dx-popup>

<app-integrate-wbd-modal
  *ngIf="showIntegrateWbdModal"
  [(showModal)]="showIntegrateWbdModal"
  (submit)="integrateWbd($event)"
></app-integrate-wbd-modal>
