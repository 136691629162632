import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PersonModel} from "../../models/person/person.model";
import {MatIconModule} from "@angular/material/icon";
import {CommonModule} from "@angular/common";
import {PersonSummaryModel} from "../../models/person/person-summary.model";
import {MatButtonModule} from "@angular/material/button";

@Component({
    selector: 'app-selectable-person',
    templateUrl: './selectable-person.component.html',
    styleUrls: ['./selectable-person.component.css'],
    imports: [
        CommonModule,
        MatIconModule,
        MatButtonModule
    ],
    standalone: true
})
export class SelectablePersonComponent implements OnInit {

    @Input()
    person!: PersonModel | PersonSummaryModel;
    @Input()
    showAddButton: boolean = false;
    @Input()
    showRemoveButton: boolean = false;
    @Output()
    onAddClicked = new EventEmitter<PersonModel | PersonSummaryModel>();
    @Output()
    onRemoveClicked = new EventEmitter<PersonModel | PersonSummaryModel>();

    constructor() {
    }

    ngOnInit(): void {
    }

}
