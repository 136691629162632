<div class="row h-100">
  <div class="col-6 attendees-list">
    <div class="content-title">
      {{ "MY-TRAININGS.TRAINING-INVITE.ALL-ATTENDEES" | translate }}
    </div>
    <dx-form [formData]="formData" labelMode="floating">
      <dxi-item>
        <dxo-label [text]="'COMMON.SEARCH' | translate"></dxo-label>
        <div *dxTemplate>
          <dx-text-box
            icon="search"
            width="100%"
            valueChangeEvent="input"
            labelMode="floating"
            stylingMode="outlined"
            placeholder=""
            [label]="
              'MY-TRAININGS.TRAINING-INVITE.SEARCH-IN-EMPLOYEES-AND-CONTACTS'
                | translate
            "
            [(value)]="formData.search"
            (valueChange)="onSerachValueChanged($event)"
          >
            <dxi-button
              name="search"
              type="danger"
              location="before"
              [options]="{
                icon: 'search',
                type: 'default',
                disabled: true
              }"
            ></dxi-button>
          </dx-text-box>
        </div>
      </dxi-item>
      <dxi-item itemType="group" colCount="2">
        <dxi-item dataField="locationFilter">
          <div *dxTemplate>
            <dx-drop-down-box
              [value]="formData.locationFilter"
              valueExpr="id"
              displayExpr="name"
              labelMode="floating"
              stylingMode="outlined"
              [label]="'COMMON.FIELDS.LOCATION' | translate"
              [dataSource]="locations"
            >
              <div *dxTemplate="let data of 'content'">
                <dx-tree-view
                  [dataSource]="locations"
                  dataStructure="plain"
                  keyExpr="id"
                  selectionMode="multiple"
                  showCheckBoxesMode="normal"
                  [selectNodesRecursive]="false"
                  displayExpr="name"
                  [selectByClick]="true"
                  (onItemSelectionChanged)="onLocationsSelected($event)"
                >
                </dx-tree-view>
              </div>
            </dx-drop-down-box>
          </div>
        </dxi-item>
        <dxi-item dataField="organizationFilter">
          <div *dxTemplate>
            <dx-drop-down-box
              [value]="formData.organizationFilter"
              [inputAttr]="{ 'aria-label': 'Owner' }"
              valueExpr="id"
              displayExpr="name"
              [dataSource]="organisationUnits"
              labelMode="floating"
              stylingMode="outlined"
              [label]="'COMMON.FIELDS.DEPARTMENT' | translate"
            >
              <div *dxTemplate="let data of 'content'">
                <dx-tree-view
                  [dataSource]="organisationUnits"
                  keyExpr="id"
                  selectionMode="multiple"
                  showCheckBoxesMode="normal"
                  [selectNodesRecursive]="false"
                  displayExpr="name"
                  [selectByClick]="true"
                  (onItemSelectionChanged)="onOrganizationSelected($event)"
                >
                </dx-tree-view>
              </div>
            </dx-drop-down-box>
          </div>
        </dxi-item>
      </dxi-item>
    </dx-form>

    <dx-check-box
      class="all-attendees-checkbox"
      [value]="isAllSelected"
      [text]="'MY-TRAININGS.TRAINING-INVITE.ALL-EMPLOYEES' | translate"
      (click)="onAllEmployeesClick($event)"
    ></dx-check-box>
    <div class="available-list">
      <div class="list-wrapper" (scroll)="onScroll($event)">
        <div class="product" *ngFor="let person of allPersons; let i = index">
          <div class="info">
            <dx-check-box
              [(value)]="person.selected"
              (onValueChanged)="onPersonSelected($event, person)"
            ></dx-check-box>
            <div>
              <div>{{ person.firstName }} {{ person.lastName }}</div>
              <div class="email-muted">
                {{ person.email }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="list-footer">
      <div>
        {{ "MY-TRAININGS.TRAINING-INVITE.TOTAL-SELECTED" | translate }}:
        <strong>{{ selectedPersons.length }}</strong>
      </div>
      <!-- <div>
        <dx-button
          [disabled]="!selectedPersons.length"
          [text]="'MY-TRAININGS.TRAINING-INVITE.CANCEL-ALL' | translate"
          type="default"
          class="no-bg"
          (click)="cancelFilters()"
        >
        </dx-button>
      </div> -->
    </div>
  </div>

  <div class="col-6 attendees-list">
    <div class="content-title">
      {{ "MY-TRAININGS.TRAINING-INVITE.SELECTED" | translate }}
    </div>
    <div class="full-height-list">
      <div class="list-wrapper">
        <dx-list [dataSource]="selectedPersons" class="striped-list">
          <div *dxTemplate="let item of 'item'">
            <div class="product">
              <div class="info">
                <div>{{ item.firstName }} {{ item.lastName }}</div>
                <div class="email-muted">
                  {{ item.email }}
                </div>
              </div>
              <div class="close-action" (click)="onRemoveClicked(item)">
                <i class="dx-icon-close"></i>
              </div>
            </div>
          </div>
        </dx-list>
      </div>
    </div>
    <div class="list-footer">
      <div></div>
      <div>
        <dx-button
          [disabled]="!selectedPersons.length"
          [text]="'MY-TRAININGS.TRAINING-INVITE.DELETE-ALL' | translate"
          type="default"
          class="no-bg"
          (click)="onRemoveClicked()"
        >
        </dx-button>
      </div>
    </div>
  </div>
</div>
