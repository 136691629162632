import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Configuration} from "../../assets/Configuration";
import {OrganisationUnitModel} from "../models/organisation-unit/organisation-unit.model";
import {OrganisationUnitCreationModel} from "../models/organisation-unit/organisation-unit-creation.model";
import {OrganisationUnitUpdateModel} from "../models/organisation-unit/organisation-unit-update.model";
import {map} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class OrganisationUnitService {

    constructor(private httpClient: HttpClient) {
    }

    public getAllOrganisationUnits(): Observable<OrganisationUnitModel[]> {
        return this.httpClient.get<OrganisationUnitModel[]>(Configuration.getApiBaseUrl() + "/organisationunit/").pipe(
            map(organisationUnits => {
                const organisationUnitIds = organisationUnits.map(ou => ou.id);
                for (let ou of organisationUnits) {
                    if (ou.parentId != null && !organisationUnitIds.includes(ou.parentId)) {
                        ou.parentId = null;
                    }
                }
                return organisationUnits;
            })
        );
    }


    getOrganisationUnit(id: string): Observable<OrganisationUnitModel> {
        return this.httpClient.get<OrganisationUnitModel>(Configuration.getApiBaseUrl() + "/organisationunit/" + id);
    }

    addOrganisationUnit(creationModel: OrganisationUnitCreationModel): Observable<OrganisationUnitModel> {
        const formData = new FormData();
        formData.append('name', creationModel.name);
        formData.append('type', creationModel.type!.toString());
        formData.append('parentId', creationModel.parentId);

        if (creationModel.description) {
            formData.append('description', creationModel.description);
        }
        if (creationModel.reportFooter) {
            formData.append('reportFooter', creationModel.reportFooter);
        }
        if (creationModel.image) {
            formData.append('image', creationModel.image);
        }
        return this.httpClient.post<OrganisationUnitModel>(Configuration.getApiBaseUrl() + '/organisationunit/', formData);
    }

    updateOrganisationUnit(id: string, updateModel: OrganisationUnitUpdateModel): Observable<OrganisationUnitModel> {
        const formData = new FormData();
        formData.append('name', updateModel.name);

        if (updateModel.description) {
            formData.append('description', updateModel.description);
        }
        if (updateModel.reportFooter) {
            formData.append('reportFooter', updateModel.reportFooter);
        }
        if (updateModel.image) {
            formData.append('image', updateModel.image);
        }
        if (updateModel.parentId) {
            formData.append('parentId', updateModel.parentId);
        }
        return this.httpClient.post<OrganisationUnitModel>(Configuration.getApiBaseUrl() + '/organisationunit/' + id, formData);
    }
}
