import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {Configuration} from "../../assets/Configuration";
import { ContactSummaryModel } from '../models/contact/contact-summary.model';
import { ContactCreationAndUpdateModel } from '../models/contact/contact-creation-and-update.model';
import { ContactFullModel } from '../models/contact/contact-full.model';

@Injectable({
  providedIn: 'root',
})
export class ExternalContactService {
  constructor(private httpClient: HttpClient) {}

  public getAllContacts(): Observable<ContactSummaryModel[]> {
    return this.httpClient.get<ContactSummaryModel[]>(
      Configuration.getApiBaseUrl() + '/contact'
    );
  }

  public getContact(id: string): Observable<ContactFullModel> {
    return this.httpClient.get<ContactFullModel>(
      Configuration.getApiBaseUrl() + '/contact/' + id
    );
  }

  public addContact(
    creationAndUpdateModel: ContactCreationAndUpdateModel
  ): Observable<ContactFullModel> {
    return this.httpClient.post<ContactFullModel>(
      Configuration.getApiBaseUrl() + '/contact',
      creationAndUpdateModel
    );
  }

  public updateContact(
    editingPersonId: string,
    creationAndUpdateModel: ContactCreationAndUpdateModel
  ): Observable<ContactFullModel> {
    return this.httpClient.post<ContactFullModel>(
      Configuration.getApiBaseUrl() + '/contact/' + editingPersonId,
      creationAndUpdateModel
    );
  }
}
