import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-panel',
  template: `
    <h2 *ngIf="title" class="title">{{title}}</h2>
    <ng-content></ng-content>
  `,
  styleUrls: ['./panel.component.scss'],
})
export class PanelComponent {
  @Input()
  title!: string | null;
}
