<div [hidden]="!runningDownloads.length" class="downloads-container" >
  <div style="width: 100%; bottom: 0;">
    <div *ngFor="let runningDownload of runningDownloads">
      <div style="position: absolute; top: 7px; z-index: 2; width: 100%; text-align: center;">
        {{runningDownload.fileName}} - {{getPercentage(runningDownload.progressPercent)}}%
      </div>
      <mat-progress-bar mode="determinate" color="accent" [value]="runningDownload.progressPercent" style="height: 20px;"></mat-progress-bar>
    </div>
  </div>
  <a #downloadLink download></a>
</div>