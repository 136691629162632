<div *ngIf="isDataLoaded">
  <div class="login-wrapper">
    <img
      *ngIf="!company"
      class="login-background"
      src="assets/img/login_background.jpeg"
    />
    <img
      *ngIf="company"
      class="login-background"
      [src]="companyBackgroundUrl"
    />
    <div class="login-content" [ngStyle]="{ 'visibility': company ? 'hidden' : 'visible' }">
      <img class="login-logo" src="assets/img/skeddle_white.png" />
      <div class="login-text">Qualification Management - Easy</div>
    </div>
    <dx-button text="Login" type="normal" (click)="onLoginClick()"> </dx-button>
  </div>
</div>
