<header>
  <dx-toolbar class="header-toolbar">
    <dxi-item
      *ngIf="menuToggleEnabled"
      location="before"
      widget="dxButton"
      cssClass="menu-button"
      [options]="{
        icon: 'menu',
        type: 'normal',
        onClick: toggleMenu
      }"
    >
    </dxi-item>
    <dxi-item location="after" class="user-container">
      <ng-container *dxTemplate>
        <div *ngIf="user" class="user">
          <div class="username">
            <span>{{ "COMMON.WELCOME" | translate }},</span>
            {{ userName }}
          </div>
          <dx-select-box
            fieldTemplate="field"
            [showDropDownButton]="false"
            [dropDownOptions]="{ width: '200px' }"
            class="avatar-select-box"
          >
            <div *dxTemplate="let data of 'field'">
              <div class="avatar">
                <img [crossOrigin]="true" [src]="user.image" />
              </div>
              <dx-text-box [visible]="false"></dx-text-box>
            </div>
            <dxi-item (click)="openProfile()" class="menu-item">
              {{ "USER-NAV.PROFILE" | translate }}</dxi-item
            >
            <dxi-item (click)="openChangePasswordModal()" class="menu-item">
              {{ "USER-NAV.CHANGE-PASSWORD" | translate }}
            </dxi-item>
            <dxi-item>
              <a href="mailto:service@skeddle.com" class="menu-item">
                {{ "COMMON.SUPPORT" | translate }}
              </a>
            </dxi-item>
            <dxi-item (click)="logout()" class="menu-item">
              {{ "USER-NAV.SIGN-OUT" | translate }}
            </dxi-item>
          </dx-select-box>
        </div>
      </ng-container>
    </dxi-item>
  </dx-toolbar>
</header>

<app-change-password-modal
  [(showModal)]="showChangePasswordModal"
></app-change-password-modal>
