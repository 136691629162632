import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {Configuration} from "../../assets/Configuration";
import { MyTrainingModel } from '../models/training/my-trainings.model';

@Injectable({
  providedIn: 'root',
})
export class MyTrainingsService {
  constructor(private httpClient: HttpClient) {}

  public getMyTrainings(): Observable<MyTrainingModel> {
    return this.httpClient.get<MyTrainingModel>(
      Configuration.getApiBaseUrl() + '/mytrainings'
    );
  }
}
