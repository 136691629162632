<ng-container *ngIf="!contentFilePreview && !isExternal">
  <div id="avatar-upload" class= "uploader-container status">
    <div class="uploader-preview-container cursor-pointer">
      <img src="assets/img/upload.jpg" />
      <span class="preview-title">{{ "COMMON.UPLOAD-VIDEO" | translate }}</span>
      <span class="preview-description">.mp4</span>
    </div>
    <dx-file-uploader
        *ngIf="!readOnly"
        #fileUploader
        dropZone="#avatar-upload"
        name="file"
        [uploadUrl]="uploadUrl"
        [chunkSize]="5000000"
        [multiple]="false"
        uploadMode="instantly"
        (onBeforeSend)="onBeforeSend($event, accessToken)"
        (onUploaded)="onUploaded($event)"
        (onUploadStarted)="handleUploadStarted($event)"
      >
    </dx-file-uploader>
    <div *ngIf="shouldCall && fileUploadStatus != ''" class="mt-1 text-muted d-flex justify-content-center">
      <p>Status : {{ fileUploadStatus }}</p>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="contentFilePreview">
  <dx-button
    *ngIf="!readOnly"
    icon="close"
    type="default"
    class="delete-btn"
    (click)="onContentDeleteClick()"
  ></dx-button>

  <div style="height: 225px">
    <ng-container
      *ngIf="
        contentFilePreview.contentType == CourseContentTypeModel.AzureVideo
      "
    >
      <video
        #videoPlayer
        [crossOrigin]="true"
        [src]="contentFilePreview.url"
        controls
        class="w-100 h-100"
      ></video>
    </ng-container>

    <ng-container
      *ngIf="
        contentFilePreview.contentType == CourseContentTypeModel.AzureScorm
      "
    >
      <img
        *ngIf="thumbnailUrl$ | async as imagePreviewUrl"
        [crossOrigin]="true"
        [src]="imagePreviewUrl"
        controls
        class="w-100 h-100"
      />
      <img
        *ngIf="!(thumbnailUrl$ | async)"
        [crossOrigin]="true"
        [src]="null"
        controls
        class="w-100 h-100"
        appTrainingImageFallback
      />
    </ng-container>
  </div>

  <div class="mt-1 text-muted d-flex justify-content-center">
    {{ contentFilePreview.name }}
  </div>
</ng-container>

<ng-container *ngIf="isExternal">
    <div style="height: 225px">
    <img
            *ngIf="thumbnailUrl$ | async as imagePreviewUrl"
            [crossOrigin]="true"
            [src]="imagePreviewUrl"
            controls
            class="w-100 h-100"
    />
    </div>
</ng-container>
