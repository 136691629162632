<div class="training-invite" *ngIf="training">
  <div class="training-invite-card">
    <div class="training-location-image">
      <img [src]="training.logoUrl" />
    </div>
    <div class="training-invite-card-title">
      {{ "MY-INVITATION.OVERVIEW.TITLE" | translate }}
    </div>
    <div class="training-invite-card-subtitle">
      {{ "MY-INVITATION.OVERVIEW.SUBTITLE" | translate }}
      {{ training.adminName }}
    </div>
    <app-panel>
      <div class="training-image-container">
        <img
          class="training-image"
          [src]="training.imageUrl"
          appTrainingImageFallback
        />
      </div>
      <div class="training-name">{{ training.name }}</div>
      <div class="training-description">{{ training.description }}</div>
      <div class="training-info">
        <div class="training-admin">
          <img
            class="training-admin-image"
            [src]="training.adminImageUrl"
            appTrainingImageFallback
          />
          {{ training.adminName }}
        </div>
        <div class="training-stats">
          <div *ngIf="training.iddTime" class="training-stats-item">
            {{ "COMMON.FIELDS.IDD-TIME-CREDIT" | translate }}:
            {{ durationString }}
          </div>
        </div>
      </div>
      <div class="training-widgets">
        <div class="training-widget-item">
          <div class="training-widget-badge">
            <i class="dx-icon dx-svg-icon" [innerHTML]="getCalendarIcon()"></i>
            {{ "COMMON.DATE" | translate }}:
          </div>
          <div class="training-widget-value">
            <div
              *ngFor="let date of training.dates"
              class="training-dates-block"
            >
              <div class="training-dates-block-date">
                {{ training.dates[0] | trainingDate:translateService.currentLang }}
              </div>
              <div *ngIf="translateService.currentLang == 'en'">
                {{ training.dates[0].start | date : "hh:mm a" }} -
                {{ training.dates[0].end | date : "hh:mm a" }}
              </div>
              <div *ngIf="translateService.currentLang == 'de'">
                {{ training.dates[0].start | date : "HH:mm" }} -
                {{ training.dates[0].end | date : "HH:mm" }}
              </div>
            </div>
          </div>
        </div>
        <div class="training-widget-item">
          <div class="training-widget-badge">
            <i class="dx-icon-map dx-svg-icon"></i>
            {{ "COMMON.FIELDS.LOCATION" | translate }}:
          </div>
          <div class="training-widget-value">
            <span
              *ngIf="training.locationType == TrainingLocationType.GoToWebinar"
            >
              GoTo Webinar
            </span>
            <span *ngIf="training.locationType == TrainingLocationType.Webex">
              Webex
            </span>
            <span
              *ngIf="
                training.locationType != TrainingLocationType.GoToWebinar &&
                training.locationType != TrainingLocationType.Webex
              "
            >
              {{ training.location }}
            </span>
            <div class="training-location-details">
              <div class="training-location-details-title">
                {{ "COMMON.FIELDS.LOCATION-DETAILS" | translate }}
              </div>
              <span
                *ngIf="
                  training.locationType == TrainingLocationType.Address ||
                  training.locationType == TrainingLocationType.Web
                "
                >{{ training.locationSuffix }}</span
              >
              <span
                *ngIf="
                  training.locationType != TrainingLocationType.Address &&
                  training.locationType != TrainingLocationType.Web &&
                  isInvitationAccepted &&
                  !accessToken
                "
                >{{ training.locationSuffix }}</span
              >
              <span
                *ngIf="
                  training.locationType != TrainingLocationType.Address &&
                  training.locationType != TrainingLocationType.Web &&
                  accessToken &&
                  training.joinUrl
                "
                >{{ training.joinUrl }}</span
              >
              <span
                *ngIf="
                  training.locationType != TrainingLocationType.Address &&
                  training.locationType != TrainingLocationType.Web &&
                  !isInvitationAccepted &&
                  !accessToken
                "
                >{{ "MY-INVITATION.ACCES-LINK-INFO" | translate }}
              </span>
              <span
                *ngIf="
                  training.locationType != TrainingLocationType.Address &&
                  training.locationType != TrainingLocationType.Web &&
                  accessToken &&
                  !training.joinUrl
                "
                >{{ "MY-INVITATION.ACCES-LINK-INFO" | translate }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="training-cta-section">
        <dx-button
          *ngIf="training.status === 0 && !isInvitationAccepted"
          [text]="'MY-TRAININGS.OVERVIEW.ACCEPT-INVITATION' | translate"
          type="normal"
          (click)="onAcceptInvitationClick()"
        >
        </dx-button>
        <dx-button
          *ngIf="isInvitationAccepted"
          [text]="'MY-TRAININGS.OVERVIEW.DECLINE-INVITATION' | translate"
          type="danger"
          (click)="onDeclineInvitationClick()"
        >
        </dx-button>
        <dx-button
          *ngIf="isInvitationAccepted"
          [text]="'MY-INVITATION.DOWNLOAD-ICS' | translate"
          type="normal"
          (click)="onDownloadIcs()"
        >
        </dx-button>
        <div class="training-info" *ngIf="training.status === 1">
          {{ "MY-INVITATION.OLD-EVENT" | translate }}
        </div>
        <div class="training-info" *ngIf="training.status === 2">
          {{ "MY-INVITATION.EVENT-BOOKED" | translate }}
        </div>
        <div class="training-info" *ngIf="training.status === 3">
          {{ "MY-INVITATION.EVENT-CANCELLED" | translate }}
        </div>
      </div>
      <div class="training-section">
        <div class="training-section-title">
          {{ "COMMON.FIELDS.DESCRIPTION" | translate }}
        </div>
        <dx-html-editor
          label=""
          [(value)]="training.descriptionExtended"
          [readOnly]="true"
        >
        </dx-html-editor>
      </div>
      <div class="training-section training-section-location">
        <div *ngIf="training.locationType == TrainingLocationType.Address">
          <ng-container *ngIf="training.latitude && training.longitude">
            <app-google-maps
              class="my-3"
              [location]="location"
            ></app-google-maps>
          </ng-container>
        </div>
        <div
          *ngIf="training.locationType == TrainingLocationType.GoToWebinar"
          class="training-location-image-container"
        >
          <img
            class="training-location-image"
            src="assets/img/gotowebinar-logo.jpg"
          />
        </div>
        <div
          *ngIf="training.locationType == TrainingLocationType.Webex"
          class="training-location-image-container"
        >
          <img
            class="training-location-image"
            src="assets/img/webex-logo.jpg"
          />
        </div>
      </div>
    </app-panel>
  </div>
</div>

<app-accept-invitation-modal
  *ngIf="showAcceptInvitationModal && training"
  [(showModal)]="showAcceptInvitationModal"
  [training]="training"
  [trainingKey]="trainingKey"
  (onInviteAcepted)="onInviteAcepted()"
></app-accept-invitation-modal>
