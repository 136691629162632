import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-page-title',
  template: `
    <div class="title-container">
      <h1 class="title">{{ title }}</h1>
      <div class="actions"><ng-content></ng-content></div>
    </div>
  `,
  styleUrls: ['./page-title.component.scss'],
})
export class PageTitleComponent {
  @Input()
  title!: string;
}
