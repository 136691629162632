<dx-popup
  [maxHeight]="650"
  [maxWidth]="1040"
  [hideOnOutsideClick]="true"
  [showCloseButton]="true"
  [(visible)]="isPopupVisible"
  [title]="'COMMON.FIELDS.ATTENDEES' | translate"
  [dragEnabled]="false"
  (onHidden)="onClose()"
  (onShowing)="onShow()"
>
  <div *dxTemplate="let data of 'content'">
    <dx-tabs
      class="table-tabs"
      width="100%"
      [selectedIndex]="selectedTab"
      [showNavButtons]="false"
      [dataSource]="tabs"
      (onItemClick)="onTabChange($event)"
    ></dx-tabs>
    <app-table
      [data]="persons"
      [columns]="personsColumns"
      [showSearch]="true"
      [showGrouping]="true"
      [searchLabel]="'COMMON.SEARCH' | translate"
      [searchPlacehoder]="'EMPLOYEE.OVERVIEW.FIND-EMPLOYEES' | translate"
      [allowDataEditing]="tab == 3 ? true : false"
      (onRowUpdate)="onRequestedMemberUpdate($event)"
      (onRowUpdateStarted)="onRequestedMemberUpdateStart($event)"
    ></app-table>
  </div>
</dx-popup>
