export interface SurveyQuestionModel {
    id: string;
    text: string;
    number: number;
    type: SurveyQuestionType;
    scaleStart: number | null;
    scaleEnd: number | null;
}

export enum SurveyQuestionType {
    FreeTextInput,
    RadioSelectNumeric,
    GroupLabel
}