<div class="employee-form">
  <app-page>
    <div class="top-cta" [ngClass]="{ 'top-cta-right': isProfilePage }">
      <dx-button
        *ngIf="!isProfilePage"
        [text]="'COMMON.BACK' | translate"
        icon="back"
        type="default"
        class="integration-btn"
        (click)="navigateBack()"
      >
      </dx-button>
      <dx-button
        *ngIf="!editingPersonId"
        [text]="'COMMON.SAVE' | translate"
        type="normal"
        (click)="onFormSubmit()"
      >
      </dx-button>
      <dx-drop-down-button
        *ngIf="editingPersonId"
        [splitButton]="true"
        text="{{ 'COMMON.SAVE' | translate }}"
        stylingMode="contained"
        type="normal"
        [dropDownOptions]="{ width: 230 }"
        (onButtonClick)="onFormSubmit()"
      >
        <dxi-item (click)="showOverviewPopup()">{{
          "EMPLOYEE.FORM.TRAINING-OVERVIEW" | translate
        }}</dxi-item>
        <dxi-item
          *ngIf="!isUserHasAccount && !isInactive"
          (click)="openAddUserConfirmationModal()"
          >{{ "EMPLOYEE.FORM.SEND-ACCESS-DATA" | translate }}</dxi-item
        >
        <dxi-item
          *ngIf="isUserHasAccount && !isInactive && isManager"
          (click)="openChangeUserPasswordConfirmationModal()"
          >{{ "EMPLOYEE.FORM.RESET-PASSWORD" | translate }}</dxi-item
        >
        <dxi-item
          *ngIf="isInactive && isManager"
          (click)="togglePersonState('active')"
          >{{ "EMPLOYEE.FORM.SET-ACTIVE" | translate }}</dxi-item
        >
        <dxi-item
          *ngIf="!isInactive && isManager"
          (click)="togglePersonState('inactive')"
          >{{ "EMPLOYEE.FORM.SET-INACTIVE" | translate }}</dxi-item
        >
      </dx-drop-down-button>
    </div>
    <app-page-title
      [title]="
        isProfilePage
          ? ('EMPLOYEE.TITLE-PROFILE' | translate)
          : editingPersonId
          ? ('EMPLOYEE.TITLE-EDIT' | translate)
          : ('EMPLOYEE.TITLE-ADD' | translate)
      "
    ></app-page-title>
    <div class="row">
      <div class="col-auto">
        <app-panel class="avatar-panel">
          <div id="avatar-upload" class="uploader-container">
            <img
              class="user-avatar"
              [src]="employeeForm.imageBase64 || employeeForm.avatar"
              *ngIf="employeeForm.imageBase64 || employeeForm.avatar"
            />
            <div
              class="uploader-preview-container"
              *ngIf="!employeeForm.imageBase64 && !employeeForm.avatar"
            >
              <img src="assets/img/upload.jpg" />
              <span class="preview-title">{{
                "COMMON.UPLOAD-IMAGE" | translate
              }}</span>
              <span class="preview-description">{{
                "COMMON.UPLOAD-IMAGE-SIZE" | translate
              }}</span>
            </div>
          </div>
          <dx-file-uploader
            #fileUploader
            dialogTrigger="#avatar-upload"
            dropZone="#avatar-upload"
            [multiple]="false"
            [allowedFileExtensions]="['.jpg', '.jpeg', '.gif', '.png']"
            uploadMode="useForm"
            [visible]="false"
            (valueChange)="onAvatarUploaded($event)"
          ></dx-file-uploader>
        </app-panel>
      </div>
      <div class="col">
        <app-panel class="employee-panel">
          <dx-form
            #topForm
            labelMode="floating"
            [showColonAfterLabel]="false"
            [formData]="employeeForm"
          >
            <dxi-item itemType="group">
              <dxo-col-count-by-screen
                [xs]="1"
                [sm]="1"
                [md]="1"
                [lg]="2"
              ></dxo-col-count-by-screen>
              <dxi-item itemType="group">
                <dxi-item dataField="firstName">
                  <dxo-label
                    [text]="'COMMON.FIELDS.FIRST-NAME' | translate"
                  ></dxo-label>
                  <dxi-validation-rule
                    type="required"
                    [message]="'VALIDATIONS.FIRST-NAME-REQUIRED' | translate"
                  ></dxi-validation-rule>
                </dxi-item>
                <dxi-item dataField="lastName">
                  <dxo-label
                    [text]="'COMMON.FIELDS.LAST-NAME' | translate"
                  ></dxo-label>
                  <dxi-validation-rule
                    type="required"
                    [message]="'VALIDATIONS.LAST-NAME-REQUIRED' | translate"
                  ></dxi-validation-rule>
                </dxi-item>
                <dxi-item
                  dataField="email"
                  [editorOptions]="{
                    disabled: !isManager
                  }"
                >
                  <dxo-label
                    [text]="'COMMON.FIELDS.EMAIL' | translate"
                  ></dxo-label>
                  <dxi-validation-rule
                    type="required"
                    [message]="'VALIDATIONS.EMAIL-REQUIRED' | translate"
                  ></dxi-validation-rule>
                  <dxi-validation-rule
                    type="email"
                    [message]="'VALIDATIONS.EMAIL-INVALID' | translate"
                  >
                  </dxi-validation-rule>
                </dxi-item>
                <dxi-item
                  *ngIf="editingPersonId"
                  editorType="dxTextBox"
                  [editorOptions]="{
                    disabled: true,
                    value: this.iddHoursReachedString
                  }"
                >
                  <dxo-label
                    [text]="'COMMON.FIELDS.HOURS-ACHIEVED' | translate"
                  ></dxo-label>
                </dxi-item>
              </dxi-item>
              <dxi-item itemType="group">
                <dxi-item dataField="locationId">
                  <div *dxTemplate>
                    <dx-drop-down-box
                      #locationDropDown
                      [(value)]="employeeForm.locationId"
                      [inputAttr]="{ 'aria-label': 'Owner' }"
                      valueExpr="id"
                      displayExpr="name"
                      [label]="('COMMON.FIELDS.LOCATIONS-DEPARTMENTS' | translate) + ' *'"
                      [dataSource]="locations"
                      [disabled]="!isManager"
                      labelMode="floating"
                      stylingMode="outlined"
                      [showClearButton]="true"
                      (onValueChanged)="locationDropDown.instance.close()">
                    >
                      <dx-validator validationGroup="customFields">
                        <dxi-validation-rule
                          type="required"
                          message="Location is required"
                        ></dxi-validation-rule>
                      </dx-validator>
                      <div *dxTemplate="let data of 'content'">
                        <dx-tree-view
                          [searchEnabled]="true"
                          [dataSource]="locations"
                          keyExpr="id"
                          dataStructure="plain"
                          [selectNodesRecursive]="false"
                          displayExpr="name"
                          (onItemClick)="onLocationSelected($event)"
                        >
                        </dx-tree-view>
                      </div>
                    </dx-drop-down-box>
                  </div>
                </dxi-item>
                <dxi-item
                  [editorOptions]="rolesOptions"
                  dataField="roleId"
                  editorType="dxSelectBox"
                >
                  <dxo-label
                    [text]="'COMMON.FIELDS.ROLE' | translate"
                  ></dxo-label>
                  <dxi-validation-rule
                    type="required"
                    [message]="'VALIDATIONS.ROLE-REQUIRED' | translate"
                  ></dxi-validation-rule>
                </dxi-item>
                <dxi-item>
                  <dxo-label
                    [text]="'COMMON.FIELDS.TRAINING-GROUPS' | translate"
                  ></dxo-label>
                  <div *dxTemplate>
                    <dx-drop-down-box
                      [(value)]="employeeForm.trainingGroupIds"
                      [inputAttr]="{ 'aria-label': 'Owner' }"
                      valueExpr="id"
                      displayExpr="name"
                      [label]="'COMMON.FIELDS.TRAINING-GROUPS' | translate"
                      [dataSource]="trainingGroups"
                      [disabled]="!isManager"
                      labelMode="floating"
                      stylingMode="outlined"
                    >
                      <div *dxTemplate="let data of 'content'">
                        <dx-tree-view
                          [searchEnabled]="true"
                          [dataSource]="trainingGroups"
                          dataStructure="plain"
                          keyExpr="id"
                          selectionMode="multiple"
                          showCheckBoxesMode="normal"
                          [selectNodesRecursive]="false"
                          displayExpr="name"
                          [selectByClick]="true"
                          (onItemSelectionChanged)="
                            onTrainingGroupSelected($event)
                          "
                        >
                        </dx-tree-view>
                      </div>
                    </dx-drop-down-box>
                  </div>
                </dxi-item>
              </dxi-item>
            </dxi-item>
          </dx-form>
        </app-panel>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <app-panel
          [title]="
            editingPersonId ? null : ('EMPLOYEE.TITLE-PROFILE' | translate)
          "
        >
          <dx-tabs
            class="employee-tabs"
            *ngIf="editingPersonId"
            width="100%"
            [selectedIndex]="selectedTab"
            [showNavButtons]="false"
            [dataSource]="tabs"
            (onItemClick)="onTabChange($event)"
          ></dx-tabs>
          <div [hidden]="selectedTab != 0">
            <dx-form
              #bottomForm
              labelMode="floating"
              [showColonAfterLabel]="false"
              [formData]="employeeForm"
            >
              <dxi-item itemType="group">
                <dxo-col-count-by-screen
                  [xs]="1"
                  [sm]="1"
                  [md]="1"
                  [lg]="2"
                ></dxo-col-count-by-screen>
                <dxi-item dataField="iddNumber" [editorOptions]="{ disabled: !isManager }">
                  <dxo-label
                    [text]="'COMMON.FIELDS.CERTIFICATION-CODE' | translate"
                  ></dxo-label>
                </dxi-item>
                <dxi-item dataField="agentNumber" [editorOptions]="{ disabled: !isManager }">
                  <dxo-label
                    [text]="'COMMON.FIELDS.EMPLOYEE-ID' | translate"
                  ></dxo-label>
                </dxi-item>
                <dxi-item dataField="phoneMobile">
                  <dxo-label
                    [text]="'COMMON.FIELDS.PHONE-MOBILE' | translate"
                  ></dxo-label>
                </dxi-item>
                <dxi-item dataField="street">
                  <dxo-label
                    [text]="'COMMON.FIELDS.STREET' | translate"
                  ></dxo-label>
                </dxi-item>
                <dxi-item dataField="zipCode">
                  <dxo-label
                    [text]="'COMMON.FIELDS.ZIP-CODE' | translate"
                  ></dxo-label>
                </dxi-item>
                <dxi-item dataField="city">
                  <dxo-label
                    [text]="'COMMON.FIELDS.CITY' | translate"
                  ></dxo-label>
                </dxi-item>
                <dxi-item
                  [editorOptions]="languagesOptions"
                  dataField="culture"
                  editorType="dxSelectBox"
                >
                  <dxo-label
                    [text]="'COMMON.FIELDS.LANGUAGE' | translate"
                  ></dxo-label>
                  <dxi-validation-rule
                    type="required"
                    [message]="'VALIDATIONS.LANGUAGE-REQUIRED' | translate"
                  ></dxi-validation-rule>
                </dxi-item>
                <dxi-item
                  [editorOptions]="timeZonesOptions"
                  dataField="timeZone"
                  editorType="dxSelectBox"
                >
                  <dxo-label
                    [text]="'COMMON.FIELDS.TIME-ZONE' | translate"
                  ></dxo-label>
                  <dxi-validation-rule
                    type="required"
                    [message]="'VALIDATIONS.TIME-ZONE-REQUIRED' | translate"
                  ></dxi-validation-rule>
                </dxi-item>
                <dxi-item
                  *ngIf="editingPersonId && isManager"
                  dataField="createdAt"
                  editorType="dxDateBox"
                  [editorOptions]="{ disabled: true }"
                >
                  <dxo-label
                    [text]="'COMMON.FIELDS.USER-CREATED' | translate"
                  ></dxo-label>
                </dxi-item>
                <dxi-item
                  *ngIf="editingPersonId && isManager"
                  dataField="accessGranted"
                  editorType="dxDateBox"
                  [editorOptions]="{ disabled: true }"
                >
                  <dxo-label
                    [text]="'COMMON.FIELDS.ACCESS-GRANTED' | translate"
                  ></dxo-label>
                </dxi-item>
              </dxi-item>
            </dx-form>
          </div>
          <div [hidden]="selectedTab != 1">
            <app-completed-event-table
              *ngIf="selectedTab == 1"
              [isManager]="isManager"
              [editingPersonId]="editingPersonId"
              [completedEvents]="completedEvents"
              (refreshEvents)="refreshEvents()"
            ></app-completed-event-table>
          </div>
          <div [hidden]="selectedTab != 2">
            <dx-form
              labelMode="floating"
              [showColonAfterLabel]="false"
              [formData]="employeeForm"
            >
              <dxi-item
                dataField="emailSignature"
                label=""
                editorType="dxHtmlEditor"
                [editorOptions]="{
                  label: '',
                  height: 350,
                  toolbar: {
                    items: [
                      'undo',
                      'redo',
                      'separator',
                      {
                        name: 'size',
                        acceptedValues: ['8pt', '10pt', '12pt', '14pt', '18pt']
                      },
                      { name: 'font', acceptedValues: ['Arial'] },
                      'separator',
                      'bold',
                      'italic',
                      'strike',
                      'underline',
                      'separator',
                      'alignLeft',
                      'alignCenter',
                      'alignRight',
                      'alignJustify',
                      'separator',
                      'orderedList',
                      'bulletList',
                      'separator',
                      {
                        name: 'header',
                        acceptedValues: [false, 1, 2, 3, 4, 5]
                      },
                      'separator',
                      'background',
                      'separator',
                      'link'
                    ]
                  }
                }"
              >
                <dxo-label text=" "></dxo-label>
              </dxi-item>
            </dx-form>
          </div>
        </app-panel>
      </div>
    </div>
  </app-page>
</div>
<app-training-overview-popup
  [isPopupVisible]="isOverviewPopupVisible"
  [personId]="editingPersonId"
  (onPopupClose)="onOverviewPopupClose()"
/>
<app-confirmation-dialog
  [(showModal)]="showModal"
  (submit)="modalSubmit()"
  [options]="modalOptions"
></app-confirmation-dialog>
