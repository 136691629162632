<dx-popup
  [maxHeight]="200"
  [maxWidth]="500"
  [hideOnOutsideClick]="true"
  [showCloseButton]="true"
  [(visible)]="isPopupVisible"
  [title]="'EMPLOYEE.FORM.TRAINING-OVERVIEW' | translate"
  [dragEnabled]="false"
  (onHidden)="onClose()"
  (onShowing)="onShow()"
>
  <div *dxTemplate="let data of 'content'">
    <div class="years-dropdown">
      <dx-drop-down-box
        [label]="'EMPLOYEE.FORM.YEARS' | translate"
        labelMode="outside"
        [(value)]="selectedYears"
        [inputAttr]="{ 'aria-label': 'Owner' }"
        valueExpr="id"
        displayExpr="name"
        [placeholder]="'EMPLOYEE.FORM.SELECT-YEARS' | translate"
        [dataSource]="years"
      >
        <div *dxTemplate="let data of 'content'">
          <dx-tree-view
            [searchEnabled]="true"
            [dataSource]="years"
            dataStructure="plain"
            keyExpr="id"
            selectionMode="multiple"
            showCheckBoxesMode="normal"
            [selectNodesRecursive]="false"
            displayExpr="name"
            [selectByClick]="true"
            (onItemSelectionChanged)="onYearsSelected($event)"
          >
          </dx-tree-view>
        </div>
      </dx-drop-down-box>
    </div>

    <div class="bottom-cta">
      <dx-button
        text="Download"
        type="normal"
        (click)="downloadOverview()"
      ></dx-button>
    </div>
  </div>
</dx-popup>
