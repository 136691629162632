<div #dateSelectionRoot tabindex="0">
  <div *ngFor="let date of dates">
    <div class="dates-wrapper">
      <div class="dates-date">
        <div class="dates-label">{{ "TRAINING.FORM.FROM" | translate }}:</div>
        <dx-date-box
          [label]="'COMMON.FIELDS.SELECT-DATE' | translate"
          labelMode="floating"
          stylingMode="outlined"
          (onFocusIn)="showDatePopup($event)"
          (onClosed)="onPopupClosed($event)"
          [readOnly]="true"
          [openOnFieldClick]="true"
          [value]="date.startDate.toJSDate()!"
          type="datetime"
          displayFormat="EEEE, d MMMM yyyy HH:mm"
          (onValueChanged)="onStartDateChange(date, $event)"
        >
        </dx-date-box>
      </div>
      <div class="dates-separator"></div>
      <div class="dates-date">
        <div class="dates-label">{{ "TRAINING.FORM.TO" | translate }}:</div>
        <dx-date-box
          [label]="'COMMON.FIELDS.SELECT-DATE' | translate"
          labelMode="floating"
          stylingMode="outlined"
          (onFocusIn)="showDatePopup($event)"
          (onFocusOut)="onPopupClosed($event)"
          [readOnly]="true"
          [value]="date.endDate.toJSDate()!"
          type="datetime"
          displayFormat="EEEE, d MMMM yyyy HH:mm"
          (onValueChanged)="onEndDateChange(date, $event)"
        >
        </dx-date-box>
      </div>
    </div>
    <div *ngIf="!isEndDate15MinutesAfterStartDate(date)" class="dates-error">
      {{ "VALIDATIONS.END-AFTER-START-15" | translate }}
    </div>
    <div *ngIf="onlyFutureDatesAllowed && !isInFuture(date)" class="dates-error">
      {{ "VALIDATIONS.START-END-IN-FUTURE" | translate }}
    </div>
  </div>
  <div class="bottom-cta">
    <div>
      <dx-button
        *ngIf="dates.length > 1"
        [text]="'TRAINING.FORM.REMOVE-DATE' | translate"
        type="default"
        (click)="onDateRemoveClick()"
      >
      </dx-button>
    </div>
    <!--   <dx-button
      [text]="'TRAINING.FORM.ADD-DATE' | translate"
      type="normal"
      (click)="onDateAddClick()"
    >
    </dx-button> -->
  </div>
</div>
