import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {filter, first, map, switchMap} from "rxjs/operators";
import {MsalBroadcastService, MsalService} from "@azure/msal-angular";
import {EventMessage, EventType} from "@azure/msal-browser";
import {AuthenticatedPersonModel} from "../models/person/authenticated-person.model";
import {LoadingService} from "./loading.service";
import {AuthenticationResult} from "@azure/msal-common";
import {Configuration} from "../../assets/Configuration";

@Injectable({
    providedIn: 'root'
})
export class AuthorizationService {

    private currentPersonSubject = new BehaviorSubject<AuthenticatedPersonModel | null>(null);
    private currentPerson$: Observable<AuthenticatedPersonModel> = this.currentPersonSubject.asObservable()
        .pipe(
            filter(person => person != null),
            map(person => person as AuthenticatedPersonModel)
        );
    private cachedPerson$?: Observable<AuthenticatedPersonModel> | null;
    private accessTokenSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
    public accessToken$ = this.accessTokenSubject.asObservable();
    private isAuthenticatedSubject = new BehaviorSubject<boolean>(false);
    public isAuthenticated$: Observable<boolean> = this.isAuthenticatedSubject.asObservable();


    constructor(private httpClient: HttpClient, private msalBroadcastService: MsalBroadcastService, private loadingService: LoadingService, private authService: MsalService) {

        this.msalBroadcastService.msalSubject$
            .pipe(
                filter((msg: EventMessage) => msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS),
                map((msg: EventMessage) => msg.payload as AuthenticationResult)
            ).subscribe(payload => {
            this.accessTokenSubject.next(payload.accessToken);
            this.isAuthenticatedSubject.next(true);
        });

        this.msalBroadcastService.msalSubject$
            .pipe(
                filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
            )
            .subscribe((currentPersonResult) => this.currentPersonSubject.next(null));
    }

    public getCurrentPerson(): Observable<AuthenticatedPersonModel> {
        if (this.currentPersonSubject.value == null) {
            this.loadingService.load(this.httpClient.get<AuthenticatedPersonModel>(Configuration.getApiBaseUrl() + "/person/current"))
                .subscribe(currentPersonResult => this.currentPersonSubject.next(currentPersonResult));
        }
        return this.currentPerson$;
    }

    public reloadCurrentPerson(): void {
        this.loadingService.load(this.httpClient.get<AuthenticatedPersonModel>(Configuration.getApiBaseUrl() + "/person/current"))
            .subscribe(currentPersonResult => this.currentPersonSubject.next(currentPersonResult));
    }

    public changePassword(data: { password: string }) {
        return this.currentPerson$.pipe(first(), switchMap((person) =>
            this.loadingService.load(
                this.httpClient.post<AuthenticatedPersonModel>(
                    Configuration.getApiBaseUrl() + "/User/change-password",
                    {
                        personId: person.person.id,
                        newPassword: data.password
                    },
                )
            )
        ))
    }

    logout(): void {
        this.authService.logout();
        this.accessTokenSubject.next(null);
        this.isAuthenticatedSubject.next(false);
    }
}
