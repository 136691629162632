<div>
  <app-page>
    <div class="top-cta">
      <dx-button
        [text]="'COMMON.BACK' | translate"
        icon="back"
        type="default"
        class="integration-btn"
        (click)="onBackClick()"
      >
      </dx-button>

      <dx-drop-down-button
        [splitButton]="true"
        [text]="'MY-TRAININGS.TRAINING-INVITE.SEND-INVITATIONS' | translate"
        stylingMode="contained"
        type="normal"
        [dropDownOptions]="{ width: 230 }"
        (onButtonClick)="onSendClick()"
      >
      </dx-drop-down-button>
    </div>

    <app-panel [title]="'MY-TRAININGS.TRAINING-INVITE.RECIPIENTS' | translate">
      <dx-form [formData]="formData" labelLocation="top" #topForm>
        <dxi-item dataField="to">
          <dxo-label
            [text]="'MY-TRAININGS.TRAINING-INVITE.TO' | translate"
          ></dxo-label>
          <div *dxTemplate>
            <app-invite-person-select-dialog
              (onSubmit)="addAttendeesHandler($event)"
            >
              <dx-tag-box
                [showSelectionControls]="true"
                applyValueMode="useButtons"
                tagTemplate="customTag"
                class="no-events"
                [(value)]="formData.to"
              >
                <div *dxTemplate="let product of 'customItem'">
                  <div class="custom-item">
                    <div class="product-name">
                      {{ product.lastName }},
                      {{ product.firstName }}
                    </div>
                  </div>
                </div>
                <div *dxTemplate="let product of 'customTag'">
                  <div class="dx-tag-content">
                    <div>
                      {{ product.lastName }},
                      {{ product.firstName }}
                    </div>
                    <div class="dx-tag-remove-button"></div>
                  </div>
                </div>
              </dx-tag-box>
            </app-invite-person-select-dialog>
          </div>
        </dxi-item>
        <dxi-item>
          <dxo-label
            [text]="'COMMON.UPLOAD-ATTACHMENTS' | translate"
          ></dxo-label>
          <div *dxTemplate>
            <dx-file-uploader
              [visible]="false"
              dialogTrigger="#fileTrigger"
              [multiple]="true"
              uploadMode="useForm"
              (valueChange)="onSelectedFileChange($event)"
              [showFileList]="true"
            >
            </dx-file-uploader>
            <dx-button
              id="fileTrigger"
              [text]="'COMMON.SELECT-FILE' | translate"
              type="default"
              class="integration-btn"
            ></dx-button>
            <span *ngIf="!formData.files.length" class="no-file-text">
              {{ 'COMMON.FILE-NOT-SELECTED' | translate }}
            </span>
            <div *ngIf="formData.files.length">
              <dx-list
                [width]="'50%'"
                noDataText=""
                [dataSource]="formData.files"
                keyExpr="name"
                displayExpr="name"
              ></dx-list>
            </div>
          </div>
        </dxi-item>
      </dx-form>
    </app-panel>
    <app-panel [title]="'MY-TRAININGS.TRAINING-INVITE.CONTENT' | translate">
      <dx-form #bottomForm [formData]="formData" labelMode="floating">
        <dxi-item
          dataField="subject"
          [label]="'MY-TRAININGS.TRAINING-INVITE.SUBJECT' | translate"
        >
          <dxi-validation-rule
            type="required"
            [message]="
              'MY-TRAININGS.TRAINING-INVITE.SUBJECT-REQUIRED' | translate
            "
          ></dxi-validation-rule>
        </dxi-item>

        <dxi-item>
          <dxo-label
            [text]="'MY-TRAININGS.TRAINING-INVITE.TEXT' | translate"
          ></dxo-label>
          <div *dxTemplate>
            <dx-html-editor
              class="html-editor"
              height="200px"
              [value]="formData.body"
              (valueChange)="formData.body = $event"
              [placeholder]="'MY-TRAININGS.TRAINING-INVITE.TEXT' | translate"
            >
              <dxo-toolbar [multiline]="true">
                <dxi-item name="undo"></dxi-item>
                <dxi-item name="redo"></dxi-item>
                <dxi-item name="separator"></dxi-item>
                <dxi-item
                  name="size"
                  [acceptedValues]="[
                    '8pt',
                    '10pt',
                    '12pt',
                    '14pt',
                    '18pt',
                    '24pt',
                    '36pt'
                  ]"
                ></dxi-item>
                <dxi-item name="separator"></dxi-item>
                <dxi-item name="bold"></dxi-item>
                <dxi-item name="italic"></dxi-item>
                <dxi-item name="strike"></dxi-item>
                <dxi-item name="underline"></dxi-item>
                <dxi-item name="separator"></dxi-item>
                <dxi-item name="alignLeft"></dxi-item>
                <dxi-item name="alignCenter"></dxi-item>
                <dxi-item name="alignRight"></dxi-item>
                <dxi-item name="alignJustify"></dxi-item>
                <dxi-item name="separator"></dxi-item>
                <dxi-item name="orderedList"></dxi-item>
                <dxi-item name="bulletList"></dxi-item>
                <dxi-item name="separator"></dxi-item>
                <dxi-item
                  name="header"
                  [acceptedValues]="[false, 1, 2, 3, 4, 5]"
                ></dxi-item>
                <dxi-item name="separator"></dxi-item>
                <dxi-item name="color"></dxi-item>
                <dxi-item name="background"></dxi-item>
                <dxi-item name="separator"></dxi-item>
                <dxi-item name="link"></dxi-item>
              </dxo-toolbar>
            </dx-html-editor>
          </div>
        </dxi-item>
      </dx-form>
    </app-panel>
  </app-page>
</div>
