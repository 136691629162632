<dx-popup
  [width]="530"
  [height]="510"
  [showTitle]="true"
  [title]="'TRAINING.CANCEL-TRAINING-DIALOG.CANCEL-TRAINING' | translate"
  [dragEnabled]="false"
  [hideOnOutsideClick]="true"
  [showCloseButton]="true"
  class="modal"
  [classList]="'modal'"
  [copyRootClassesToWrapper]="false"
  [visible]="showModal"
  (onHidden)="dismiss()"
>
  <div *dxTemplate="let data of 'content'">
    {{ formData.infoCheckbox }}
    <div class="modal-message">
      {{
        'TRAINING.CANCEL-TRAINING-DIALOG.THIS-EVENT-HAS-PARTICIPANTS-THAT-ALREADY-ACCEPTED'
          | translate
      }}
      <br />
      {{
        'TRAINING.CANCEL-TRAINING-DIALOG.ARE-YOU-SURE-YOU-WANT-TO-CONTINUE-WITH-THESE-CHANGES'
          | translate
      }}
    </div>
    <div>
      <dx-form [formData]="formData" labelLocation="top">
        <dxi-item
          dataField="infoCheckbox"
          editorType="dxCheckBox"
          [editorOptions]="{
            text:  'TRAINING.CANCEL-TRAINING-DIALOG.SEND-AU-UPDATE-TO-ALL-PARTICIPANTS' | translate,
          }"
        >
          <dxo-label [visible]="false"></dxo-label>
        </dxi-item>
        <dxi-item
          dataField="infoInput"
          editorType="dxTextArea"
          [editorOptions]="{
            placeholder:
              'TRAINING.CANCEL-TRAINING-DIALOG.NOTE-TO-PARTICIPENT-OPTIONAL'
              | translate,
            height: 150,
            disabled: !formData.infoCheckbox
          }"
        >
          <dxo-label
            [text]="'TRAINING.CANCEL-TRAINING-DIALOG.NOTE' | translate"
          ></dxo-label>
        </dxi-item>
      </dx-form>
    </div>
  </div>

  <dxi-toolbar-item
    widget="dxButton"
    toolbar="bottom"
    location="before"
    [options]="{
      type: 'default',
      text: 'COMMON.NO' | translate,
      onClick: dismiss
    }"
  ></dxi-toolbar-item>
  <dxi-toolbar-item
    widget="dxButton"
    toolbar="bottom"
    location="after"
    [options]="{
      stylingMode: 'contained',
      text: 'COMMON.YES' | translate,
      onClick: onSubmit
    }"
  ></dxi-toolbar-item>
</dx-popup>
