import { Component, Input } from '@angular/core';
import { clockIcon, calendarIcon, playIcon, categoryIcon, bookmarkIcon } from '../../app-icons';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-training-card',
  templateUrl: './training-card.component.html',
  styleUrls: ['./training-card.component.scss'],
})
export class TrainingCardComponent {
  @Input()
  training!: any;

  @Input()
  isCatalogue: boolean = false;

  @Input()
  categoryId: string = '';

  getCategoryIcon() {
    return this.sanitized.bypassSecurityTrustHtml(categoryIcon);
  }

  getClockIcon() {
    return this.sanitized.bypassSecurityTrustHtml(clockIcon);
  }

  getCalendarIcon() {
    return this.sanitized.bypassSecurityTrustHtml(calendarIcon);
  }

  getPlayIcon() {
    return this.sanitized.bypassSecurityTrustHtml(playIcon);
  }

  getBookmarkIcon() {
    return this.sanitized.bypassSecurityTrustHtml(bookmarkIcon);
  }

  getCardLink() {
    if (!this.isCatalogue) {
      return `/my-trainings/${this.training.type.toLowerCase()}/${this.training.id}`;
    } else {
      return `/training-catalogue/${this.categoryId}/${this.training.type.toLowerCase()}/${this.training.id}`
    }
  }

  constructor(
    private sanitized: DomSanitizer,
    private router: Router,
    public translateService: TranslateService
  ) {}

  // navigateToDetails() {
  //   if (!this.isCatalogue) {
  //     this.router.navigate([
  //       `/my-trainings/${this.training.type.toLowerCase()}`,
  //       this.training.id,
  //     ]);
  //   } else {
  //     this.router.navigate([
  //       `/training-catalogue/${this.categoryId}/${this.training.type.toLowerCase()}/${this.training.id}`
  //     ]);
  //   }
  // }
}
