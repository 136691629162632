export enum EventTypeModel
{
  Template = -1,
  Normal = 0,
  Voting = 1,
  Info = 2,
  External = 3,
  Webinar = 4,
  GutBeraten = 5
}
