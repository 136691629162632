import { Component, OnInit, ViewChild } from '@angular/core';
import { DxFormComponent } from 'devextreme-angular';
import { DxSelectBoxTypes } from 'devextreme-angular/ui/select-box';
import { filter, map, of, switchMap, tap } from 'rxjs';
import { TrainingTypeModel } from 'src/app/models/training/training-type.model';
import { TrainingGroupCreationAndUpdateModel } from 'src/app/models/training-group-creation-and-update.model';
import { TrainingGroupService } from 'src/app/services/training-group.service';
import { TrainingGroupModel } from 'src/app/models/training-group.model';
import { LoadingService } from 'src/app/services/loading.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TimeService } from 'src/app/services/time.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-training-groups-details',
  templateUrl: './training-groups-details.component.html',
  styleUrl: './training-groups-details.component.scss',
})
export class TrainingGroupsDetailsComponent implements OnInit {
  editingMode = false;
  currentTrainingGroup: TrainingGroupModel | null = null;
  trainingTypeModelIDD = TrainingTypeModel.IDD;
  titleAndDescFormData = {
    name: '',
    description: '',
  };
  generalFormData!: {
    trainingType: number | null;
    useDefaultQualificationTarget: boolean;
    qualificationTargetHours: number;
    qualificationTargetMinutes: number;
  };
  defaultSelectOptions = {
    showClearButton: true,
    stylingMode: 'outlined',
    displayExpr: 'name',
    valueExpr: 'id',
  } as DxSelectBoxTypes.Properties;
  trainingTypes: DxSelectBoxTypes.Properties = {
    ...this.defaultSelectOptions,
    dataSource: [
      {
        id: TrainingTypeModel.IDD,
        name: this.translateService.instant('COMMON.FIELDS.TRAINING-TYPES.IDD'),
      },
      {
        id: TrainingTypeModel.Compliance,
        name: this.translateService.instant(
          'COMMON.FIELDS.TRAINING-TYPES.COMPLIANCE'
        ),
      },
      {
        id: TrainingTypeModel.General,
        name: this.translateService.instant(
          'COMMON.FIELDS.TRAINING-TYPES.GENERAL'
        ),
      },
    ],
    placeholder: '',
  };

  @ViewChild('titleAndDescForm', { static: false })
  titleAndDescForm!: DxFormComponent;
  @ViewChild('generalForm', { static: false }) generalForm!: DxFormComponent;

  constructor(
    private trainingGroupService: TrainingGroupService,
    private loadingService: LoadingService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toastrService: ToastrService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params
      .pipe(
        map((params) => params['id']),
        filter((id) => !!id),
        tap((id) => {
          this.editingMode = id !== 'new';
        }),
        switchMap((id) => {
          return this.loadingService.load(
            this.editingMode
              ? this.trainingGroupService.getTrainingGroup(id)
              : of(null)
          );
        })
      )
      .subscribe((result) => {
        this.currentTrainingGroup = result;
        if (!result) {
          this.generalFormData = {
            trainingType: null,
            useDefaultQualificationTarget: true,
            qualificationTargetHours: 0,
            qualificationTargetMinutes: 0,
          };
          return;
        }

        const qualificationTargetHours =
          TimeService.getHoursFromAbsoluteMinutes(
            result.qualificationTargetIddTimeMinutes || 0
          );
        const qualificationTargetMinutes =
          TimeService.getMinutesFromAbsoluteMinutes(
            result.qualificationTargetIddTimeMinutes || 0
          );

        this.titleAndDescFormData.name = result.name;
        this.titleAndDescFormData.description = result.description || '';
        this.generalFormData = {
          trainingType: result.trainingType,
          useDefaultQualificationTarget: result.useDefaultQualificationTarget,
          qualificationTargetHours,
          qualificationTargetMinutes,
        };
      });
  }

  handleTimePicker(value: { hours: number; minutes: number }) {
    this.generalFormData.qualificationTargetHours = value.hours;
    this.generalFormData.qualificationTargetMinutes = value.minutes;
  }

  submit() {
    const isTitleAndDescFormValid =
      this.titleAndDescForm.instance.validate().isValid;
    const isGeneralFormValid = this.generalForm.instance.validate().isValid;
    if (!isTitleAndDescFormValid || !isGeneralFormValid) {
      return;
    }

    let qualificationTargetIddTimeMinutes: number | null = null;
    const trainingType = this.generalFormData.trainingType;
    if (typeof trainingType !== 'number') return;
    if (trainingType === TrainingTypeModel.IDD) {
      qualificationTargetIddTimeMinutes =
        this.generalFormData.qualificationTargetHours * 60 +
        this.generalFormData.qualificationTargetMinutes;
    }

    const model: TrainingGroupCreationAndUpdateModel = {
      name: this.titleAndDescFormData.name,
      trainingType: trainingType,
      description: this.titleAndDescFormData.description,
      qualificationTargetIddTimeMinutes: qualificationTargetIddTimeMinutes,
      useDefaultQualificationTarget:
        this.generalFormData.useDefaultQualificationTarget,
    };

    if (this.editingMode && this.currentTrainingGroup) {
      const obs = this.trainingGroupService.updateTrainingGroup(
        this.currentTrainingGroup.id,
        model
      );
      this.loadingService.load(obs).subscribe(() => {
        this.toastrService.success('Training group saved');
        this.navigateBack();
      });
    } else {
      const obs = this.trainingGroupService.addTrainingGroup(model);
      this.loadingService.load(obs).subscribe(() => {
        this.toastrService.success('Training group added');
        this.navigateBack();
      });
    }
  }

  navigateBack(): void {
    this.router.navigate(['administration']);
  }
}
