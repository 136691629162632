<dx-drop-down-box
  [inputAttr]="{ 'aria-label': 'Owner' }"
  placeholder="Training time"
  [value]="viewTime"
  [dataSource]="[viewTime]"
  class="time-counter"
  [readOnly]="disabled"
  [validationErrors]="[{ message: 'VALIDATIONS.TRAINING-TIME-MINUTE' | translate }]"
  [validationStatus]="validationStatus"
>
  <div *dxTemplate="let data of 'content'" class="qwe">
    <dx-form
      labelLocation="top"
      [showColonAfterLabel]="false"
      [formData]="formData"
      (onFieldDataChanged)="setTimeView()"
    >
      <dxi-item itemType="group" [colCount]="2">
        <dxi-item
          editorType="dxNumberBox"
          [editorOptions]="{
            showSpinButtons: true,
            min: 0
          }"
          dataField="hours"
        >
          <dxo-label text="Hours"></dxo-label>
        </dxi-item>
        <dxi-item
          editorType="dxNumberBox"
          [editorOptions]="{
            showSpinButtons: true,
            min: 0,
            max: 59
          }"
          dataField="minutes"
        >
          <dxo-label text="Minutes"></dxo-label>
        </dxi-item>
      </dxi-item>
    </dx-form>
  </div>
</dx-drop-down-box>
