<!-- <div class="training-category-card" (click)="navigateToDetails()"> -->
<a class="training-category-card" [routerLink]="[getCardLink()]">
  <img
    class="training-category-image"
    [src]="category.trainingCategory.imageUrl"
    appTrainingImageFallback
  />
  <div class="training-category-details">
    <div>
      <h2 class="training-category-name">{{ category.trainingCategory.name }}</h2>
      <div class="training-category-description">
        {{ category.trainingCategory.description }}
      </div>
    </div>
    <div>
      <div class="training-category-badge">
        <div class="training-category-badge-container">
          <div class="training-category-badge-content">
            <i
              class="dx-icon dx-svg-icon play-icon"
              [innerHTML]="getCategoryIcon()"
            ></i>
            {{ category.trainings.length }}
            {{ "TRAINING-CATALOGUE.OVERVIEW.TRAININGS" | translate }}
          </div>
        </div>
        <div class="training-category-badge-container">
          <div class="training-category-badge-content">
            <i
              class="dx-icon dx-svg-icon play-icon"
              [innerHTML]="getPlayIcon()"
            ></i>
            {{ category.courses.length }}
            {{ "TRAINING-CATALOGUE.OVERVIEW.COURSES" | translate }}
          </div>
        </div>
      </div>
    </div>
  </div>
</a>
<!-- </div> -->
