import {Component} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {Observable} from "rxjs";
import {CourseTemplateFullModel} from "../../../models/course/course-template-full.model";
import {CourseTemplateService} from "../../../services/course-template.service";
import {AsyncPipe, NgIf} from "@angular/common";
import {LoadingService} from "../../../services/loading.service";

@Component({
    selector: 'app-course-completed',
    standalone: true,
    imports: [
        NgIf,
        AsyncPipe
    ],
    templateUrl: './course-completed.component.html',
    styleUrl: './course-completed.component.css'
})
export class CourseCompletedComponent {
    courseTemplateId: string;
    course$: Observable<CourseTemplateFullModel>;

    constructor(private courseTemplateService: CourseTemplateService, private router: Router, private route: ActivatedRoute, private loadingService: LoadingService) {
        this.courseTemplateId = this.route.snapshot.params['id'];
        this.course$ = this.loadingService.load(courseTemplateService.getCourseTemplate(this.courseTemplateId));
    }
}
