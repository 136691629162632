import {Component, OnDestroy} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {GeneralSettingsService} from 'src/app/services/general-settings.service';
import {LoadingService} from 'src/app/services/loading.service';
import {AuthorizationService} from "../../services/authorization.service";
import {of, Subscription} from "rxjs";
import {switchMap, tap} from "rxjs/operators";

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnDestroy {
    isDataLoaded: boolean = false;
    company: string | null = null;
    companyBackgroundUrl: string | null = null;
    subs = new Subscription();

    constructor(
        private route: ActivatedRoute,
        private generalSettingsService: GeneralSettingsService,
        private loadingService: LoadingService,
        private router: Router,
        private authorizationService: AuthorizationService
    ) {
        this.company = route.snapshot.params['company'];

        const sub = this.authorizationService.isAuthenticated$.pipe(
            switchMap(isAuthenticated => {
                if (isAuthenticated) {
                    this.router.navigateByUrl('/dashboard');
                    return of(null);
                } else if (!isAuthenticated && this.company) {
                    return this.loadingService.load(this.generalSettingsService.getLoginImage(this.company!))
                } else {
                    return of(null)
                }
            })
        ).subscribe((image) => {
            this.companyBackgroundUrl = image;
            this.isDataLoaded = true;
        });
        this.subs.add(sub);

    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    onLoginClick(): void {
        this.router.navigate(['dashboard']);
    }
}
