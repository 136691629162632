<div>
  <app-page>
    <div class="top-cta">
      <dx-button
        [text]="'COMMON.BACK' | translate"
        icon="back"
        type="default"
        class="integration-btn"
        (click)="navigateBack()"
      >
      </dx-button>
      <dx-button
        class="cta-btn"
        type="normal"
        text="{{ 'COMMON.SAVE' | translate }}"
        (click)="submit()"
      ></dx-button>
    </div>
    <app-page-title
      [title]="
        editingMode
          ? ('ADMINISTRATION.GROUP-TITLE-EDIT' | translate)
          : ('ADMINISTRATION.GROUP-TITLE-ADD' | translate)
      "
    ></app-page-title>
    <div>
      <div class="row">
        <div class="col-7">
          <app-panel [title]="'ADMINISTRATION.TITLE-DESCRIPTION' | translate">
            <dx-form
              #titleAndDescForm
              labelMode="floating"
              [showColonAfterLabel]="false"
              [formData]="titleAndDescFormData"
            >
              <dxi-item dataField="name">
                <dxo-label
                  [text]="'COMMON.FIELDS.TITLE' | translate"
                ></dxo-label>
                <dxi-validation-rule
                  type="required"
                  [message]="'VALIDATIONS.TITLE-REQUIRED' | translate"
                ></dxi-validation-rule>
              </dxi-item>
              <dxi-item
                dataField="description"
                editorType="dxTextArea"
                [editorOptions]="{
                  height: 140
                }"
              >
                <dxo-label
                  [text]="'COMMON.FIELDS.DESCRIPTION' | translate"
                ></dxo-label>
              </dxi-item>
            </dx-form>
          </app-panel>
        </div>
        <div class="col-5">
          <app-panel [title]="'ADMINISTRATION.GENERAL' | translate">
            <dx-form
              *ngIf="generalFormData"
              #generalForm
              labelMode="floating"
              [showColonAfterLabel]="false"
              [formData]="generalFormData"
            >
              <dxi-item
                [editorOptions]="trainingTypes"
                dataField="trainingType"
                editorType="dxSelectBox"
              >
                <dxo-label
                  [text]="'COMMON.FIELDS.TRAINING-TYPE' | translate"
                ></dxo-label>
                <dxi-validation-rule
                  type="required"
                  [message]="'VALIDATIONS.TRAINING-TYPE-REQUIRED' | translate"
                ></dxi-validation-rule>
              </dxi-item>
              <!-- <dxi-item
                [colCount]="2"
                itemType="group"
                *ngIf="generalFormData.trainingType === trainingTypeModelIDD"
              >
                <dxi-item>
                  <dxo-label
                    [text]="'COMMON.FIELDS.TRAINING-TIME' | translate"
                  ></dxo-label>
                  <div *dxTemplate>
                    <app-time-picker
                      [isInvalid]="false"
                      [disabled]="generalFormData.useDefaultQualificationTarget"
                      (timeChanged)="handleTimePicker($event)"
                      [defaultValue]="{
                        hours: generalFormData.qualificationTargetHours,
                        minutes: generalFormData.qualificationTargetMinutes
                      }"
                    ></app-time-picker>
                  </div>
                </dxi-item>
              </dxi-item>
              <dxi-item
                *ngIf="generalFormData.trainingType === trainingTypeModelIDD"
                editorType="dxCheckBox"
                dataField="useDefaultQualificationTarget"
                [editorOptions]="{
                    text: 'ADMINISTRATION.LINK-TO-DEFAULT' | translate,
                  }"
              >
                <dxo-label [visible]="false"> </dxo-label>
              </dxi-item> -->
            </dx-form>
          </app-panel>
        </div>
      </div>
    </div>
  </app-page>
</div>
