import { Component, OnInit, ViewChild } from '@angular/core';
import { forkJoin, from, Subscription, switchMap } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { TrainingService } from '../../../services/training.service';
import { LoadingService } from '../../../services/loading.service';
import { PersonModel } from '../../../models/person/person.model';
import { RawFileModel } from '../../../models/raw-file-model';
import { FileUtilService } from '../../../services/file-util.service';
import { TrainingMessageSendModel } from '../../../models/training/training-message-send.model';
import { ToastrService } from 'ngx-toastr';
import { PersonSummaryModel } from '../../../models/person/person-summary.model';
import { DxFormComponent } from 'devextreme-angular';
import { CourseTemplateFullModel } from 'src/app/models/course/course-template-full.model';
import { CourseTemplateService } from 'src/app/services/course-template.service';
import { CourseTemplateMessageService } from 'src/app/services/course-template-message.service';

@Component({
  selector: 'app-elearning-message',
  templateUrl: './elearning-message.component.html',
  styleUrl: './elearning-message.component.scss'
})
export class ElearningMessageComponent implements OnInit {
  courseTemplateId!: string;
  isInvite: boolean = false;

  courseTemplate: CourseTemplateFullModel | null = null;
  attachments: RawFileModel[] = [];

  formData: {
    to: (PersonModel | PersonSummaryModel)[];
    subject: string;
    body: string;
    files: File[];
  } = {
    to: [],
    subject: '',
    body: '',
    files: []
  };

  private subs = new Subscription();

  @ViewChild('topForm') topForm!: DxFormComponent;
  @ViewChild('bottomForm') bottomForm!: DxFormComponent;

  constructor(
    private route: ActivatedRoute,
    private trainingService: TrainingService,
    private courseTemplateService: CourseTemplateService,
    private loadingService: LoadingService,
    private router: Router,
    private fileUtilService: FileUtilService,
    private courseTemplateInvitationService: CourseTemplateMessageService,
    private toastrService: ToastrService
  ) {}

  ngOnInit(): void {
    this.courseTemplateId = this.route.snapshot.params['id'];
    this.isInvite =
      this.route.snapshot.url.filter(url => url.toString() == 'invite').length >
      0;
    const obs = forkJoin({
      courseTemplateFull: this.courseTemplateService.getCourseTemplate(this.courseTemplateId),
      members: this.trainingService.getEventMembers(this.courseTemplateId)
    });
    const sub = this.loadingService.load(obs).subscribe(t => {
      this.courseTemplate = t.courseTemplateFull;

      this.formData.subject = t.courseTemplateFull.courseTemplate.emailSubject
        ? t.courseTemplateFull.courseTemplate.emailSubject
        : t.courseTemplateFull.courseTemplate.name;
      this.formData.body = t.courseTemplateFull.courseTemplate.emailBody
        ? t.courseTemplateFull.courseTemplate.emailBody
        : t.courseTemplateFull.courseTemplate.description || '';
      if (!this.isInvite) {
        this.formData.to = t.members.map(em => em.person);
      }
    });
    this.subs.add(sub);
  }

  onInvitersChange(e: any) {
    this.formData.to = e.component.getSelectedNodeKeys();
  }

  onSelectedFileChange(files: File[]) {
    this.formData.files = files;
  }

  onBackClick(): void {
    this.router.navigate(['manage-elearning', this.courseTemplateId]);
  }

  onSendClick() {
    const isTopFormValid = this.topForm.instance.validate().isValid;
    const isBottomFormValid = this.bottomForm.instance.validate().isValid;

    if (!this.formData.to.length) {
      this.toastrService.error('Attendees are required');
      return;
    }
    if (!isTopFormValid || !isBottomFormValid) {
      this.toastrService.error('Values are not valid');
      return;
    }

    const attachmentsPromises = Promise.all(
      this.formData.files.map(file => {
        return this.fileUtilService.convertFileToRawFileModel(file);
      })
    );

    from(attachmentsPromises)
      .pipe(
        switchMap(attachments => {
          const model: TrainingMessageSendModel = {
            toPersonIds: this.formData.to
              ? this.formData.to.map(p => p.id)
              : [],
            subject: this.formData.subject,
            htmlBody: this.formData.body,
            attachments
          };
          const obs = this.isInvite
            ? this.courseTemplateInvitationService.sendInvitation(
                this.courseTemplateId,
                model
              )
            : this.courseTemplateInvitationService.sendInfo(this.courseTemplateId, model);

          return this.loadingService.load(obs);
        })
      )
      .subscribe({
        next: () => {
          this.toastrService.success('Erfolgreich versendet');
          this.router.navigate(['manage-elearning', this.courseTemplateId]);
        },
        error: () => {
          this.toastrService.error('Es ist ein Fehler aufgetreten');
        }
      });
  }

  addAttendeesHandler(persons: PersonModel[]) {
    this.formData.to = persons;
  }
}
