import enMessages from 'devextreme/localization/messages/en.json';
import deMessages from 'devextreme/localization/messages/de.json';
import { Component, OnInit } from '@angular/core';
import { Event, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { loadMessages, locale } from 'devextreme/localization';
import { DxTreeViewTypes } from 'devextreme-angular/ui/tree-view';
import { DxDrawerTypes } from 'devextreme-angular/ui/drawer';
import { AuthorizationService } from './services/authorization.service';
import { AuthenticatedPersonModel } from './models/person/authenticated-person.model';
import { supportIcon } from './app-icons';
import { PersonModel } from './models/person/person.model';
import { TranslateService } from '@ngx-translate/core';
import { StorageKey, StorageService } from './services/storage.service';
import { filter, map, shareReplay, switchMap } from 'rxjs/operators';
import { AppInsightsService } from './services/app-insights.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { AngularFaviconService } from 'angular-favicon';
import config from 'devextreme/core/config';
import { licenseKey } from '../devextreme-license';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  userRole: string = '';
  selectedRoute = '';
  menuOpened: boolean = true;
  menuMode: DxDrawerTypes.OpenedStateMode = 'shrink';
  menuRevealMode: DxDrawerTypes.RevealMode = 'slide'; //'slide';
  minMenuSize = 0;
  shaderEnabled = false;
  isIframe = false;
  user: PersonModel | null = null;
  logoUrl: string | null = null;
  currentPerson$ = new Subject<AuthenticatedPersonModel>();
  isAnonymousRoute: boolean = false;
  anonymousRoutes: string[] = ['/feedback/v2', '/Feedback/v2', '/my-invitation', '/login'];
  lastCheckedBuildTime: number | null = null;

  get supportIcon() {
    return this.sanitized.bypassSecurityTrustHtml(supportIcon);
  }

  get userName() {
    return `${this.user!.firstName} ${this.user!.lastName}`;
  }

  constructor(
    private router: Router,
    private authorizationService: AuthorizationService,
    private sanitized: DomSanitizer,
    private storageService: StorageService,
    private breakpointObserver: BreakpointObserver,
    private appInsights: AppInsightsService,
    private ngxFavicon: AngularFaviconService,
    translate: TranslateService,
    private snackBar: MatSnackBar
  ) {
    config({ licenseKey });

    this.currentPerson$.subscribe((result) => {
      translate.use(result.person.culture).subscribe(() => {
        if (result.person.culture == 'en') {
          loadMessages(enMessages);
          locale('en-EN');
        } else if (result.person.culture == 'de') {
          loadMessages(deMessages);
          locale('de-DE');
        }

        this.userRole = result.person.role.name;
        this.user = result.person;
        this.logoUrl = result.logoUrl;
      });
    });

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.selectedRoute = event.urlAfterRedirects.split('?')[0];
      }
    });

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationStart),
        map((event) => event as NavigationStart),
        filter((navigationStartEvent) => {
          this.isAnonymousRoute = this.anonymousRoutes.some((anonymousRoute) =>
            navigationStartEvent.url.includes(anonymousRoute)
          ) || navigationStartEvent.url == '/';

          if (this.isAnonymousRoute) {
            // @ts-ignore
            const userLanguage = navigator.language || navigator.userLanguage;
            const culture = userLanguage.split('-')[0];

            translate.use(culture).subscribe(() => {
              if (userLanguage === 'de-DE') {
                loadMessages(deMessages);
                locale('de-DE');
              } else {
                loadMessages(enMessages);
                locale('en-EN');
              }
            });
          }

          return !this.isAnonymousRoute;
        }),
        switchMap(() => this.authorizationService.getCurrentPerson()),
        shareReplay(1)
      )
      .subscribe((person) => this.currentPerson$.next(person));

    this.breakpointObserver
      .observe('(min-width: 1441px)')
      .subscribe((result) => {
        this.handleScreenWidth(result.breakpoints['(min-width: 1441px)']);
      });
  }

  handleScreenWidth(isLargeScreen: boolean) {
    if (isLargeScreen) {
      this.shaderEnabled = false;
      this.menuOpened = true;
    } else {
      this.shaderEnabled = true;
      this.menuOpened = false;
    }
  }

  closeDrawer() {
    if (this.shaderEnabled) {
      this.menuOpened = false;
    }
  }

  ngOnInit() {
    this.isIframe = window !== window.parent && !window.opener;
    this.ngxFavicon.setFavicon('assets/favicon.ico', 'assets/favicon_dark.ico');
    this.checkForUpdates();
  }

  navigationChanged(event: DxTreeViewTypes.ItemClickEvent) {
    const path = (event.itemData as any).path;

    if (path == '/training') {
      this.storageService.set(StorageKey.TRAINING_OVERVIEW_TAB_INDEX, '0');
    }
    if (path) {
      this.router.navigate([path]);
    }
  }

  
  private checkForUpdates() {
    setInterval(() => {
      fetch('assets/version.json?' + Date.now())
        .then(response => response.json())
        .then(data => {
          if (this.lastCheckedBuildTime && data.lastBuildTime > this.lastCheckedBuildTime) {
            const snackBar = this.snackBar.open(
              'A new version is available!', 
              'Update now', 
              {
                duration: 0,
                horizontalPosition: 'center',
                verticalPosition: 'bottom',
              }
            );
            snackBar.onAction().subscribe(() => {
              window.location.reload();
            });
          }
          this.lastCheckedBuildTime = data.lastBuildTime;
          console.log('Last checked build time:', this.lastCheckedBuildTime);
        });
    }, 10000);
  }
}
