import { Component } from '@angular/core';
import {Configuration} from "../../../assets/Configuration";
import { Subscription, filter, map } from 'rxjs';
import { AuthorizationService } from 'src/app/services/authorization.service';
import DataSource from 'devextreme/data/data_source';
import { url } from 'inspector';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss'],
})
export class TestComponent {

  private subs = new Subscription();
  uploadUrl = Configuration.getApiBaseUrl() + "/fileuploader";
  accessToken: string = "";

  constructor(private authorizationService: AuthorizationService) {

    const sub = this.authorizationService.accessToken$
      .pipe(
        filter((accessToken: string | null) => accessToken != null),
        map((accessToken: string | null) => accessToken as string)
      )
      .subscribe((accessToken) => {
        this.accessToken = accessToken
      });
      this.subs.add(sub);
  }

  onBeforeSend(e: any, accessToken: string): any {
    e.request.setRequestHeader('Authorization', 'Bearer ' + accessToken);
}

  onUploadStarted(e: any) {
    this.getChunkPanel()!.innerHTML = '';
  }

  onUploadProgress(e: any) {
    this.getChunkPanel()!.appendChild(this.addChunkInfo(e.segmentSize, e.bytesLoaded, e.bytesTotal));
  }

  addChunkInfo(segmentSize: any, loaded: any, total: any) {
    const result = document.createElement('DIV');

    result.appendChild(this.createSpan('Chunk size:'));
    result.appendChild(this.createSpan(this.getValueInKb(segmentSize), 'segment-size'));
    result.appendChild(this.createSpan(', Uploaded:'));
    result.appendChild(this.createSpan(this.getValueInKb(loaded), 'loaded-size'));
    result.appendChild(this.createSpan('/'));
    result.appendChild(this.createSpan(this.getValueInKb(total), 'total-size'));

    return result;
  }

  getValueInKb(value: any) {
    return `${(value / 1024).toFixed(0)}kb`;
  }

  createSpan(text: any, className: any = null) {
    const result = document.createElement('SPAN');
    if (className) { result.className = `${className} dx-theme-accent-as-text-color`; }
    result.innerText = text;
    return result;
  }

  getChunkPanel() {
    return document.querySelector('.chunk-panel');
  }
}
