import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-course-questions-modal',
  templateUrl: './course-questions-modal.component.html',
  styleUrls: ['./course-questions-modal.component.scss'],
})
export class CourseQuestionsModalComponent {
  @Input() showModal = false;

  @Output() showModalChange = new EventEmitter<boolean>();
  @Output() submit = new EventEmitter();

  dismiss = () => {
    this.showModal = false;
    this.showModalChange.emit(this.showModal);
  };

  onSubmit = () => {
    this.submit.emit();
  };
}

