import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RawFileModel } from '../models/raw-file-model';
import { DownloadService } from './download.service';
import {Configuration} from "../../assets/Configuration";

@Injectable({
  providedIn: 'root',
})
export class TrainingHistoryService {
  constructor(
    private httpClient: HttpClient,
    private downloadService: DownloadService
  ) {}

  public getTrainingHistoryYearList(personId: string): Observable<string[]> {
    return this.httpClient.get<string[]>(
      Configuration.getApiBaseUrl() + `/person/${personId}/training-history-year-list`
    );
  }

  public getTrainingHistory(personId: string, years: string): void {
    const url =
      Configuration.getApiBaseUrl() + `/person/${personId}/training-history/${years}`;
    this.downloadService.download(url);

    // return this.httpClient.get<RawFileModel>(
    //   Configuration.getApiBaseUrl() + `/person/${personId}/training-history/${years}`
    // );
  }
}
