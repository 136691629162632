import { Injectable } from '@angular/core';
import {Subject} from "rxjs";
import {HttpClient, HttpEvent, HttpEventType, HttpHeaderResponse, HttpHeaders, HttpParams, HttpProgressEvent, HttpResponse} from "@angular/common/http";
import {filter, map, share} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class DownloadService {

  private downloadStartedSubject = new Subject<DownloadStartedEvent>();
  private downloadProgressSubject = new Subject<DownloadProgressEvent>();
  private downloadFinishedSubject = new Subject<DownloadFinishedEvent>();

  public downloadStarted$ = this.downloadStartedSubject.asObservable();
  public downloadProgress$ = this.downloadProgressSubject.asObservable();
  public downloadFinished$ = this.downloadFinishedSubject.asObservable();

  private downloadCount = 0;

  constructor(private http: HttpClient) {
  }

  public download(url: string, httpParams?: HttpParams): void {
    const downloadId = this.downloadCount++;
    const downloadObs = this.http
      .get(url, {responseType: 'blob', observe: 'events', reportProgress: true, params: httpParams})
      .pipe(share());

    downloadObs.pipe(
      filter(event => this.isHttpHeaderResponse(event)),
      map(event => event as HttpHeaderResponse)
    ).subscribe((response) => {
      this.downloadStartedSubject.next({
        downloadId: downloadId,
        fileName: this.getFileNameFromHeaders(response.headers),
      })
    });

    downloadObs.pipe(
      filter(event => this.isHttpResponse<Blob>(event)),
      map(event => event as HttpResponse<Blob>)
    ).subscribe((response) => {
      this.downloadFinishedSubject.next({
        downloadId: downloadId,
        fileName: this.getFileNameFromHeaders(response.headers),
        file: response.body!
      })
    });


    downloadObs.pipe(
      filter(event => this.isHttpProgressEvent(event)),
      map(event => event as HttpProgressEvent)
    ).subscribe(progressEvent => {
      this.downloadProgressSubject.next({
        downloadId: downloadId,
        totalSize: progressEvent.total!,
        progressSize: progressEvent.loaded
      })
    });

  }

  private isHttpResponse<T>(event: HttpEvent<T>): boolean {
    return event.type === HttpEventType.Response
  }

  private isHttpProgressEvent(event: HttpEvent<unknown>): boolean {
    return event.type === HttpEventType.DownloadProgress
      || event.type === HttpEventType.UploadProgress
  }

  private isHttpHeaderResponse(event: HttpEvent<unknown>): boolean {
    return event.type === HttpEventType.ResponseHeader;
  }

  private getFileNameFromHeaders(headers: HttpHeaders): string {
    return headers.get('content-disposition')!
      .split(';')[1]
      .trim()
      .split('=')[1]
      .replace(/\"/g, '');
  }
}

export interface DownloadProgressEvent {
  downloadId: number;
  totalSize: number;
  progressSize: number;
}

export interface DownloadStartedEvent {
  downloadId: number;
  fileName: string;
}

export interface DownloadFinishedEvent {
  downloadId: number;
  fileName: string;
  file: Blob;
}
